/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React,{ useState } from "react";
import { Button, Modal} from "react-bootstrap";
import { Link } from "react-router-dom";
//assets
import { RoundDelete, RoundEdit, TeamAdd, PolicyIcon, DdIcon, ElearnIcon,PolicyIconOrange,
  DdIconOrange,
  ElearnIconOrange  } from "assets/Iconsv2/Iconsv2";

const DetailTimelineSection = ({
  data = [],
  state,
  variablesObj,
  functionContent,
  isBackButtonEnabled = true
}) => {
  const { iraBasicInfo = {},  } = state ?? {};

  const _handleBackButtonAction = () => {
    if (state?.originFlag == "kanban") {
      return "/ira/kanban";
    } else {
      return "/";
    }
  };
  const [showModalImage, setShowModalImage] = useState(false);
  const [showModalVideo, setShowModalVideo] = useState(false);
  const [showModalPdf, setShowModalPdf] = useState(false);
  const handleShowImage = () => {setShowModalImage(true);}
  const handleCloseImage = () => setShowModalImage(false);
  const handleShowVideo = () => {setShowModalVideo(true);}
  const handleCloseVideo = () => setShowModalVideo(false);
  const handleShowPdf= () => {setShowModalPdf(true);}
  const handleClosePdf = () => setShowModalPdf(false);
  

  const { isApproveEnabled = false,iraId } = variablesObj ?? {};

  return (
    <>
      <div className="row my-4 align-items-center">
        {isBackButtonEnabled ? <div className="col-12 col-lg-4 col-md-4 px-0">
          <Link to={_handleBackButtonAction()}>
            <Button
              className="border clide-v2-primary clide-v2-white-color"
              variant=""
            >
              <i className="fa fa-angle-left" aria-hidden="true"></i>
            </Button>
          </Link>
        </div> : null }
        <div className="col-12 col-lg-4 col-md-4">
          <div>
            <span className="common-black-1 font-weight-500">
              {" "}
              {iraId ?? ""}{" "} 
              <span
                style={{
                  borderLeft: "3px Solid Orange",
                  padding: "0 5px 0 5px",
                }}
              >
                {" "}
                {iraBasicInfo.type_of_incidence} - 
                {iraBasicInfo.activity}{" "}
              </span>
              <span
                style={{
                  borderLeft: "3px Solid Orange",
                  padding: "0 5px 0 5px",
                }}
              >
                {iraBasicInfo?.reportedmonth_name}
              </span>
            </span>
          </div>
        </div>
        <div className="col-12 col-lg-4 col-md-4 d-flex justify-content-end px-0">
          <div className="d-flex align-items-center">
          {state?.activity_img?(
                <div>
                  <button className="clide-v2-icon-btn mr-2"
                    title="policy"
                    onClick={handleShowImage} >
                    <PolicyIconOrange />
                  </button>

                  <Modal

                    show={showModalImage}
                    onHide={handleCloseImage}
                    className="reportPopup "
                  >
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body>
                      <img src={state.activity_img} alt="">
                      </img>

                      
                    </Modal.Body>
                  </Modal>
                </div>
              ):null}

              {state?.activity_video?(
                <div>
                  <button className="clide-v2-icon-btn mr-2"
                  
                    title="dd"
                    onClick={handleShowVideo} >
                    <DdIconOrange  />
                  </button>

                  <Modal

                    show={showModalVideo}
                    onHide={handleCloseVideo}
                    className="reportPopup "
                  >
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body>

                    <video
              controls
              width="100%"
              style={{ borderRadius: "8px" }}
              src={state.activity_video}
            > </video>

                      
                    </Modal.Body>
                  </Modal>
                </div>
              ):null}

              {state?.activity_pdf?(
                <div>
                  <button className="clide-v2-icon-btn mr-2"
                    title="elearn"
                    onClick={handleShowPdf} >
                    <ElearnIconOrange />
                  </button>

                  <Modal

                    show={showModalPdf}
                    onHide={handleClosePdf}
                    className="reportPopup "
                  >
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body>

                    <embed
              src={state.activity_pdf}
              type="application/pdf"
              width="100%"
              height="500px"
              style={{ borderRadius: "8px" }}
            />
                    </Modal.Body>
                  </Modal>
                </div>
              ):null} 

            { ["pending", "progress"].includes(state?.reportStatus) ? (
              <>
                {iraBasicInfo.edit_flag == 1 && (
                  <Link
                    to={`/edit-ira-reporting-detail/${iraBasicInfo.ira_id}/${iraBasicInfo.service_id}/${iraBasicInfo.project_id}/edit`}
                  >
                    <button className="clide-v2-icon-btn mr-2" title="Edit">
                      <RoundEdit />
                    </button>
                  </Link>
                )}

                {iraBasicInfo.delete_flag == 1 ? (
                  <div>
                    <button
                      className="clide-v2-icon-btn mr-2"
                      title="Delete"
                     
                      onClick={() =>
                        functionContent.debounceFunc(() => {
                            functionContent.showModal()
                        })
                      }
                    >
                      <RoundDelete />
                    </button>
                  </div>
                ) : null}
              </>
            ) : null}

            {state?.reportStatus == "completed" ? (
              <div className="rd-btn right-block-gap ">
                <Link to={`/ira-report/${iraBasicInfo.ira_id}`} target="_blank">
                  <img
                    src={require("assets/sls-icons/V2. New Development/PDF.svg")}
                    className="clide-v2-profile-img clide-v2-pdf-icon"
                    alt="completed pdf"
                  />
                </Link>
              </div>
            ) : null}
            <div>
           

              {["pending", "progress"].includes(state?.reportStatus) &&
              isApproveEnabled ? (
                <button
                  className="clide-v2-icon-btn mr-2"
                  title="Add Team"
                  onClick={() =>
                    functionContent.debounceFunc(() => {
                        functionContent.openInviteUser()
                    })
                  }
                >
                  <TeamAdd />
                </button>
              ) : null}
              <div className="image-box">
                {iraBasicInfo.invited_user?.map((image, index) => (
                  <img
                    style={{ marginRight: "0.5vw" }}
                    src={image.userpic}
                    alt=""
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pe-3 clide-v2-p-0">
        <div className="clide-v2-card-box-1 p-3">
          <div className="text-center clide-v2-primary-color py-4">
            Timeline
          </div>
          <div className="overflow-x overflow-auto">
            <div
              className="line_box stav_projektu"
              style={{ margin: "40px 0 40px 0" }}
            >
              {data?.map((el) => {
                return (
                  <div className="text_circle done ">
                    <a href="#" className="tvar">
                      <span />
                    </a>
                    <div className="circle clide-v2-white-box ">
                      {el?.title}
                      <hr />
                      {el?.cardTitle}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(DetailTimelineSection);
