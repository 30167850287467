import React from 'react'
import { OverlayTrigger, Popover, Container } from "react-bootstrap"
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";


const DetailReportingDetails = ({ state, functionContent }) => {
    const { nmDetail } = state;
    const { t } = useTranslation(['nm', 'util']);
    return (
        <>

            {/* ReportingDetails */}
            <div className='clide-v2-card-box-1 p-3'>
                <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3">{t('reportedBy')}</p>
                <hr />
                <div className="d-flex p-1 mb-2 text-start">
                    <div className="">
                        <OverlayTrigger
                            trigger="hover"
                            placement="left"
                            rootClose={true}
                            overlay={
                                <Popover
                                    id="popover-positioned-bottom"
                                    style={{ width: "15vw", borderRadius: "10px" }}
                                >
                                    <Container>
                                        <div className="my-3">
                                            <span className="common-black-1 font-weight-500 font-16">
                                            {t('reportedDate')} :
                                            </span>
                                            <span className="common-black-1 ml-3 font-16">
                                                {nmDetail.reported_date}
                                            </span>
                                        </div>
                                        <div className="my-3">
                                            <span className="common-black-1 font-weight-500 font-16">
                                                {t('reportedTime')} :
                                            </span>
                                            <span className="common-black-1 ml-3 font-16">
                                                {nmDetail.reported_time}
                                            </span>
                                        </div>
                                        <div className="my-3">
                                            <span className="common-black-1 font-weight-500 font-16">
                                                {t('reportedMonth')} :
                                            </span>
                                            <span className="common-black-1 ml-3 font-16">
                                                {nmDetail.reportedmonth_name}
                                            </span>
                                        </div>
                                    </Container>
                                </Popover>
                            }
                        >
                            <img className="clide-v2-profile-img" src={nmDetail.reported_by_pic} />
                        </OverlayTrigger>

                    </div>
                    <div className="p-1 px-3 fw-bold w-100  ">
                        <div className="col-12 col-md-12 col-lg-12">
                            {nmDetail.reported_by}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-md-6 col-lg-6 mb-3">
                        <div className="">
                            {t('id')} :
                        </div>
                        <div className="clide-v2-title mb-1">
                            <label className="">{state?.assignedDetails?.emp_id ?? "NA"}</label>
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-6 mb-3">
                        <div className="">
                            {t('type')}
                        </div>
                        <div className="clide-v2-title mb-1">
                            <label className="">{state?.assignedDetails?.type ?? "NA"}</label>
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-6 mb-3">
                        <div className="">
                            {t('department')}
                        </div>
                        <div className="clide-v2-title mb-1">
                            <label className="">{functionContent?.getDepartment(state?.assignedDetails?.field)}</label>
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-lg-6 mb-3">
                        <div className="">
                            {t('designation')}
                        </div>
                        <div className="clide-v2-title mb-1">
                            <label className=""> {state?.assignedDetails?.designation ?? "NA"}</label>
                        </div>
                    </div>
                </div>
            </div>

            {/* Bades Section */}


            <div className="clide-v2-card-box-1 mt-2 p-3">
                <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3">{t('badges')} ({state?.likeNumber})</p>
                <hr />
                <div className="text-center clide-v2-border-primary-color border p-3 rounded">
                    {state?.userLike != "" ? (
                        state?.userLike?.map(
                            (item, index) =>
                                index < 5 && (
                                    <React.Fragment>
                                        <img
                                            src={item.user_profile}
                                            alt=""
                                            data-tip
                                            data-for={`user-name-${index}`}
                                            className='clide-v2-profile-img'
                                        />

                                        <ReactTooltip
                                            effect="solid"
                                            place="right"
                                            id={`user-name-${index}`}
                                        >
                                            <>
                                                <span>{item.user_name}</span>
                                            </>
                                        </ReactTooltip>
                                    </React.Fragment>
                                )
                            // </div>
                        )
                    ) : (
                        <p className="data-not-present mb-0">{t('badgesNotAvailable')} </p>
                    )}
                </div>
            </div>
        </>
    )
}

export default DetailReportingDetails