import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Url from "app/shared/constants/Url";
import AlertMessage from "app/shared/components/Popups/AlertMessage";
import { Scrollbars } from "react-custom-scrollbars";
import CrownIcon from "assets/images/icons/Crown .svg";
import {
  KanbanListTypeIcon,
  KanbanGridIcon,
  UserAdd,
  UserList,
  UserSetting,
  Unpublish,
  Seprator,
  PlusIconRed,
  RoundDelete,
} from "assets/Iconsv2/Iconsv2";
import { withTranslation } from 'react-i18next';
const rfdc = require("rfdc")();

  export default withTranslation(['workpermit','util'])(
    class AssignUser extends Component {
  constructor(props) {
    super(props);
    const {t}=this.props;
    this.state = {
      showModal: this.props.showModal,
      userList: [],
      teamLeader: [],
      dataLoading: true,
      searchUserName: "",
      searchBtnClicked: false,
      searchUserList: [],
    };
  }

  componentDidMount() {
    this._getUserList();
  }

  _getUserList = () => {
    let URL = Url.formType1.assignUserList;
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(URL, ContentType);

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { userList, teamLeader } = prevState;
              userList = rfdc(response.data.data.assign_user);

              // in API for unassigned teamlead object is returning with id only
              if (
                response.data.data.teamLeader &&
                response.data.data.teamLeader.name !== undefined
              ) {
                teamLeader.push(rfdc(response.data.data.teamLeader));
              }
              return { userList, teamLeader };
            });
          }
          await this.setState((prevState) => {
            let { dataLoading } = prevState;
            dataLoading = false;
            return { dataLoading };
          });
        })
        .catch(async (error) => {
          await this.setState((prevState) => {
            let { dataLoading } = prevState;
            dataLoading = false;
            return { dataLoading };
          });
        });
    });
  };

  _handleAssignUser = (userId) => {
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    let extraData = {
      selecteduser_id: userId,
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.formType1.assignUser,
        ContentType,
        extraData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { teamLeader, userList, searchBtnClicked } = prevState;

              searchBtnClicked = false;
              let userIndex = userList.findIndex((item) => item.id === userId);
              let currentTeamLeader = rfdc(teamLeader[0]);
              teamLeader = [];
              teamLeader.push(rfdc(userList[userIndex]));
              userList.splice(userIndex, 1);
              if (currentTeamLeader !== undefined) {
                userList.push(currentTeamLeader);
              }
              return { teamLeader, userList, searchBtnClicked };
            });

            AlertMessage({ message: this.props.t('assignedSuccessfully'), type: "success" });
          }
        })
        .catch(async (error) => {});
    });
  };

  _handleUnAssignUser = () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(Url.formType1.unassignUser);

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { userList, teamLeader, searchBtnClicked } = prevState;
              searchBtnClicked = false;
              userList.push(teamLeader[0]);
              teamLeader = [];
              return { userList, teamLeader, searchBtnClicked };
            });
            AlertMessage({
              message: this.props.t('unassignedSuccessfully'),
              type: "success",
            });
          }
        })
        .catch(async (error) => {});
    });
  };

  _handleInputChange = async (event) => {
    const val = event.target.value;

    await this.setState((prevState) => {
      let { userList, searchUserName, searchBtnClicked, searchUserList } =
        prevState;
      searchUserName = val;
      searchBtnClicked = true;
      searchUserList = userList.filter((user) => {
        if (user.name !== undefined) {
          if (user.name.toLowerCase().includes(searchUserName.toLowerCase())) {
            return user;
          }
        }
      });
      return { searchUserName, searchBtnClicked, searchUserList };
    });
  };

  _handleUserSearch = async () => {
    await this.setState((prevState) => {
      let { userList, searchUserName, searchBtnClicked, searchUserList } =
        prevState;
      searchBtnClicked = true;
      searchUserList = userList.filter((user) => {
        if (user.name !== undefined) {
          if (user.name.toLowerCase().includes(searchUserName.toLowerCase())) {
            return user;
          }
        }
      });
      return { searchBtnClicked, searchUserList };
    });
  };

  render() {
    const {t} = this.props;
    return (
      <div className="report-section">
        <Modal
          centered
          show={this.state.showModal}
          className="assign-user-modal"
          onHide={this.props.closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('assignUser')}</Modal.Title>
            <Button
              variant="outline-primary"
              className="modal-btn"
              onClick={this.props.closeModal}
            >
              {t('doneAUC')}
            </Button>
          </Modal.Header>

          <Modal.Body>
            <div className="team-lead-box">
              <div className="team-lead-img">
                <img
                  className="team-lead"
                  src={require("assets/sls-icons/Crown/new_opportunity.svg")}
                  alt=""
                />
              </div>
              <div className="team-lead-text">
                <p>{t('userAssignedGetFollowingRightsAudit')}</p>
                <p>
                  {t('createDeleteAssignPublishChoose')}
                </p>
              </div>
            </div>
            <div className="active-team-box">
              <div className="rd-left-bottom invite-user-section">
                {this.state.teamLeader.length > 0 && (
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex gap-2 align-items-center">
                      <img
                        src={this.state.teamLeader[0].pic}
                        alt=""
                        className="clide-v2-profile-img"
                      />
                      <h6 className="user-name mb-0 clide-v2-primary-color">
                        {" "}
                        {this.state.teamLeader[0].name}
                      </h6>
                    </div>
                    <div className="d-flex gap-2">
                      <img
                        className="team-lead"
                        style={{
                          height: "2.3rem!important",
                          width: "2.3rem",
                          background: "white",
                          bottom: "-6px",
                          mixBlendMode: "multiply",
                        }}
                        src={require("assets/sls-icons/Crown/new_opportunity.svg")}
                        alt={t('assignuser')}
                      />

                      <button
                        className="clide-v2-icon-btn"
                        onClick={this._handleUnAssignUser}
                      >
                        <RoundDelete />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="search-section">
              <input
                type="text"
                placeholder={t('search')}
                name="search"
                value={this.state.searchUserName}
                onChange={(e) => this._handleInputChange(e)}
              />

              <button type="button" onClick={this._handleUserSearch}>
                <i className="fa fa-search"></i>
              </button>
            </div>
            <Scrollbars
              style={{ height: `15rem` }}
              renderView={(props) => (
                <div
                  style={{
                    paddingRight: "15px",
                    ...props.style,
                    overflowX: "hidden",
                  }}
                />
              )}
            >
              <div className="rd-left-bottom invite-user-section">
                {!this.state.searchBtnClicked &&
                  this.state.userList.length > 0 &&
                  this.state.userList.map((userInfo) => (
                    <div className="user-list" key={userInfo.id}>
                      <img src={userInfo.pic} alt="" />
                      <h6 className="user-name"> {userInfo.name}</h6>
                      <button
                        className="clide-v2-icon"
                        onClick={() => this._handleAssignUser(userInfo.id)}
                      >
                        <img
                          src={CrownIcon}
                          alt=""
                          className="user-icon border-0 cursor-p d-block"
                          style={{
                            height: "2.3rem!important",
                            width: "1.3rem",
                            background: "white",
                            bottom: "-6px",
                            mixBlendMode: "multiply",
                          }}
                        />
                      </button>
                    </div>
                  ))}
                {this.state.searchBtnClicked &&
                  this.state.searchUserList.length > 0 &&
                  this.state.searchUserList.map((userInfo) => (
                    <div className="user-list" key={userInfo.id}>
                      <img src={userInfo.pic} alt="" />
                      <h6 className="user-name"> {userInfo.name}</h6>
                      <button
                        className="clide-v2-icon"
                        onClick={() => this._handleAssignUser(userInfo.id)}
                      >
                        <img
                          src={CrownIcon}
                          alt=""
                          className="user-icon border-0 cursor-p d-block"
                          style={{
                            height: "2.3rem!important",
                            width: "1.3rem",
                            background: "white",
                            bottom: "-6px",
                            mixBlendMode: "multiply",
                          }}
                        />
                      </button>
                    </div>
                  ))}
              </div>
            </Scrollbars>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
)
