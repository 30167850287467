import { DateTableComponent as DataTable } from "app/shared/components/ReusableComponents/DataTable";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { Link } from "react-router-dom";
//components
import { AccordionWrapper } from "app/views/Nm/container/modules/capa";
import UaUcHalfGraph from "app/shared/components/PredictiveAnalysis/UaUcHalfGraph";
import { withTranslation } from 'react-i18next';


export const DisplayPercentage = (value) => {
const pendingValue  =   100 - parseInt(value)
 const values  = [
    { color:"#0A58CA", name: "Complete", y: value  },
    { color: "#F8F8FB", name: "Pending", y:  pendingValue},
  ];

  
  return values.length > 0 ? (
    <div className="w-auto display-percentage-wrap">
      <UaUcHalfGraph
        title=""
        graphData={values}
        total={`${value} %`}
        height={90}
        customTitles={{ useHTML: true }}
        list={false}
        cutout={"82%"}
        radius={"90%"}
        fontSize={"1.5rem"}
        fontWeight={500}
        styleObj={{
          marginTop: '0px',
        }}
      />
    </div>
  ) : null;
};
const CapaInprogressSection = ({ t, props, functionContent, state = {} }) => {
  const { progressListCount } = props ?? {};
  return (
    <div className={`col-12  mb-2 ${state.listMode === "list" ? "col-md-12 col-lg-12" :" col-md-6 col-lg-6"}  ` }>
      <div className="clide-v2-card-box">
        <div className="  clide-v2-p-0">
          <div className="d-flex border-bottom flex-direction-m">
            <div className="clide-v2-side-color-box clide-v2-sky">
              <div className="clide-v2-side-text ml-0 ml-lg-3">
                {" "}
                {t('inProgr')} 
                | {progressListCount || 0}
              </div>
            </div>
            <Scrollbars
              style={{ height: `calc(100vh - 14vw)` }}
              className="m-2"
              renderTrackHorizontal={(props) => (
                <div
                  {...props}
                  className="track-horizontal"
                  style={{ display: "none" }}
                />
              )}
              renderThumbHorizontal={(props) => (
                <div
                  {...props}
                  className="thumb-horizontal"
                  style={{ display: "none" }}
                />
              )}
              renderThumbVertical={(props) => (
                <div
                  {...props}
                  style={{
                    position: "relative",
                    display: "block",
                    width: "100%",
                    cursor: "pointer",
                    borderRadius: "inherit",
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    height: "100px",
                  }}
                />
              )}
              onScrollStop={() => {
                Number.isInteger(props.progress_list.length) &&
                  props.progress_list.length <= props.progressListCount &&
                  props.getPaginationData(props.pageNumber + 1);
              }}
              
            >
              <div className=" p-3 w-100">
                {props.progress_list.map((data, index) => (
                  <div
                    key={index}
                    className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10  mb-3"
                    style={{
                      borderColor: data.severity_color,
                    }}
                  >
                    <AccordionWrapper
                    
                      items={[
                        {
                          title: (
                            <>
                              <div className="d-flex w-100 border-bottom mb-2">
                                <div className="w-75 ">
                                  <Link
                                    to={`/ira-detail/${data.ira_id}/kanban/completed/capa`}
                                    className="text-decoration-none clide-v2-secondary-color"
 
                                  >
                                    <div className="w-75 p-3"><span className="clide-v2-primary-color">#{data.ira_id}</span>&nbsp;|&nbsp;
                                      {data.incident_type || ""} |{" "}
                                      {data.activity || ""}
                                    </div>
                                  </Link>
                                </div>
                                <div className=" py-0">
                                  <button className="clide-v2-side-button py-1 px-5">
                                   {t('caPa')}
                                  </button>
                                </div>
                              </div>
                              <div className="mb-1">
                                <div className="row p-1">
                                  <div className="col-12 col-md-3 col-lg-3">
                                    <div> {t('service')}</div>
                                    <div className=" fw-semibold  clide-v2-fs-5">
                                      {data.service}
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-3 col-lg-3">
                                    <div>{t('plantProject')} </div>
                                    <div className=" fw-semibold  clide-v2-fs-5">
                                      {data.project}
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 col-lg-6 d-flex justify-content-end">
                                    {DisplayPercentage(data?.progress)}
                                  </div>
                                </div>
                              </div>
                            </>
                          ),
                          content: (
                            <>
                              <div className="open-bbody">
                                <hr className="mb-3 mt-1" />
                                {state.listMode === "list" ? (
                                  <>
                                    <div className="mt-3 ">
                                      <DataTable
                                        {...functionContent.getFieldProps(
                                          data.list
                                        )}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <Scrollbars
                                      style={{
                                        height: `15rem`,
                                        minHeight: "12rem",
                                      }}
                                      renderView={(props) => (
                                        <div
                                          style={{
                                            paddingRight: "15px",
                                            ...props.style,
                                            overflowX: "hidden",
                                          }}
                                        />
                                      )}
                                    >
                                      <div className="mr-3">
                                        {data.list.map(
                                          (capaItem, index, arr) => (
                                            <div
                                              key={`inprogressList-${capaItem.recommendation_id}`}
                                              className={` ${
                                                arr.length - 1 !== index
                                                  ? "border-bottom"
                                                  : ""
                                              } py-2`}
                                            >
                                              <Row>
                                                <Col sm={12} className="my-2 ">
                                                  <div className="d-flex  justify-content-between ">
                                                    <div className="d-flex ">
                                                      <div className=" ">
                                                        {t('corrective')}{" "}
                                                        {++index}
                                                      </div>{" "}
                                                      &nbsp; |
                                                      <span
                                                        style={{
                                                          color: "black",
                                                        }}
                                                        className="ml-2  font-weight-500 fw-semibold  clide-v2-fs-5 text-overflow-ellipsis cursor-pointer"
                                                      >
                                                        {capaItem.recommendation
                                                          ?.recommendation ||
                                                          ""}
                                                      </span>{" "}
                                                    </div>
                                                    <div>
                                                    <div
                                                      className="  rounded px-2 border cursor-p clide-v2-primary clide-v2-white-color border-0"
                                                      onClick={() =>
                                                        functionContent.checkApprovePersmission(
                                                          data,
                                                          capaItem.tracking_responsible_person,
                                                          capaItem,
                                                          index + 1,
                                                          data.ira_id
                                                        )
                                                      }
                                                    >
                                                      {capaItem.recommendation
                                                        ?.status || ""}
                                                    </div>
                                                    </div>
                                                  </div>
                                                </Col>
                                                <Col sm={3}>
                                                  <div className=" ">
                                                    {t('priority')}
                                                  </div>
                                                  <div
                                                    
                                                    className="badge  fw-semibold  clide-v2-fs-5 py-2"
                                                  >
                                                    <div
                                                      className="fw-semibold  clide-v2-fs-5"
                                                      style={{
                                                        color:
                                                        capaItem.priority_color,
                                                      }}
                                                    >
                                                      {capaItem.priority}
                                                    </div>
                                                  </div>
                                                </Col>
                                                <Col sm={3}>
                                                  <div className=" ">
                                                    {t('overdue')}
                                                  </div>
                                                  <div className=" fw-semibold  clide-v2-fs-5">
                                                    {capaItem.overdue_deviation ||
                                                      ""}
                                                  </div>
                                                </Col>
                                                <Col sm={3}>
                                                  <div className=" ">
                                                    {t('category')}
                                                  </div>
                                                  <div className=" fw-semibold  clide-v2-fs-5">
                                                    {capaItem.category}
                                                  </div>
                                                </Col>
                                                <Col sm={3}>
                                                  <div className=" ">
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.7)",
                                                      }}
                                                      className={`${
                                                        functionContent.comapareTargetDate(
                                                          capaItem.target_date
                                                        )
                                                          ? "bg-danger"
                                                          : "bg-warning"
                                                      } text-white rounded px-1`}
                                                    >
                                                      {t('dueDate')}
                                                    </span>
                                                  </div>
                                                  <div className=" fw-semibold  clide-v2-fs-5">
                                                    {capaItem.target_date ||
                                                      "N/A"}
                                                  </div>
                                                </Col>
                                                <Col sm={3}>
                                                  <div className=" ">
                                                    {t('proposed')}
                                                  </div>
                                                  <div className="d-flex ">
                                                    <img
                                                      src={
                                                        capaItem?.recommendedby_pic
                                                      }
                                                      className="clide-v2-profile-img mr-2"
                                                    />
                                                    <div className=" fw-semibold  clide-v2-fs-5">
                                                      {capaItem?.recommendedby ||
                                                        ""}
                                                    </div>
                                                  </div>
                                                </Col>
                                                <Col sm={3}>
                                                  <div className=" ">
                                                    {t('assigned')}
                                                  </div>
                                                  {capaItem.tracking_responsible_person.map(
                                                    (person, index) => (
                                                      <div
                                                        className="d-flex "
                                                        key={index}
                                                      >
                                                        <img
                                                          src={
                                                            person?.person_pic
                                                          }
                                                          className="clide-v2-profile-img mr-2"
                                                        />
                                                        <div className=" fw-semibold  clide-v2-fs-5">
                                                          {person.person_name}
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </Col>
                                              </Row>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </Scrollbars>
                                  </>
                                )}
                              </div>
                            </>
                          ),
                        },
                      ]}
                      customHeader
                      classes={ {
                        mainWrapper:"pl-0",
                        wrapperItemSummary:"pb-0 pl-0"
                    }}
                    />
                  </div>
                ))}

                {/* <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-secondary-color mb-3">
                  <div className="d-flex w-100 border-bottom mb-2">
                    <div className="w-75 p-3">UA | Chemical Handling</div>
                    <div className="p-3">
                      <button className="clide-v2-side-button">CAPA</button>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="row p-1">
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Service</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          PHARMA SERVICES
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Plant/Project</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          Pharma Plant
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4 text-center">
                        <img className="w-75" src="img/halfcircle.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-blue-color mb-3">
                  <div className="d-flex w-100 border-bottom mb-2">
                    <div className="w-75 p-3">UA | Chemical Handling</div>
                    <div className="p-3">
                      <button className="clide-v2-side-button">CAPA</button>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="row p-1">
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Service</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          PHARMA SERVICES
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Plant/Project</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          Pharma Plant
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4 text-center">
                        <img className="w-75" src="img/halfcircle.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-green-color mb-3">
                  <div className="d-flex w-100 border-bottom mb-2">
                    <div className="w-75 p-3">UA | Chemical Handling</div>
                    <div className="p-3">
                      <button className="clide-v2-side-button">CAPA</button>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="row p-1">
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Service</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          PHARMA SERVICES
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Plant/Project</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          Pharma Plant
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4 text-center">
                        <img className="w-75" src="img/halfcircle.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-ghostwhite-color mb-3">
                  <div className="d-flex w-100 border-bottom mb-2">
                    <div className="w-75 p-3">UA | Chemical Handling</div>
                    <div className="p-3">
                      <button className="clide-v2-side-button">CAPA</button>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="row p-1">
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Service</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          PHARMA SERVICES
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Plant/Project</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          Pharma Plant
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4 text-center">
                        <img className="w-75" src="img/halfcircle.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-Neonpink-color mb-3">
                  <div className="d-flex w-100 border-bottom mb-2">
                    <div className="w-75 p-3">UA | Chemical Handling</div>
                    <div className="p-3">
                      <button className="clide-v2-side-button">CAPA</button>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="row p-1">
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Service</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          PHARMA SERVICES
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Plant/Project</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          Pharma Plant
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4 text-center">
                        <img className="w-75" src="img/halfcircle.png" />
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation("ira")(CapaInprogressSection);
