import React from "react";
//components
import DetailStep1 from "./components/step1";
import DetailStep2 from "./components/step2";
import DetailStep3 from "./components/step3";
import DetailStep4 from "./components/step4";
import DetailStep5 from "./components/step5";
//assets
import { TimelineCalendarIcon } from "assets/Iconsv2/Iconsv2";
import { useTranslation } from "react-i18next";

const DetailStepForms = ({ state, functionContent, variablesObj }) => {
  const { t } = useTranslation('ira');
  return (
    <li>
      <div className="cbp_tmicon cbp_tmicon-mail">
        <TimelineCalendarIcon />
      </div>
      <div className="cbp_tmlabel clide-v2-white-box">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-8">
            <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-2">
              {t('incidentInvestigation')}
            </p>
          </div>
          <div className="col-12 col-md-4 col-lg-4 text-end">
            <button className="clide-v2-btn-template clide-v2-primary my-1 px-3 ">
              <span className="clide-v2-white-color">{t('analysis')}</span>
            </button>
          </div>
        </div>
        <DetailStep1
          state={state}
          functionContent={functionContent}
          variablesObj={variablesObj}
        />
        <DetailStep2
          state={state}
          functionContent={functionContent}
          variablesObj={variablesObj}
        />
        <DetailStep3
          state={state}
          functionContent={functionContent}
          variablesObj={variablesObj}
        />
        <DetailStep4
          state={state}
          functionContent={functionContent}
          variablesObj={variablesObj}
        />
        <DetailStep5
          state={state}
          functionContent={functionContent}
          variablesObj={variablesObj}
        />
      </div>
    </li>
  );
};

export default DetailStepForms;
