import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Url from "app/shared/constants/Url";
import { useTranslation } from "react-i18next";

const LowSpace = ({ message, handleLowSpace }) => {
  const { t } = useTranslation(['util']);
  return (
    <SweetAlert
      custom
      confirmBtnText="Okay"
      confirmBtnBsStyle="success"
      customIcon={require("assets/images/low_storage.svg")}
      title={t('warning')}
      onConfirm={handleLowSpace}
    >
      {message}
    </SweetAlert>
  );
};

LowSpace.defaultProps = {
  handleLowSpace: () => {},
};
export default LowSpace;
