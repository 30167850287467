import * as serviceWorker from "./serviceWorker";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom";

import { I18nextProvider } from "react-i18next"; // Import I18nextProvider
// import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
// import { initReactI18next } from "react-i18next";

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    fallbackLng: "en", 
    returnObjects: true,
    saveMissing: true,
    interpolation: {
      escapeValue: false,
    },
    fallbackNS: ["util"],
    defaultNS: "util",
  });

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
