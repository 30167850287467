import React, { Component } from "react";
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
export default withTranslation('util')(
    class DeletePopup extends Component {
  constructor(props){
    super(props);
    this.state = {
      showDeletePopup: false
    };
    this._closeModal = this._closeModal.bind(this);
  }

  _closeModal(){
    this.props.onCancel();
  }

  _confirm(){
    this.props.onConfirm();
  }

  render() {
    const {t}= this.props;
    return (
      <div>
        <Modal centered show={this.props.showDeletePopup} className="submit-modal" onHide={this._closeModal}>
          <Modal.Header ></Modal.Header>
          <Modal.Body className="d-flex flex-column">
            <img className="submit-img w-50 mx-auto" src={require('assets/images/delete-popup.svg')} />
            <p className="submit-text">{t('areYouSureYouWantToDelete')} </p>
            <div className="modal-footer-btn">
              <button
                type="submit"
                className="red-btn"
                onClick={this._closeModal}
              >
                {t('cancelAUC')}
              </button>
              <button
                type="submit"
                className="red-btn"
                onClick={() => this._confirm()}
              >
                {t('yesAUC')}, {t('deleteAUC')}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
)
