import React, { Fragment } from "react";
import AudioPlayer from "react-h5-audio-player";
import ReactTooltip from "react-tooltip";
//componnents
import { CarouselComponent } from "app/shared/components/ReusableComponents/Carousel";
import {
  getUserProfileDetails,
  GetPopoverFrame,
} from "app/views/SocialMedia/ReportingDetail/ReportingDetail";
//assets
import {
  TimelineDocIcon,
  TimelineInfoIcon,
  TimelineCalendarIcon,
  TeamLeadBadge,
} from "assets/Iconsv2/Iconsv2";
//utills
import CheckFormSettingField from "app/shared/utility/CheckFormSettingField";
import { getInitials } from "app/views/Nm/container/utils/moduleHelp";
import { useTranslation } from "react-i18next";

const DetailBasicInfo = ({ state, functionContent }) => {
  const { iraBasicInfo } = state;
  const {t} = useTranslation(['ira','util']);
  return (
    <Fragment>
      <li>
        <div className="cbp_tmicon cbp_tmicon-phone">
          <TimelineDocIcon />
        </div>
        <div className="cbp_tmlabel clide-v2-white-box">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-4">
              <CarouselComponent
                items={functionContent.__generateCarouselData(
                  iraBasicInfo.evidence_images ?? []
                )}
                showArrows={false}
              />
            </div>
            <div className="col-12 col-md-8 col-lg-8">
              <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3">
                {t('reportedDetails')}
              </p>

              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 mb-2">
                  <div className="col-12 col-md-12 col-lg-12 px-0">
                    <div className="clide-v2-title mb-1">
                      <label className="clide-v2-fs-4 mb-0">
                        {t('incidentDescription')} :
                      </label>
                    </div>
                  </div>
                  <div className="clide-v2-title mb-1">
                    <label className="">
                      {iraBasicInfo.observation != "" ? (
                        <div className="output-text">
                          {iraBasicInfo.observation}
                        </div>
                      ) : (
                        "NA"
                      )}
                    </label>

                    {iraBasicInfo.observation_record != undefined &&
                      iraBasicInfo.observation_record.substr(
                        iraBasicInfo.observation_record.length - 3
                      ) == "mp3" && (
                        <AudioPlayer
                          src={iraBasicInfo.observation_record}
                          showJumpControls={true}
                          layout="horizontal-reverse"
                          customVolumeControls={[]}
                          customAdditionalControls={[]}
                          className="border-0  shadow-none"
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div className="cbp_tmicon cbp_tmicon-screen">
          <TimelineInfoIcon />
        </div>
        <div className="cbp_tmlabel clide-v2-white-box">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 mb-2">
              <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                {t('activityInfo')}
              </p>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="col-12 col-md-12 col-lg-12 px-0">
                    <div className="clide-v2-title mb-1">
                      <label className="clide-v2-fs-4 mb-0">{t('service')}</label>
                    </div>
                  </div>
                  <div className="clide-v2-title mb-1">
                    <label className="">{iraBasicInfo.service}</label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="col-12 col-md-12 col-lg-12 px-0">
                    <div className="clide-v2-title mb-1">
                      <label className="clide-v2-fs-4 mb-0">
                        {t('plantProject')}{" "}
                      </label>
                    </div>
                  </div>
                  <div className="clide-v2-title mb-1">
                    <label className="">{iraBasicInfo.project}</label>
                  </div>
                </div>
                {CheckFormSettingField(state.iraFormSetting, "barrier") ? (
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="col-12 col-md-12 col-lg-12 px-0">
                      <div className="clide-v2-title mb-1">
                        <label className="clide-v2-fs-4 mb-0">{t('barrier')}</label>
                      </div>
                    </div>
                    <div className="clide-v2-title mb-1">
                      <label className="">{iraBasicInfo.barrier}</label>
                    </div>
                  </div>
                ) : null}

                {CheckFormSettingField(state?.iraFormSetting, "sub hazard") ? (
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="col-12 col-md-12 col-lg-12 px-0">
                      <div className="clide-v2-title mb-1">
                        <label className="clide-v2-fs-4 mb-0">
                          {t('subHazard')}{" "}
                        </label>
                      </div>
                    </div>
                    <div className="clide-v2-title mb-1">
                      <label className="">
                        {iraBasicInfo.sub_hazard ?? "NA"}
                      </label>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 mb-2">
              <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                {t("departmentContrLocation")}
              </p>
              <div className="row">
                {CheckFormSettingField(state.iraFormSetting, "department") ? (
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="col-12 col-md-12 col-lg-12 px-0">
                      <div className="clide-v2-title mb-1">
                        <label className="clide-v2-fs-4 mb-0">
                          {t('department')}{" "}
                        </label>
                      </div>
                    </div>
                    <div className="clide-v2-title mb-1">
                      <label className="">{iraBasicInfo.department}</label>
                    </div>
                  </div>
                ) : null}

                {CheckFormSettingField(state.iraFormSetting, "contractor") &&
                state.showContractor &&
                iraBasicInfo.contractor ? (
                  <div className="col-12 col-md-6 col-lg-6 px-0">
                    <div className="col-12 col-md-12 col-lg-12 px-0">
                      <div className="clide-v2-title mb-1">
                        <label className="clide-v2-fs-4 mb-0">
                          {t('contractor')}{" "}
                        </label>
                      </div>
                    </div>
                    <div className="clide-v2-title mb-1">
                      <label className="">
                        {iraBasicInfo.contractor ?? "NA"}
                      </label>
                    </div>
                  </div>
                ) : null}

                {CheckFormSettingField(state.iraFormSetting, "zone") &&
                state.showZone &&
                iraBasicInfo.zone ? (
                  <div className="col-12 col-md-6 col-lg-6">
                    {state.showZone && iraBasicInfo.zone && (
                      <>
                        <div className="col-12 col-md-12 col-lg-12 px-0">
                          <div className="clide-v2-title mb-1">
                            <label className="clide-v2-fs-4 mb-0">{t('zone')} </label>
                          </div>
                        </div>
                        <div className="clide-v2-title mb-1">
                          <label className="">
                            {iraBasicInfo.zone ?? "NA"}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                ) : null}
                {CheckFormSettingField(state.iraFormSetting, "location") &&
                state.showLocation &&
                iraBasicInfo.location ? (
                  <div className="col-12 col-md-6 col-lg-6 px-0">
                    {state.showLocation && iraBasicInfo.location && (
                      <>
                        <div className="col-12 col-md-12 col-lg-12 px-0">
                          <div className="clide-v2-title mb-1">
                            <label className="clide-v2-fs-4 mb-0">
                              {t('location')}{" "}
                            </label>
                          </div>
                        </div>
                        <div className="clide-v2-title mb-1">
                          <label className="">
                            {iraBasicInfo.location ?? "NA"}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div className="cbp_tmicon cbp_tmicon-screen">
          <TimelineInfoIcon />
        </div>
        <div className="cbp_tmlabel clide-v2-white-box">
          <div className="row">
            <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
              {t('incidentDetails')}
            </p>
            <div className="col-12 col-md-5 col-lg-5 mb-2">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="col-12 col-md-12 col-lg-12 px-0">
                    <div className="clide-v2-title mb-1">
                      <label className="clide-v2-fs-4">{t('incidentDate')} </label>
                    </div>
                  </div>
                  <div className="clide-v2-title mb-1">
                    <label className="">{`${iraBasicInfo.incident_date}`}</label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="col-12 col-md-12 col-lg-12 px-0">
                    <div className="clide-v2-title mb-1">
                      <label className="clide-v2-fs-4">{t('incidentTm')} </label>
                    </div>
                  </div>
                  <div className="clide-v2-title mb-1">
                    <label className=""> {iraBasicInfo.incident_time}</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-7 col-lg-7 mb-2">
              <div className="col-12 col-md-12 col-lg-12 px-0">
                <div className="clide-v2-title mb-1">
                  <label className="clide-v2-fs-4">
                    {t('incidentEmployeeDetails')}{" "}
                  </label>
                </div>
                <div className="">
                  <div className="d-flex overflow-auto p-4">
                    {state.incidentPersonName?.map((data, index) => {
                      const detail =
                        getUserProfileDetails(
                          parseInt(data.searched_incident_person_id),
                          state.userLists
                        ) ?? {};
                      const isUserFromOrgnization =
                        data?.searched_incident_person_id;
                      let trigger = {};
                      return (
                        <div className="clide-v2-white-box ms-3  ">
                          <div className="d-flex p-1 row text-center">
                            <div className="mt-2 col">
                              {isUserFromOrgnization ? (
                                <GetPopoverFrame
                                  isManualTrigger
                                  element={
                                    <>
                                      <img
                                        src={detail?.user_pic}
                                        alt={t('avatar')}
                                        className="clide-v2-profile-img  cursor-p"
                                        onClick={() => trigger(true)}
                                      />
                                    </>
                                  }
                                  mappingKeys={() => {
                                    return {
                                      ID: detail["employeeId"] ?? "NA",
                                      Department:
                                        detail["field"]?.split(":")[1] ?? "NA",
                                      Type: detail["userType"] ?? "NA",
                                      Designation:
                                        detail["designation"] ?? "NA",
                                      Contact: detail["contact_number"] ?? "NA",
                                      // Zone: detail['designation'],
                                      // Location: detail['designation'],
                                    };
                                  }}
                                  extractTrigger={(el) => (trigger = el)}
                                />
                              ) : (
                                <img
                                  src={require("assets/sls-icons/V2. New Development/user.svg")}
                                  className="clide-v2-profile-img  "
                                  alt={t('avatar')}
                                />
                              )}
                            </div>
                            <div className="p-1 pe-3 col">
                              <div className="col-12 col-md-12 col-lg-12 px-0">
                                <label className="clide-v2-fs-5 fw-semibold white-space-nowrap">
                                  {data.incident_person_name ??
                                    "Incident Person"}
                                </label>
                              </div>
                              <div className="clide-v2-title mb-1">
                                <label className="clide-v2-primary-color">
                                  ({data.incident_person_id ?? index + 1})
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      {iraBasicInfo.tl_assign_date != "" ? (
        <li>
          <div className="cbp_tmicon cbp_tmicon-mail">
            <TimelineCalendarIcon />
          </div>
          <div className="cbp_tmlabel clide-v2-white-box">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-8">
                <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-2">
                  {t('iraInvestigation')}
                </p>
              </div>
              <div className="col-12 col-md-4 col-lg-4 text-end">
                <button className="clide-v2-btn-template clide-v2-primary my-1 px-3 ">
                  <span className="clide-v2-white-color">{t('startTime')}</span>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="col-12 col-md-12 col-lg-12 px-0">
                  <div className="clide-v2-title mb-1">
                    <label className="clide-v2-fs-4">
                      {t('startDate')}
                      <ReactTooltip
                        effect="solid"
                        place="right"
                        id={`targetDate`}
                      >
                        <>
                          <div className="output-text font-16 font-weight-500 ">
                            {t('targetDateComplete')}
                          </div>
                        </>
                      </ReactTooltip>
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        data-tip
                        data-for={`startDate`}
                      >
                        <path
                          d="M8.5 1.59375C7.13408 1.59375 5.79882 1.99879 4.6631 2.75766C3.52737 3.51653 2.64218 4.59514 2.11946 5.85709C1.59674 7.11904 1.45998 8.50766 1.72645 9.84734C1.99293 11.187 2.65069 12.4176 3.61655 13.3835C4.5824 14.3493 5.81298 15.0071 7.15266 15.2735C8.49234 15.54 9.88096 15.4033 11.1429 14.8805C12.4049 14.3578 13.4835 13.4726 14.2423 12.3369C15.0012 11.2012 15.4063 9.86593 15.4063 8.5C15.4043 6.66894 14.6761 4.91343 13.3813 3.61868C12.0866 2.32393 10.3311 1.59568 8.5 1.59375ZM8.23438 4.78125C8.39198 4.78125 8.54605 4.82799 8.6771 4.91555C8.80814 5.00311 8.91028 5.12756 8.97059 5.27317C9.03091 5.41878 9.04669 5.57901 9.01594 5.73359C8.98519 5.88817 8.9093 6.03016 8.79785 6.1416C8.68641 6.25305 8.54442 6.32894 8.38984 6.35969C8.23526 6.39044 8.07504 6.37466 7.92943 6.31434C7.78382 6.25403 7.65936 6.15189 7.5718 6.02084C7.48424 5.8898 7.4375 5.73573 7.4375 5.57812C7.4375 5.36678 7.52146 5.16409 7.6709 5.01465C7.82035 4.86521 8.02303 4.78125 8.23438 4.78125ZM9.03125 12.2188C8.74946 12.2188 8.47921 12.1068 8.27995 11.9076C8.08069 11.7083 7.96875 11.438 7.96875 11.1562V8.5C7.82786 8.5 7.69273 8.44403 7.5931 8.3444C7.49347 8.24477 7.4375 8.10965 7.4375 7.96875C7.4375 7.82785 7.49347 7.69273 7.5931 7.5931C7.69273 7.49347 7.82786 7.4375 7.96875 7.4375C8.25055 7.4375 8.5208 7.54944 8.72005 7.7487C8.91931 7.94796 9.03125 8.21821 9.03125 8.5V11.1562C9.17215 11.1562 9.30728 11.2122 9.4069 11.3118C9.50653 11.4115 9.5625 11.5466 9.5625 11.6875C9.5625 11.8284 9.50653 11.9635 9.4069 12.0632C9.30728 12.1628 9.17215 12.2188 9.03125 12.2188Z"
                          fill="#FF6347"
                        ></path>
                      </svg>
                    </label>
                  </div>
                </div>
                <div className="clide-v2-title mb-1">
                  <label className="">
                    {" "}
                    {iraBasicInfo.tl_assign_date ?? ""}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </li>
      ) : null}

      {iraBasicInfo.assign_to_id != "" ? (
        <li>
          <div className="cbp_tmicon cbp_tmicon-mail">
            <TimelineCalendarIcon />
          </div>
          <div className="cbp_tmlabel clide-v2-white-box">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-8">
                <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-2">
                  {t('iraInvestigationTarget')}
                </p>
              </div>
              <div className="col-12 col-md-4 col-lg-4 text-end">
                <button className="clide-v2-btn-template clide-v2-primary my-1 px-3 ">
                  <span className="clide-v2-white-color">{t('teamDueDate')}</span>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="col-12 col-md-12 col-lg-12 px-0">
                  <div className="clide-v2-title mb-1">
                    <label className="clide-v2-fs-4">
                      {t('targetDate')}
                      <ReactTooltip
                        effect="solid"
                        place="right"
                        id={`startDate`}
                      >
                        <>
                          <div className="output-text font-16 font-weight-500 ">
                            {t('investigationTeamAssigned')}
                          </div>
                        </>
                      </ReactTooltip>
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        data-tip
                        data-for={`targetDate`}
                      >
                        <path
                          d="M8.5 1.59375C7.13408 1.59375 5.79882 1.99879 4.6631 2.75766C3.52737 3.51653 2.64218 4.59514 2.11946 5.85709C1.59674 7.11904 1.45998 8.50766 1.72645 9.84734C1.99293 11.187 2.65069 12.4176 3.61655 13.3835C4.5824 14.3493 5.81298 15.0071 7.15266 15.2735C8.49234 15.54 9.88096 15.4033 11.1429 14.8805C12.4049 14.3578 13.4835 13.4726 14.2423 12.3369C15.0012 11.2012 15.4063 9.86593 15.4063 8.5C15.4043 6.66894 14.6761 4.91343 13.3813 3.61868C12.0866 2.32393 10.3311 1.59568 8.5 1.59375ZM8.23438 4.78125C8.39198 4.78125 8.54605 4.82799 8.6771 4.91555C8.80814 5.00311 8.91028 5.12756 8.97059 5.27317C9.03091 5.41878 9.04669 5.57901 9.01594 5.73359C8.98519 5.88817 8.9093 6.03016 8.79785 6.1416C8.68641 6.25305 8.54442 6.32894 8.38984 6.35969C8.23526 6.39044 8.07504 6.37466 7.92943 6.31434C7.78382 6.25403 7.65936 6.15189 7.5718 6.02084C7.48424 5.8898 7.4375 5.73573 7.4375 5.57812C7.4375 5.36678 7.52146 5.16409 7.6709 5.01465C7.82035 4.86521 8.02303 4.78125 8.23438 4.78125ZM9.03125 12.2188C8.74946 12.2188 8.47921 12.1068 8.27995 11.9076C8.08069 11.7083 7.96875 11.438 7.96875 11.1562V8.5C7.82786 8.5 7.69273 8.44403 7.5931 8.3444C7.49347 8.24477 7.4375 8.10965 7.4375 7.96875C7.4375 7.82785 7.49347 7.69273 7.5931 7.5931C7.69273 7.49347 7.82786 7.4375 7.96875 7.4375C8.25055 7.4375 8.5208 7.54944 8.72005 7.7487C8.91931 7.94796 9.03125 8.21821 9.03125 8.5V11.1562C9.17215 11.1562 9.30728 11.2122 9.4069 11.3118C9.50653 11.4115 9.5625 11.5466 9.5625 11.6875C9.5625 11.8284 9.50653 11.9635 9.4069 12.0632C9.30728 12.1628 9.17215 12.2188 9.03125 12.2188Z"
                          fill="#FF6347"
                        ></path>
                      </svg>
                    </label>
                  </div>
                </div>
                <div className="clide-v2-title mb-1">
                  <label className="">{iraBasicInfo.target_date ?? "NA"}</label>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="col-12 col-md-12 col-lg-12 px-0">
                  <div className="clide-v2-title mb-1">
                    <label className="clide-v2-fs-4">{t('investigationTeam')} </label>
                  </div>
                </div>
                <div className="clide-v2-title mb-1">
                  <div className="d-flex flex-wrap gap-2">
                    {iraBasicInfo?.people_involved_list?.map((item, index) => (
                      <div>
                        <div key={`investigation-team-${index}`}>
                          <button
                            type="button"
                            className="icon-button"
                            title={item.user_name}
                          >
                            <span className="">
                              {getInitials(item.user_name)}
                            </span>
                            {item.status == 1 ? (
                              <span className="icon-button__badge">
                                <TeamLeadBadge />
                              </span>
                            ) : null}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      ) : null}

      {iraBasicInfo.closing_date != 0 ? (
        <li>
          <div className="cbp_tmicon cbp_tmicon-mail">
            <TimelineCalendarIcon />
          </div>
          <div className="cbp_tmlabel clide-v2-white-box">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-8">
                <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-2">
                  {t('iraInvestigationComplete')}
                </p>
              </div>
              <div className="col-12 col-md-4 col-lg-4 text-end">
                <button className="clide-v2-btn-template clide-v2-primary my-1 px-3 ">
                  <span className="clide-v2-white-color">{t('completedDate')}</span>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="col-12 col-md-12 col-lg-12 px-0">
                  <div className="clide-v2-title mb-1">
                    <label className="clide-v2-fs-4">
                      {t('iraInvestigationCompletedDate')}
                    </label>
                  </div>
                </div>
                <div className="clide-v2-title mb-1">
                  <label className=""> {iraBasicInfo.closing_date ?? ""}</label>
                </div>
              </div>
            </div>
          </div>
        </li>
      ) : null}

      {iraBasicInfo.capa_completion_date != "" ? (
        <li>
          <div className="cbp_tmicon cbp_tmicon-mail">
            <TimelineCalendarIcon />
          </div>
          <div className="cbp_tmlabel clide-v2-white-box">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-8">
                <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-2">
                  {t('capaRecommendation')} &amp; {t('capaStatus')}
                </p>
              </div>
              <div className="col-12 col-md-4 col-lg-4 text-end">
                <button className="clide-v2-btn-template clide-v2-primary my-1 px-3 ">
                  <span className="clide-v2-white-color">{t('capaDetail')}</span>
                </button>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-4 col-lg-4">
                <div className="col-12 col-md-12 col-lg-12 px-0">
                  <div className="clide-v2-title mb-1">
                    <label className="clide-v2-fs-4">{t('recommendationDate')}</label>
                  </div>
                </div>
                <div className="clide-v2-title mb-1">
                  <label className="">
                    {iraBasicInfo.capa_completion_date ?? ""}
                  </label>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-8">
                <div className="col-12 col-md-12 col-lg-12 px-0">
                  <div className="clide-v2-title mb-1">
                    <label className="clide-v2-fs-4">
                      {t('completedPercentage')}
                    </label>
                  </div>
                </div>
                <div className="clide-v2-title mb-1">
                  <div className="progress">
                    <div
                      className="progress-bar clide-v2-primary"
                      role="progressbar"
                      style={{ width: `${iraBasicInfo.progress}%` }}
                      aria-valuenow={iraBasicInfo.progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {iraBasicInfo.progress}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      ) : null}
    </Fragment>
  );
};

export default DetailBasicInfo;
