import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { Redirect, withRouter } from "react-router-dom";
import Url from "app/shared/constants/Url";
import axios from "axios";
import {
  SuccessToast,
  ErrorToast,
} from "app/shared/components/Popups/ToastMessage";
import ClideLoader from "app/shared/ui-kit/loader";
const qs = require("qs");

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkExpired: false,
      token: this.props.match.params.token,
      flag: this.props.match.params.flag,
      formSubmitting: false,
      redirectToReferrer: false,
      email: null,
      emailError: false,
    };
  }

  componentDidMount() {
    this._checkLinkExpiry();
  }

  _checkLinkExpiry = () => {
    axios
      .post(Url.linkExpire, qs.stringify({ token: this.state.token }), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        console.log("Link expiry :", res);
        if (res.data.msg === "Link Expired") {
          this.setState({ linkExpired: true });
        } else if (res.data.status === 401) {
          this.setState({ linkExpired: true });
        } else {
          this.setState({ linkExpired: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  _handleResendLink = async () => {
    if (this._validEmail(this.state.email)) {
      await axios
        .post(
          Url.resendLink,
          qs.stringify({
            email: this.state.email,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            SuccessToast(
              "Resend password link successfully! please check your email..."
            );
            setTimeout(() => {
              this.setState({ redirectToReferrer: true });
            }, 2000);
          } else {
            ErrorToast(res.data.msg);
          }
          this.setState({ formSubmitting: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ formSubmitting: false });
        });
    } else {
      await this.setState({ emailError: true });
    }
  };

  _validEmail = (email) => {
    let emailRegex = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    if (email && email.match(emailRegex)) {
      return true;
    }
    return false;
  };

  _handleEmailChange = async (event) => {
    let em = event.target.value;
    await this.setState({ email: em, emailError: em ? false : true });
  };

  render() {
    if (this.state.redirectToReferrer) {
      return <Redirect to={"/auth/login"} />;
    }
    if (this.state.formSubmitting) {
      return <ClideLoader />;
    }

    if (this.state.linkExpired) {
      return (
        <div>
          <h4 className="session-expire">
            The password reset link is no longer valid. Please request another
            password reset email.
            <p>Please enter email id, which is used at time of registration</p>
          </h4>

          <div className="login-form">
            <h6> Resend Password </h6>
            <div className="floatingLabelInput">
              <label className={this.state.email ? "on" : null}>Email Id</label>
              <input
                name="email"
                type="email"
                placeholder="Enter previous email id"
                onChange={this._handleEmailChange}
              />
              {!!this.state.emailError && (
                <p className="text-danger"> Please enter valid email </p>
              )}
            </div>
            <div className="login-btn">
              <Button
                type="submit"
                className={"red-btn"}
                onClick={this._handleResendLink}
              >
                {this.state.formSubmitting ? "Please wait..." : "SUBMIT"}
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="login-form">
          {this.state.flag == 2 ? (
            <h4>Reset Password</h4>
          ) : (
            <h4>Set Password</h4>
          )}

          <Formik
            initialValues={{ confirmPassword: "", password: "" }}
            validate={(values) => {
              let errors = {};
              if (values.password === "") {
                errors.password = "New Password is required";
              } else if (
                !/^(?=.*\d)(?=.*[A-Za-z])[A-Za-z\d$@$!%*#?&]{8,}$/.test(
                  values.password
                )
              ) {
                errors.password =
                  "Invalid password format (Password should contain minimum 8 and maximun 16 characters with atleast 1 number and 1 special characters)";
              }
              if (values.confirmPassword === "") {
                errors.confirmPassword = "Confirm Password is required";
              } else if (values.password !== values.confirmPassword) {
                errors.confirmPassword =
                  "New Password and Confirm does not match";
              }
              return errors;
            }}
            onSubmit={async (values, actions) => {
              this.setState({ formSubmitting: true },
              await axios
                .post(
                  Url.setPassword,
                  qs.stringify({
                    token: this.state.token,
                    password: values.password,
                  }),
                  {
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                  }
                )
                .then((res) => {
                  if (res.data.status === 200) {
                    SuccessToast(
                      "Your password has been set successfully! Redirecting to Login..."
                    );
                    setTimeout(() => {
                      this.setState({ redirectToReferrer: true });
                    }, 2000);
                  } else {
                    ErrorToast("Password not set. Please try again.");
                  }
                  this.setState({ formSubmitting: false });
                })
                .catch((error) => {
                  console.log(error);
                  this.setState({ formSubmitting: false });
                }));
            }}
          >
            {({
              touched,
              errors,
              isSubmitting,
              handleSubmit,
              isValid,
              dirty,
              values,
            }) => (
              <Form onSubmit={handleSubmit} className="input-container">
                <div className="input-group">
                 
                  <Field
                    name="password"
                    type="password"
                    placeholder="New Password"
                      className="w-100 border-0"
                  />
                  <ErrorMessage
                    component="div"
                    name="password"
                     className="inputErrorMsg text-danger"
                  />
                </div>
                <div className="input-group">
                 
                  <Field
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm Password"
                    className="w-100 border-0"
                  />
                  <ErrorMessage
                    component="div"
                    name="confirmPassword"
                    
                    className="inputErrorMsg text-danger"
                  />
                </div>
                
                  <button
                    type="submit"
                   
                    disabled={!isValid || this.state.formSubmitting || !dirty}
                  >
                    {this.state.formSubmitting ? "Please wait..." : "SUBMIT"}
                  </button>
                
              </Form>
            )}
          </Formik>
        </div>
      );
    }
  }
}

export default withRouter(ResetPassword);
