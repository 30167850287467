import React, { Component } from "react";
import AuthLeftBottom from "./AuthLeftBottom";
import Url from "app/shared/constants/Url";
import ConfirmPopupImage from "../../../../assets/images/CLIDEOffice.png";
import { withTranslation } from 'react-i18next';

  export default withTranslation(['util'])(
    class AuthLeft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentData: {},
    };
  }

  componentWillMount = () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(Url.loginPageContent);

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState({ contentData: response.data.data });
          }
        })
        .catch((error) => console.log(error));
    });
  };

  render() {
    const {t} = this.props;
    return (
      <div className="sign-column w2">
        <div className="intro-p">
          <div className="logo">
            <img
              className="logo-img"
              src={require("assets/images/CLIDEOffice.png")}
              alt={t('logo')}
            />
          </div>
          <div className="canvas-logo">
            <img src={this.state.contentData.gif_url} alt="login" />
          </div>
          <div className="intro-content">
            <div className="intro-title">
              <strong className="login-title">
                {this.state.contentData?.title}
              </strong>
            </div>
            <div className="intro-title">
              {this.state.contentData?.description}
            </div>
          </div>
          <div className="shareArticle">
            <div className="shareSocial">
              <ul className="socialList">
                <li>
                  <a
                    href="https://www.linkedin.com/showcase/clide-safety/?viewAsMember=true"
                    target="_blank"
                    title={t('viewOurLinkedinPage')}
                  >
                   <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 14 14"
                    >
                      <path
                        fill="none"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.574 1.767a1.316 1.316 0 0 1-1.287 1.326A1.346 1.346 0 0 1 .99 1.767A1.326 1.326 0 0 1 2.287.5a1.316 1.316 0 0 1 1.287 1.267M1.129 5.449c0-.762.485-.643 1.158-.643s1.148-.119 1.148.643v7.424c0 .772-.485.614-1.148.614s-1.158.158-1.158-.614zm4.306.001c0-.426.158-.585.405-.634s1.099 0 1.396 0s.416.485.406.851a2.49 2.49 0 0 1 2.217-.99a2.97 2.97 0 0 1 3.148 3.098v5.068c0 .772-.475.614-1.149.614c-.673 0-1.148.158-1.148-.614V8.884A1.425 1.425 0 0 0 9.206 7.34A1.435 1.435 0 0 0 7.74 8.914v3.959c0 .772-.485.614-1.158.614s-1.148.158-1.148-.614V5.449Z"
                      ></path>
                    </svg>
                    
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/CLIDEo"
                    target="_blank"
                    title={t('viewOurTwitterPage')}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" ><path fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M2 18.5C3.765 19.521 5.814 20 8 20c6.48 0 11.762-5.137 11.992-11.562L22 4.5l-3.354.5A4 4 0 0 0 16 4c-2.572 0-4.5 2.517-3.88 4.98c-3.552.23-6.771-1.959-8.633-4.875c-1.236 4.197-.09 9.251 3.013 12.366c0 1.176-3 1.878-4.5 2.029" color="white"></path></svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/CLIDEOffice-107416677331001/?modal=admin_todo_tour"
                    target="_blank"
                    title={t('viewOurFacebookPage')}
                  >
                   <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" ><path fill="white" fillRule="evenodd" d="M10.488 3.788A5.25 5.25 0 0 1 14.2 2.25h2.7a.75.75 0 0 1 .75.75v3.6a.75.75 0 0 1-.75.75h-2.7a.15.15 0 0 0-.15.15v1.95h2.85a.75.75 0 0 1 .728.932l-.9 3.6a.75.75 0 0 1-.728.568h-1.95V21a.75.75 0 0 1-.75.75H9.7a.75.75 0 0 1-.75-.75v-6.45H7a.75.75 0 0 1-.75-.75v-3.6A.75.75 0 0 1 7 9.45h1.95V7.5a5.25 5.25 0 0 1 1.538-3.712M14.2 3.75a3.75 3.75 0 0 0-3.75 3.75v2.7a.75.75 0 0 1-.75.75H7.75v2.1H9.7a.75.75 0 0 1 .75.75v6.45h2.1V13.8a.75.75 0 0 1 .75-.75h2.114l.525-2.1H13.3a.75.75 0 0 1-.75-.75V7.5a1.65 1.65 0 0 1 1.65-1.65h1.95v-2.1z" clipRule="evenodd"></path></svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UC0pxWwgntN9AXOEH0gr_yug"
                    target="_blank"
                    title={t('visitOurYoutubeChannel')}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" color="white"><path d="M12 20.5c1.81 0 3.545-.179 5.153-.507c2.01-.41 3.014-.614 3.93-1.792c.917-1.179.917-2.532.917-5.238v-1.926c0-2.706 0-4.06-.917-5.238c-.916-1.178-1.92-1.383-3.93-1.792A26 26 0 0 0 12 3.5c-1.81 0-3.545.179-5.153.507c-2.01.41-3.014.614-3.93 1.792C2 6.978 2 8.331 2 11.037v1.926c0 2.706 0 4.06.917 5.238c.916 1.178 1.92 1.383 3.93 1.792c1.608.328 3.343.507 5.153.507"/><path d="M15.962 12.313c-.148.606-.938 1.04-2.517 1.911c-1.718.947-2.577 1.42-3.272 1.237a1.7 1.7 0 0 1-.635-.317C9 14.709 9 13.806 9 12s0-2.709.538-3.144c.182-.147.4-.256.635-.317c.695-.183 1.554.29 3.272 1.237c1.58.87 2.369 1.305 2.517 1.911c.05.206.05.42 0 .626"/></g></svg>
                  </a>
                </li>
                <li>
                  <a href="/market" target="_blank" title="Visit Market-Place">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="M17 18a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2c0-1.11.89-2 2-2M1 2h3.27l.94 2H20a1 1 0 0 1 1 1c0 .17-.05.34-.12.5l-3.58 6.47c-.34.61-1 1.03-1.75 1.03H8.1l-.9 1.63l-.03.12a.25.25 0 0 0 .25.25H19v2H7a2 2 0 0 1-2-2c0-.35.09-.68.24-.96l1.36-2.45L3 4H1zm6 16a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2c0-1.11.89-2 2-2m9-7l2.78-5H6.14l2.36 5z"/></svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.Clideoffice"
                    target="_blank"
                    title={t('clideAnalyserApp')}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="white" d="M232.4 114.49L88.32 26.35a16 16 0 0 0-16.2-.3A15.86 15.86 0 0 0 64 39.87v176.26A15.94 15.94 0 0 0 80 232a16.07 16.07 0 0 0 8.36-2.35l144.04-88.14a15.81 15.81 0 0 0 0-27ZM80 215.94V40l143.83 88Z"/></svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <a
              className="login-links"
              href="https://eclide.clideoffice.com"
              target="_blank"
            >
              <p className="footer-text">{t('eclideAUC')}</p>
            </a>
            <span>|</span>
            <a
              className="login-links"
              href="https://clideoffice.com"
              target="_blank"
            >
              <p className="footer-text">{t('clideAnalyser')} </p>
            </a>
            <span>|</span>
            <a
              className="login-links"
              href="https://clideg.clideoffice.com"
              target="_blank"
            >
              <p className="footer-text">{t('clidegAUC')}</p>
            </a>
          </div>
          <div className="powered-by">{t('poweredByClideG')}</div>
        </div>
      </div>
    );
  }
}
)