/* eslint-disable jsx-a11y/alt-text */
import { DateTableComponent as DataTable } from "app/shared/components/ReusableComponents/DataTable";
import React from "react";

import Scrollbars from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//assets
import {
  AccordinPlay,
  AccordinPlayDown,
  KanbanFilterIcon,
  TeamLeadBadge,
  IconPdf,
} from "assets/Iconsv2/Iconsv2";
//utills
import { getInitials } from "../../utils/moduleHelp";
const ListCompletedSection = ({ props, functionContent, state = {} }) => {
  const [openIdx, setOpenIdx] = React.useState([0]);
  const { t } = useTranslation('nm');

  const isPanelOpen = (idx) => {
    return openIdx.includes(idx);
  };
  const manageState = (idx) => {
    if (isPanelOpen(idx)) {
      setOpenIdx(openIdx.filter((el) => el !== idx));
      return;
    }
    setOpenIdx([...openIdx, idx]);
  };
  return (
    <div  className={`col-12  mb-2 ${state.listMode === "list" ? "col-md-12 col-lg-12" :" col-md-4 col-lg-4"}  ` }>
      <div className="clide-v2-card-box clide-v2-border-top-0">
        <div className=" clide-v2-p-0">
          <div className="border-bottom">
            <div className="clide-v2-side-color-capalist clide-v2-border-10 clide-v2-white clide-v2-border-green-color ">
              <div className="d-flex">
                <div className="p-2 w-100 ">
                  <div className="p-2">
                    {" "}
                    {t('completed')}{" |"}{" "}
                    {state.completedFilterFlag == 1
                      ? props.completeFilterCount || 0
                      : props.nmCompletedCount || 0}
                  </div>
                </div>
                <div className="p-3 flex-shrink-1 ">
                  <button
                    className="border-0 btn p-0"
                    onClick={() => {
                      functionContent.completedModal(true);
                    }}
                  >
                    <KanbanFilterIcon />
                  </button>
                </div>
              </div>
            </div>
            <div className="clide-v2-white-box m-3 mb-0">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('searchByCategory')}
                  aria-label="Search"
                  aria-describedby="button-addon2"
                  value={props.searchList6}
                  onChange={(e) => props._handleCompletedSearch(e)}
                />
                <button
                  className="btn btn-secondary clide-v2-bg-white clide-v2-border-color border-start-0"
                  type="button"
                  id="button-addon2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="#F56620"
                      d="M8.333 3.334a5 5 0 100 10 5 5 0 000-10zm-6.666 5A6.667 6.667 0 1113.6 12.422l4.49 4.49a.833.833 0 01-1.18 1.178l-4.488-4.49A6.667 6.667 0 011.667 8.334z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            {state.listMode === "list" ? (
              <div className="mt-3 ">
                <DataTable
                  {...functionContent.getFieldProps(
                    props.nmCompleted,
                    ["Closed On", "Team Lead", "Deviation"],
                    "completed"
                  )}
                  scrollProps={{
                    onScrollStop:
                      props.nmCompleted.length != props.nmCompletedCount ||
                      props.completeFilterCount != props.nmCompleted.length
                        ? 
                        functionContent._handleCompleteScrollbarPagination
                        : null,
                  }}
                />
              </div>
            ) : (
              <Scrollbars
                style={{ height: `calc(100vh - 18vw)` }}
                renderTrackHorizontal={(props) => (
                  <div
                    {...props}
                    className="track-horizontal"
                    style={{ display: "none" }}
                  />
                )}
                renderThumbHorizontal={(props) => (
                  <div
                    {...props}
                    className="thumb-horizontal"
                    style={{ display: "none" }}
                  />
                )}
                renderThumbVertical={(props) => (
                  <div
                    {...props}
                    style={{
                      position: "relative",
                      display: "block",
                      width: "100%",
                      cursor: "pointer",
                      borderRadius: "inherit",
                      backgroundColor: "rgba(0, 0, 0, 0.2)",
                      height: "100px",
                    }}
                  />
                )}
                onScrollStop={
                  props.nmCompleted.length != props.nmCompletedCount ||
                  props.completeFilterCount != props.nmCompleted.length
                    ? 
                    functionContent._handleCompleteScrollbarPagination
                    : null
                }
              >
                <div className=" p-3 w-100">
                  {props?.nmCompleted.map((data, index) => {
                    const isAriaExpand = isPanelOpen(index);
                    return (
                      <div
                        key={index}
                        className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10  mb-3"
                        style={{
                          borderColor: data.severity_color,
                        }}
                      >
                        <div className="d-flex w-100 border-bottom mb-2">
                          <div className="p-2 w-100 bd-highlight">
                            <Link
                              to={`/nm-detail/${data.nearmiss_id}/kanban/completed`}
                              className="text-decoration-none clide-v2-secondary-color"
                            ><span className="clide-v2-primary-color">#{data.nearmiss_id}</span>&nbsp;|&nbsp;
                              {data.type_of_act || data.type_of_Act}{" "}
                              |&nbsp;
                              {data.activity}
                            </Link>
                          </div>
                          {(data.overdue || data.overdue_days) != "0 Days" && (
                            <div className="p-2 w-100 bd-highlight">
                              {t('deviation')}{" |"}{data.overdue_days || data.overdue}
                            </div>
                          )}

                          <div className="p-2 flex-shrink-1 bd-highlight">
                            <button
                              className="border-0 btn p-0"
                              onClick={() => manageState(index)}
                            >
                              {!isAriaExpand ? (
                                <AccordinPlay />
                              ) : (
                                <AccordinPlayDown />
                              )}
                            </button>
                          </div>
                        </div>
                        {isAriaExpand && (
                          <div className="mb-1">
                            <div className="row p-1">
                              <div className="row p-0">
                                <div className="col-12 col-md-12 col-lg-9 p-0">
                                  <div className="row ">
                                    <div className="col-12 col-md-12 col-lg-6 mb-2">
                                      <div>{t('service')}</div>
                                      <div className="fw-semibold clide-v2-fs-5 d-flex align-items-center">
                                        {data.service}
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6 mb-2">
                                      <div>{t('plantProject')}</div>
                                      <div className="fw-semibold clide-v2-fs-5 d-flex align-items-center">
                                        {data.project}
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6 mb-2">
                                      <div>{t('reportedBy')}</div>
                                      <div className="fw-semibold clide-v2-fs-5 d-flex align-items-center">
                                        <img
                                          src={data.reported_by_pic}
                                          class="clide-v2-profile-img mr-2"
                                        />
                                        {data.reported_by}
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6 mb-2">
                                      <div>{t('reportedOn')}</div>
                                      <div className="fw-semibold clide-v2-fs-5 d-flex align-items-center">
                                        {data.report_date}
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6 mb-2">
                                      <div>{t('teamLead')}</div>
                                      <div className="fw-semibold clide-v2-fs-5 d-flex align-items-center">
                                        <img
                                          src={data.assign_to_pic}
                                          class="clide-v2-profile-img mr-2"
                                        />
                                        {data.assign_to}
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6 mb-2">
                                      <div>{t('dueDate')}</div>
                                      <div className="fw-semibold clide-v2-fs-5 d-flex align-items-center">
                                        {data.target_date || "NA"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-3 text-center">
                                  {data.evidence != "" && (
                                    <div className="mb-2">
                                      {data.evidence[0]?.format === "video" ? (
                                        <button className="border-0 bg-transparent">
                                          <img
                                            src={require("assets/sls-icons/V2. New Development/Video Player (1).svg")}
                                            className=" object-fit-cover"
                                            onClick={() =>
                                              functionContent.setState({
                                                videoUrl:
                                                  data.evidence[0]?.link,
                                              })
                                            }
                                          />
                                        </button>
                                      ) : (
                                        <button className="bg-transparent border-0 w-100">
                                          <img
                                            onClick={() =>
                                              functionContent._handleLightBox(
                                                data.evidence
                                              )
                                            }
                                            style={{ height: "7vw" }}
                                            className="w-100 object-fit-cover cursor-pointer"
                                            src={data.evidence[0]?.link}
                                            title="Evidence"
                                          />
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-12 col-lg-5 mb-2 ps-0">
                                  <div class="clide-v2-fs-5 clide-v2-green clide-v2-white-color d-flex fw-semibold justify-content-between p-2 rounded">
                                    <span>{t('closedOn')}</span>
                                    <span class="float-end">
                                      {data.closing_date || "NA"}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-7 mb-2 pe-0">
                                  <div className="d-flex">
                                    <div className="w-75">
                                      <div className="d-flex overflow-auto">
                                        {data.team?.map((team) => {
                                          return team.status == 1 ? (
                                            <button
                                              type="button"
                                              className="icon-button"
                                              title={team.name}
                                            >
                                              <span className="">{getInitials(team.name)}</span>
                                              <span className="icon-button__badge">
                                                <TeamLeadBadge />
                                              </span>
                                            </button>
                                          ) : (
                                            <div className="clide-v2-icon cursor-pointer" title={team.name}>
                                              <div className="fw-semibold clide-v2-fs-5 d-flex align-items-center clide-v2-name-prefix">
                                              {getInitials(team.name)}
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                    <div className="w-25 ">
                                      <div className="clide-v2-icon float-end">
                                        <Link
                                          to={`/nm-report/${data.nearmiss_id}`}
                                          target="_blank"
                                          title="View Report"
                                        >
                                          <IconPdf />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </Scrollbars>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListCompletedSection;
