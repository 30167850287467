import React, { Component } from "react";
import { Button, Modal, Accordion } from "react-bootstrap";
import { ChevronDown, Circle, ArrowRight } from "react-feather";
import { withTranslation } from 'react-i18next';

class AssignedProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
    };
    this.closeModal = this.closeModal.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({ show: this.props.show });
    }
  }

  closeModal() {
    this.props.modalClose();
  }

  render() {
    const { t } = this.props;
    return (
      <div className="report-section">
        <Modal centered show={this.props.show} className="assigned-popup">
          <Modal.Title>
            <div className="modal-header">
              <h4 className="modal-title clide-v2-primary-color fw-bold clide-v2-fs-4">
                {t('assignedProject')}
              </h4>
              <button className="clide-v2-icon-btn" onClick={this.closeModal}>
                <svg
                  data-bs-dismiss="modal"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle 
                  cx="16" 
                  cy="16" 
                  r="15.5" 
                  fill="white" 
                  stroke="#FF6347"
                  />
                  <path
                    d="M17.2727 16L20.8352 12.4375C21.0043 12.2687 21.0994 12.0397 21.0996 11.8008C21.0998 11.5619 21.0051 11.3327 20.8364 11.1636C20.6676 10.9945 20.4386 10.8994 20.1997 10.8992C19.9608 10.899 19.7316 10.9937 19.5625 11.1625L16 14.725L12.4375 11.1625C12.2684 10.9934 12.0391 10.8984 11.8 10.8984C11.5609 10.8984 11.3316 10.9934 11.1625 11.1625C10.9934 11.3316 10.8984 11.5609 10.8984 11.8C10.8984 12.0391 10.9934 12.2684 11.1625 12.4375L14.725 16L11.1625 19.5625C10.9934 19.7316 10.8984 19.9609 10.8984 20.2C10.8984 20.4391 10.9934 20.6684 11.1625 20.8375C11.3316 21.0066 11.5609 21.1016 11.8 21.1016C12.0391 21.1016 12.2684 21.0066 12.4375 20.8375L16 17.275L19.5625 20.8375C19.7316 21.0066 19.9609 21.1016 20.2 21.1016C20.4391 21.1016 20.6684 21.0066 20.8375 20.8375C21.0066 20.6684 21.1016 20.4391 21.1016 20.2C21.1016 19.9609 21.0066 19.7316 20.8375 19.5625L17.2727 16Z"
                    fill="#FF6347"
                  />
                </svg>
              </button>
            </div>
          </Modal.Title>

          <Modal.Body>
            <div className="assigned-popup">
              {this.props.assignData && this.props.assignData.length > 0 && (
                <Accordion defaultActiveKey="0">
                  {this.props.assignData.map((assignItem, index) => (
                    <Accordion.Item eventKey={index} key={`assign-${index}`}>
                      <Accordion.Header>
                        <h6>{assignItem.service_name}</h6>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="inner-project-list">
                          {assignItem.project_list &&
                            assignItem.project_list.map((projectItem, pIndex) => (
                              <p key={`project-${pIndex}`}>
                                <ArrowRight /> {projectItem.project_name}
                              </p>
                            ))}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              )}
              {this.props.assignData && this.props.assignData.length === 0 && (
                <p className="text-center list-error">{t('noDataAvailable')}</p>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

AssignedProject.defaultProps = {
  show: false,
  assignData: [],
};
export default withTranslation(['workpermit', 'util'])(AssignedProject);
