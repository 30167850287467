import React, { Component } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import Url from "app/shared/constants/Url";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import { connect } from "react-redux";
import { checkPermittedRoute } from "app/shared/utility/UtilFunction";
import CommonDropdown from "../ReportModal/CommonDropdown";
import axios from "axios";
import { SuccessToast } from "app/shared/components/Popups/ToastMessage";
import { withRouter } from "react-router-dom";
import { requiredMessage } from "app/shared/constants/Messages";
import DatePicker from "react-datepicker";
import { Calendar } from "react-feather";
import moment from "moment";
import { convertDateFromTimezone, convertDateToUTC } from "app/shared/utility/tzUtilFunction";
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
const mapStateToProps = (state) => {
  const data = [...state.permitModule.permittedModuleList];
  return {
    permittedModuleList: data,
  };
};

class CloseModal extends Component {
  constructor(props) {
    super(props);
    let time = new Date();
    let addedTime = new Date();
    addedTime = addedTime.setHours(time.getHours() + 1);
    this.state = {
      closingReasonList: [{ id: 1, closingReason: "Work Completed" }, { id: 2, closingReason: "Work Stopped" }, { id: 3, closingReason: "Work Cancelled" }],
      declineReasonList: [{ id: 1, declineReason: "Risk Assessment Not done " }, { id: 2, declineReason: "No permit required for work" }, { id: 3, declineReason: "Insufficient Control measure" }, { id: 4, declineReason: "Work Cancelled" }, { id: 5, declineReason: "Work Stopped" }],
      extendReasonList: [{ id: 1, closingReason: "Work not completed" }, { id: 2, closingReason: "Limited resource" }],
      closingActionId: null,
      closingActionError: false,
      closingRemarkText: null,
      closingRemarkTextError: false,
      reasonList: [],
      dropDownTitle: null,
      selectedEndDate: addedTime,
      maxDatepickerDate: new Date(),
      url: "",
      extendedTimeDuration: 0,
    };
  }

  componentDidMount = async () => {
    var title = "Closing";
    var list = this.state.closingReasonList;
    var tempUrl = Url.formType1.workPermitClose;
    switch (this.props.type) {
      case "close":
        title = "Closing";
        list = this.state.closingReasonList;
        tempUrl = Url.formType1.workPermitClose;
        break;
      case "decline":
        title = "Decline";
        list = this.state.declineReasonList;
        tempUrl = Url.formType1.workPermitDecline;
        break;
      case "extend":
        title = "Extend";
        list = this.state.extendReasonList;
        tempUrl = Url.formType1.workPermitExtend;
        break;
      default:
        title = "Closing";
        list = this.state.closingReasonList;
        tempUrl = Url.formType1.workPermitClose;
    }
    await this.setState((prevState) => {
      let { dropDownTitle, reasonList, url } = prevState;
      dropDownTitle = title;
      reasonList = list;
      url = tempUrl;
      return { dropDownTitle, reasonList, url }
    })

    this._getRemainingTime();
  };

  _setDataLoading = async (status) => {
    await this.setState((prevState) => {
      let { dataLoading } = prevState;
      dataLoading = status;
      return { dataLoading };
    });
  };

  _getRemainingTime = () => {

    let diffInMilliSeconds = Math.abs(new Date(this.props.extendData?.endDate) - this.state.selectedEndDate) / 1000;
    console.log(diffInMilliSeconds);

    //Calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    console.log('calculated hours', hours);

    this.setState((prevState) => {

      prevState.extendedTimeDuration = hours;
      return prevState;

    });
  }

  _handleChange = async (item, type) => {
    await this.setState((prevState) => {
      switch (type) {
        case "closing_remark":
          let { closingRemarkText, closingRemarkError } = prevState;
          closingRemarkText = item;
          closingRemarkError = !item ? true : false;
          return { closingRemarkText, closingRemarkError };
        case "end_date":
          let { selectedEndDate, selectedEndDateError } = prevState;
          selectedEndDate = item;
          selectedEndDateError = !item ? true : false;
          return { selectedEndDate, selectedEndDateError };
        default:
          return prevState;
      }
    });
    this._getRemainingTime();
  };

  _closingActionChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { closingActionId, closingActionError } = prevState;
      closingActionId = id;
      closingActionError = id ? false : true;
      return { closingActionId, closingActionError };
    });
  };

  handleWorkPermitClose = async () => {
    let URL = this.state.url;
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let data = {}
    if (this.props.type == "close") {
      data = {
        work_permit_use_id: this.props.wpuId,
        closing_remark: this.state.closingRemarkText,
        closing_reason: this.state.closingActionId,
        closing_date: convertDateToUTC(new Date()),
      }
    } else if (this.props.type == "decline") {
      data = {
        work_permit_use_id: this.props.wpuId,
        decline_remark: this.state.closingRemarkText,
        decline_reason: this.state.closingActionId,
        decline_date: convertDateToUTC(new Date()),
      }
    } else {
      var endDate = moment(this.state.selectedEndDate).format(
        "YYYY-MM-DD HH:mm:ss");
      data = {
        work_permit_use_id: this.props.wpuId,
        extend_remark: this.state.closingRemarkText,
        extend_reason: this.state.closingActionId,
        end_date: endDate,
      }
    }

    // axiosAPI function for axios http call
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(URL, ContentType, data);

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            // setTableData(response.data.data);
            // workPermitApprovalList();
            // setDataloading(false);
            this.props.handleClose();
            this.props.refresh();
          }
        })
        .catch(async (error) => {
          // setDataloading(false);
        });
    });
  };

  render() {
    const {t} = this.props;
    return (
      <div>
        <Modal
          show={true}
          onHide={this.props.handleClose}
          className="closePopup"
          backdrop="static"
          keyboard={false}
        // style={{ width: "20vw" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{`${this.state.dropDownTitle} ${t('remarkReason')}`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Data Loading  */}
            <div
              className="loader"
              style={{ display: this.state.dataLoading ? "block" : "none" }}
            >
              <LoadingImage />
            </div>
            <div className="rd-content">
              <div className="rd-box">
                {this.props.type == "extend" &&
                  <>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label className="form-label">{t('startDate')}</label>
                          <p className="form-view-text">
                            {/* {this.props.extendData.startDate} */}
                            {`${convertDateFromTimezone(this.props.extendData.startDate)}`}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <label className="form-label">{t('endDate')}</label>
                          <p className="form-view-text">
                            {/* {this.props.extendData.endDate} */}
                            {`${convertDateFromTimezone(this.props.extendData.endDate)}`}

                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <label className="form-label">{t('timeDuration')}</label>
                          <p className="form-view-text">
                            {`${this.props.extendData.timeDuration} Hr`}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <div className="form-group">
                      <label className="form-label">
                        {t('endDate')} & {t('time')}
                      </label>
                      <div className="calender-div">
                        <DatePicker
                          selected={this.state.selectedEndDate}
                          minDate={this.state.maxDatepickerDate}
                          showTimeSelect
                          timeFormat="HH:mm"
                          dateFormat="dd/MM/yyyy HH:mm"
                          onChange={(jsDate) =>
                            this._handleChange(
                              jsDate,
                              "end_date"
                            )
                          }
                        />
                        <Calendar className="calender-icon" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label">{t('extendedTimeDuration')}</label>
                      <p className="form-view-text">
                        {`${this.state.extendedTimeDuration || 0} Hr`}
                      </p>
                    </div>
                  </>
                }
                <div className="form-group">
                  <div className="rd-box-head">
                    <h3>
                      {`${this.state.dropDownTitle} ${t('remark')}`}
                      <span className="inputErrorMsg">&nbsp;*</span>
                    </h3>
                  </div>
                  <input
                    className="form-control"
                    onChange={(event) =>
                      this._handleChange(event.target.value, "closing_remark")
                    }
                    value={this.state.closingRemarkText}
                  // placeholder={this._dynamicObservationPlaceHolder()}
                  ></input>
                  {!!this.state.closingRemarkError && (
                    <p className="inputErrorMsg">{requiredMessage}</p>
                  )}
                </div>
                {/* <div className="form-group"> */}
                {/* <div className="rd-box-head">
                    <h3>
                      Closing Action
                      <span className="inputErrorMsg">&nbsp;*</span>
                    </h3>
                  </div> */}
                <CommonDropdown
                  title={`${this.state.dropDownTitle} ${t('reason')}`}
                  optionList={this.state.reasonList}
                  onChangeHandle={this._closingActionChangeHandle}
                  isRequired={false}
                  value={this.state.closingActionId}
                />
                {!!this.state.closingActionError && (
                  <p className="inputErrorMsg">{requiredMessage}</p>
                )}
                {/* </div> */}
              </div>
            </div>
            <div className="modal-footer-btn">
              <button
                type="submit"
                className="red-btn"
                onClick={this.handleWorkPermitClose}
              >
                {`${this.state.dropDownTitle} Permit`}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

// export default withRouter(connect(mapStateToProps, null)(CloseModal));
// export default compose(
//   withRouter,
//   withTranslation(["workpermit","util"])
// )(CloseModal);

export default compose(
  withRouter,
  connect(mapStateToProps, null),
  withTranslation(["workpermit","util"])
)(CloseModal);
