/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { Col, Modal } from "react-bootstrap";
import { Redirect, withRouter } from "react-router-dom";
import { Trash, RefreshCw, Plus, X } from "react-feather";
import { Scrollbars } from "react-custom-scrollbars";
import "font-awesome/css/font-awesome.min.css";
import { ResolvePopup, InviteUser } from "app/shared/components/Popups";
import Url from "app/shared/constants/Url";
import axios from "axios";
import AudioPlayer from "react-h5-audio-player";
import FsLightbox from "fslightbox-react";
import AlertMessage from "app/shared/components/Popups/AlertMessage";
import { DeletePopup } from "app/shared/components/Popups";
import moment from "moment";

import URL from "app/shared/constants/Url";
import DatePicker from "react-datepicker";
import { RoundDelete } from "assets/Iconsv2/Iconsv2";
// ui kit components
import {
  Modal as SLDModal,
  Button as SLDButton,
} from "@salesforce/design-system-react";
import { Spinner } from "app/views/Ira/Detail";

import _ from "lodash";

import { searchInArrary } from "app/views/Form/SubmitFormListing/FormApprovalTableView";
import UaUcGraph from "app/shared/components/PredictiveAnalysis/UaUcGraph";

//components
import DetailTimelineSection from "./components/detailPage/timelineSection";
import DetailRightSection from "./components/detailPage/rightSection";
import DetailLeftSection from "./components/detailPage/leftSection";
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
const qs = require("qs");

const tabs = [
  {
    tabName: "Proposed CAPA",
    key: "prop",
  },
  {
    tabName: "Final CAPA",
    key: "final",
  },
];
export const getNextDays = (currentDate = new Date(), daysToAdd = 1) => {
  const nextDate = new Date(currentDate);
  nextDate.setDate(currentDate.getDate() + daysToAdd);
  return nextDate;
};
export const _momentDate = (stringDate) =>
  moment(stringDate, "DD-MM-YYYY").toDate();
class NMDetail extends Component {
  constructor(props) {
    super(props);
    const {t} = this.props;
    this.state = {
      isRecommendationEnabled: false,
      iAssignErrorEnabled: false,
      isCategoryErrorEnabled: false,
      isCorretiveActionErrorEnabled: false,
      isCAPAErrorEnabled: false,
      isDueDateErrorEnabled: false,
      isPriortiyErrorEnabled: false,
      isFreezeEnabled: false,
      isFreezeLoadingEnabled: false,
      show: false,
      userLists: [],
      nmCache: {},
      showUser: false,
      nmDetail: [],
      nmFormSetting: [],
      nmId: this.props.match.params.id,
      originFlag: this.props.match.params.origin,
      reportStatus: this.props.match.params.reportStatus,
      soiId: this.props.match.params.id,
      resolveFlag: this.props.match.params.flag,
      selectedTargetDate: null,
      selectedDate: null,
      factorParams: {},
      isFactorLoading: false,
      isCommentApiLoading: false,
      toggleAddQuestion: false,
      correctiveAction: false,
      isCommentApiLoading: false,
      toggleStep3: false,
      userData: JSON.parse(localStorage.getItem("userData")),
      toggleStep3edit: false,
      toggleStep2: false,
      step2Type: "none",
      currentTab: "prop",
      dataLoading: true,
      accordionOpen: true,
      lightboxactive: false,
      subcommentlist: [],
      isRecommendationEnable: false,
      comment: 0,
      isDataFilterEnable: false,
      isFilterLoading: false,
      isQuestionLoading: false,
      extremeSelect: 0,
      extremeValue: 0,
      deleteQuestionId: null,
      highSelect: 0,
      recommendedby: [],
      mediumSelect: 0,
      lowSelect: 0,
      all: 0,
      filterParams: {
        category: "",
        recommendation_by: "",
        responsible_person_id: "",
        startDate: "",
        endDate: "",
        page_no: 1,
      },
      commentAdd: "",
      commentlist: [],
      allComments: false,
      redirectToReferrer: false,
      toggler: false,
      teamLeadID: null,
      deletePopup: false,
      confirmPopup: false,
      afterTargetDatePopup: false,
      userLikedPopup: false,
      fsToggler: false,
      userLike: [],
      likeNumber: "",
      imageType: "evidence" || "evidence_resolve",
      fsToggler1: false,
      toggler: false,
      lightboxactive: false,
      evidencelightbox: [],
      evidencelightboxType: [],
      assignedDetails: {},
      showPopupSearch: false,
      obs_rec: "",
      deletePopupCAPA: false,
      ApproveTab: false,
      showContractor: false,
      showZone: false,
      showLocation: false,
      projectList: [],
      completioncount: [],
      capacount: [],
      opencount: [],
      closecount: [],
      gpcount: [],
      tbtcount: [],
      trainingInternalCount: [],
      trainingExternalCount: [],
      incidentPersonName: [],
      trainingEmployeeCount: [],
      trainingContractorCount: [],
      commentError: false,
      ContributingFactors: [],
      ImmediateFactors: [],
      RcaFactors: [],
      cflist: [],
      Iflist: [],
      rcalist: [],
      fcrdelID: null,
      questionList: [],
      Category_list: [],
      responsible_person: [],
      responsible_p: null,
      category_l: null,
      recommendation: "",
      CorrectiveActionText: "",
      priority: { extreme: false, high: false, medium: false, low: false },
      priority_l: 0,
      r_id: -1,
      editR_id: null,
      activity_id:null,
      activity_img:"",
      activity_img_name:"",
      activity_pdf:"",
      activity_pdf_name:"",
      activity_video:"",
      activity_video_name:"",
      FishData: {},
      capaType: "",
    };
    this.myRef = React.createRef();
    this.openResolve = this.openResolve.bind(this);
    this.openInviteUser = this.openInviteUser.bind(this);
  }

  componentDidMount() {
    this.nmDetail().then(() => {
      this.fetchActivityDetails();
  })
  .catch((error) => {
    console.error("Error ", error);
  });
    this.CommentList();
    this.UserWhoLiked();
    this.nmDetails()
    this.Dasycount();
    
  
   
  }
  // componentDidUpdate() {
  //   if (!this.state.dataLoading) {
  //     let el = document.getElementsByClassName("effectValue");

  //     if (el?.length) {

  //       el[0].outerHTML( `<div>${this.state.nmDetail?.activity_name} ${<OverlayTrigger
  //         trigger="hover"
  //         placement="right"
  //         rootClose={true}

  //         overlay={
  //           <Popover
  //             id="popover-positioned-right"
  //             style={{ width: "10rem", height: "3rem", borderRadius: "10px", textAlign: "center" }}
  //           >

  //             <div className="output-text font-16 font-weight-500 mt-2">
  //               Root Cause Analysis
  //             </div>
  //           </Popover>
  //         }
  //       >
  //         <Info className="cursor-p  d-block text-danger ml-1" style={{ height: "20px" }} />
  //       </OverlayTrigger>}</div>`)

  //       console.log(el);

  //     }
  //   }

  // }

  //Add question
  addQuestion = async () => {
    console.log("Sayan this.addQuestion");
    await this.setState((prevState) => {
      let { questionList } = prevState;
      questionList.push({
        question: "",
        response: "",
        isQuestionErrorEnabled: false,
        isDescriptionEnabled: false,
      });
      return { questionList };
    });
  };

  onChangeHandle = async (index, question) => {
    console.log("condition 1");
    await this.setState((prevState) => {
      let { questionList } = prevState;
      questionList[index].question = question;
      return { questionList };
    });
  };

  onChangeHandle2 = async (index, response) => {
    await this.setState((prevState) => {
      let { questionList } = prevState;
      questionList[index].response = response;
      return { questionList };
    });
  };

  async removeQuestion(index) {
    var tIndex = this.state.questionList.findIndex((item) => item == index);
    await this.setState((prevState) => {
      let { questionList } = prevState;
      questionList.splice(tIndex, 1);
      return { questionList };
    });
  }

  handleCheckboxChange = (event) => {
    const { factors_list } = this.state?.CFList;

    // Assuming the checkbox change event contains the ID of the item
    const factorId = event.target.id;

    const updatedFactorsList = factors_list.map((el) =>
      el?.factor_id == factorId ? { ...el, checked: !el?.checked } : el
    );
    console.log("updatedFactorsList", updatedFactorsList);
    this.setState((prevState) => ({
      CFList: {
        ...prevState.CFList,
        factors_list: updatedFactorsList,
      },
    }));
  };

  setFishData() {
    const RawFishdata = this.state.nmDetail.nm_reported_detail_RCA_factor_list;
    console.log("sayan RCA ", RawFishdata);
    const filtered = this.state.nmDetail.nm_reported_detail_RCA_factor_list.map(
      (el) => el.factor
    );

    const extractData = (RawFishdata) => {
      let modules = {};
      RawFishdata.forEach((el) => {
        modules = {
          ...modules,
          [el]: [],
        };
      });
      return modules;
    };
    var FishDataforGraph = {
      [this.state.nmDetail?.activity]: {
        ...extractData(filtered),
      },
    };

    console.log(FishDataforGraph);

    this.setState({ FishData: FishDataforGraph });
  }

  ApproveRemmondation = async (approve) => {
    const itemsLength =
      this.state.nmDetail.nm_reported_detail_proposed_recommendation_list
        ?.length;
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");
    const near_miss_id = this.state.soiId;
    const project_d = this.state.nmDetail.project_id;
    const service_d = this.state.nmDetail.service_id;
    const category_id = this.state.category_l;
    const recommended_id = approve;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_d,
      project_id: project_d,
      nearmiss_id: near_miss_id,
      recommendation_id: recommended_id,
    };
    // console.warn(data)

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/listnm/praposedrecommendationApproved", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({ message: responseJson.msg });
          // console.warn(this.state.message)
          // this._ProposedRecommend();

          this.hideModal();
          if (itemsLength === 1) {
            this.setState({ currentTab: "final" });
          }
          this.nmDetail();
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  _editRecommendation = async (id) => {
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const near_miss_id = this.state.soiId;

    const project_d = this.state.nmDetail.project_id;
    const service_d = this.state.nmDetail.service_id;
    const category_id = this.state.category_l;
    const recommendation = this.state.CorrectiveActionText;
    const target_date = moment(this.state.targetDate).format("YYYY-MM-DD");
    const priority = this.state.priority_l;
    const person_id = this.state.responsible_p;
    const recommendedId = id;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_d,
      project_id: project_d,
      nearmiss_id: near_miss_id,
      category_id: category_id,
      recommendation: recommendation,
      recommendation_id: recommendedId,
      target_date: target_date,
      priority: priority,
      responsible_person_id: person_id,
    };

    fetch(URL.clientURL + "/listnm/editrecommendation", {
      method: "post",
      body: qs.stringify(data, { arrayFormat: "repeat" }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.nmDetail();
          console.log("sayan 200");
        }
      })
      .catch((error) => {
        // throw error;
        // ToastMessage(error);
        console.log("error");
      });
  };

  DeleteRemmondation = async (r_id) => {
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const near_miss_id = this.state.soiId;

    const recommended_id = r_id;

    let data = {
      auth_key: token,
      user_id: userId,
      recommendation_id: recommended_id,
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/listnm/deleterecommendation", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          // this.finalFreezeDetails();
          console.log("sayan 200");
          this.hideModal();
          this.nmDetail();
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  handleCheckboxChangeIF = (event) => {
    const { factors_list } = this.state?.IFList;

    // Assuming the checkbox change event contains the ID of the item
    const factorId = event.target.id;

    const updatedFactorsList = factors_list.map((el) =>
      el?.factor_id == factorId ? { ...el, checked: !el?.checked } : el
    );
    console.log("updatedFactorsList", updatedFactorsList);
    this.setState((prevState) => ({
      IFList: {
        ...prevState.IFList,
        factors_list: updatedFactorsList,
      },
    }));
  };

  handleCheckboxChangeRAC = (event) => {
    const { factors_list } = this.state?.RCAFList;

    // Assuming the checkbox change event contains the ID of the item
    const factorId = event.target.id;

    const updatedFactorsList = factors_list.map((el) =>
      el?.factor_id == factorId ? { ...el, checked: !el?.checked } : el
    );
    console.log("updatedFactorsList", updatedFactorsList);
    this.setState((prevState) => ({
      RCAFList: {
        ...prevState.RCAFList,
        factors_list: updatedFactorsList,
      },
    }));
  };

  onPriorityChange(num) {
    if (num == 0) {
      this.setState({
        priority: { extreme: true, high: false, medium: false, low: false },
      });
      this.setState({ priority_l: 4 });
    }
    if (num == 1) {
      this.setState({
        priority: { extreme: false, high: true, medium: false, low: false },
      });
      this.setState({ priority_l: 3 });
    }
    if (num == 2) {
      this.setState({
        priority: { extreme: false, high: false, medium: true, low: false },
      });
      this.setState({ priority_l: 2 });
    }
    if (num == 3) {
      this.setState({
        priority: { extreme: false, high: false, medium: false, low: true },
      });
      this.setState({ priority_l: 1 });
    }
  }

  _AddRecommendation = async () => {
    if (this.manageCapaValidation()) {
      return false;
    }
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");
    this.setState({ isRecommendationEnabled: true });
    const near_miss_id = this.state.soiId;

    const project_d = this.state.nmDetail.project_id;
    const service_d = this.state.nmDetail.service_id;
    const category_id = this.state.category_l;
    const recommendation = this.state.CorrectiveActionText;
    const target_date = moment(this.state.selectedDate).format("YYYY-MM-DD");
    const priority = this.state.priority_l;
    const person_id = this.state.responsible_p;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_d,
      project_id: project_d,
      nearmiss_id: near_miss_id,
      category_id: category_id,
      recommendation: recommendation,
      target_date: target_date,
      capa_type: this.state.capaType,
      priority: priority,
      responsible_person_id: person_id,
    };
    console.log(data);

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    // Object.entries(data).forEach((el) => {
    //   formBody.push(el[0], el[1])
    // })

    fetch(URL.clientURL + "/listnm/addrecommendation", {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({ recommendations: responseJson.data });
          this.setState({ isRecommendationEnabled: false });
          console.log("sayan 200 recommendations");
          this.manageReset();
          this.hideModal();
          this.cancleSubmitTargetTime();
          this.nmDetail();
        } else {
          console.log("sayan non 200");
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  _EditRecommendation = async () => {
    if (this.manageCapaValidation(true)) {
      return false;
    }
    this.setState({ isRecommendationEnabled: true });
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const near_miss_id = this.state.soiId;

    const project_d = this.state.nmDetail.project_id;
    const service_d = this.state.nmDetail.service_id;
    const category_id = this.state.category_l;
    const recommendation = this.state.CorrectiveActionText;
    const target_date = moment(this.state.selectedDate).format("YYYY-MM-DD");
    const priority = this.state.priority_l;
    const person_id = this.state.responsible_p;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_d,
      project_id: project_d,
      nearmiss_id: near_miss_id,
      category_id: category_id,
      recommendation: recommendation,
      target_date: target_date,
      priority: priority,
      responsible_person_id: person_id,
      recommendation_id: this.state.editR_id,
      capa_type: this.state.capaType,
    };
    console.log(data);

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    // Object.entries(data).forEach((el) => {
    //   formBody.push(el[0], el[1])
    // })

    fetch(URL.clientURL + "/listnm/editrecommendation", {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({ recommendations: responseJson.data });
          this.setState({ isRecommendationEnabled: false });
          console.log("sayan 200 recommendations");
          this.manageReset();
          this.hideModal();
          this.cancleSubmitTargetTime();
          this.nmDetail();
        } else {
          console.log("sayan non 200");
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  manageEdit = (id, arr = []) => {
    const user = arr?.filter((el) => el.recommendation_id === id)[0] ?? {};
    this.setState({
      category_l: user.category_id?.toString(),
      CorrectiveActionText:
        user?.recommendation?.map((el) => el?.recommendation) ?? "",
      responsible_p: user?.responsible_person_list[0]?.person_id?.toString(),
      selectedDate: _momentDate(user?.target_date),
      editR_id: id,
      priority: {
        extreme: user?.priority === "Critical",
        high: user?.priority === "Urgent",
        medium: user?.priority === "Important",
        low: user?.priority === "Normal",
      },
      capaType: user.recommendation[0]?.capaType,
    });
    this.toggleStep3Tab();
  };

  manageReset = () => {
    this.setState({
      category_l: null,
      CorrectiveActionText: "",
      responsible_p: null,
      selectedDate: null,
      capaType: "",
      editR_id: null,
      priority: { extreme: false, high: false, medium: false, low: false },
    });
  };
  _removefinalcr = async (fcaId) => {
    const itemsLength =
      this.state.nmDetail.nm_reported_detail_final_recommendation_list?.length;
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");
    const near_miss_id = this.state.soiId;

    const project_d = this.state.nmDetail.project_id;
    const service_d = this.state.nmDetail.service_id;

    const data = {
      auth_key: token,
      user_id: userId,
      service_id: service_d,
      project_id: project_d,
      nearmiss_id: near_miss_id,
      recommendation_id: fcaId,
    };
    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(URL.clientURL + "/listnm/finalrecommendationdelete", {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then(() => {
        this.setState({
          fcrdelID: null,
        });
        if (itemsLength === 1) {
          this.setState({ currentTab: "prop" });
        }
        this.nmDetail();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  _AddParameter = async () => {
    // console.log("ADD Parameter fired")
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const near_miss_id = this.state.soiId;

    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      nearmiss_id: near_miss_id,
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/listnm/addparameterlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          console.log("sayan 200");
          if (responseJson.data) {
            this.setState({
              Category_list: responseJson.data.category_list || [],
              responsible_person: responseJson.data.responsible_person || [],
              dataLoading: false,
            });
          } else {
            this.setState({ dataLoading: false });
          }
        }
      })
      .catch((error) => {
        throw error;
      });
    console.log(
      "1. category: ",
      this.state.Category_list,
      " 2. responsible: ",
      this.state.responsible_person
    );
  };

  nmDetail = async () => {
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");
    const clientUrl = localStorage.getItem("clientUrl");
    const near_miss_id = this.state.soiId;

    let details = {
      auth_key: token,
      user_id: userId,
      nearmiss_id: near_miss_id,
    };
    let formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/listnm/nearmissdetail", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          console.log("Tushar");
          console.log(responseJson);

          try {
            const {
              nm_reported_detail_people_involved_list,
              
              nm_reported_detail_proposed_recommendation_list,
              nm_reported_detail_final_recommendation_list,
              nm_reported_detail_contrubuting_factor_list,
              nm_reported_detail_immediate_factor_list,
              nm_reported_detail_RCA_factor_list,
              target_date,
              nm_reported_detail_incidence_analysis_question,
            } = responseJson.data;
            if (responseJson.data.reported_by_id) {
              this.fetchReportingPerson(responseJson.data.reported_by_id);
            }
            console.log(responseJson,"respjson")

            await this.setState(
              {
                nmCache: responseJson.data,
                activity_id:responseJson.data.activity_id,
                nmDetail: responseJson.data,
                nm_reported_detail_people_involved_list:
                  nm_reported_detail_people_involved_list,
                proposedRecommendation:
                  nm_reported_detail_proposed_recommendation_list,
                finalRecommendation:
                  nm_reported_detail_final_recommendation_list,
                ContributingFactors:
                  nm_reported_detail_contrubuting_factor_list,
                ImmediateFactors: nm_reported_detail_immediate_factor_list,
                RcaFactors: nm_reported_detail_RCA_factor_list,
                incidentDateforView: target_date,
                iaq: nm_reported_detail_incidence_analysis_question,
                dataLoading: false,
                form_setting: responseJson.form_setting?.setting || [],
                teamsl: nm_reported_detail_people_involved_list.map(
                  (item) => item.status
                ),
                teamMember: responseJson.team_member,
              },
              () => {
                const involvedPersonsList =
                  this.state?.nmDetail?.incident_person_details?.map(
                    (el) => el.searched_incident_person_id
                  );
                if (involvedPersonsList.length) {
                  this.fetchUsersList(involvedPersonsList);
                }
              }
            );
            this.setFishData();
          } catch (error) {
            console.log(error);
          }
        }
      });
    console.log("sayan " + this.state.nmDetail.project_id);
  };

  fetchActivityDetails=async()=>{
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    

    await axios
    .post(
      Url.activityDetail,
      qs.stringify({
        auth_key: token,
        user_id: userId,
        activity_id: this.state.activity_id,
        
      }),
     
      
    )
    .then(async(response) => {
      if (response.data.status == 200) {
        console.log("API Response:", response.data);
        
        
        await this.setState({
          activityData: response.data.data.activity_list,
          activity_img:response.data?.data.activity_list?.[0]?.file_img,
          activity_img_name:response.data?.data?.activity_list?.[0]?.file_img_name,
          activity_pdf:response.data?.data?.activity_list[0]?.file_pdf,
          activity_pdf_name:response.data?.data?.activity_list?.[0]?.file_pdf_name,
          activity_video:response.data?.data?.activity_list?.[0]?.file_video,
          
          
        });
      }
    })
    .catch((error) => { console.log(error)});
    console.log(this.state.activity_id,this.state.activity_img,this.state.activitydata,"checlcheck")
    
  }

  handleContrubutingSubmit = () => {};

  showUserLikedPopup = () => {
    this.setState({
      userLikedPopup: true,
    });
  };

  handleClose = () => {
    this.setState({
      userLikedPopup: false,
    });
  };

  openResolve() {
    const show = !this.state.show;
    this.setState({
      show,
    });
  }

  openInviteUser() {
    const showUser = !this.state.showUser;
    this.setState({
      showUser,
    });
  }

  toggleCorrectiveAction() {
    console.log("fired");
    console.log(this.state.correctiveAction);
    if (this.state.correctiveAction) {
      this.setState({ correctiveAction: false });
    } else {
      this.setState({ correctiveAction: true });
    }
  }

  toggleAddQuestionTab() {
    if (this.state.toggleAddQuestion) {
      this.setState({ toggleAddQuestion: false });
      this.setState({ step2Type: "none" });
    } else {
      this.setState({ toggleAddQuestion: true });
    }
  }

  toggleStep3Tab() {
    if (this.state.toggleStep3) {
      this.manageReset();
      this.setState({ toggleStep3: false });
      this.setState({ correctiveAction: false });
    } else {
      this.setState({ toggleStep3: true });
      this.setState({ correctiveAction: true });
    }
  }

  toggleStep3EditTab(id) {
    if (this.state.toggleStep3edit) {
      this.setState({ toggleStep3edit: false });
      this.setState({ editR_id: null });
    } else {
      this.setState({ toggleStep3edit: true });
      this.setState({ editR_id: id });
    }
  }

  toggleApproveTabCAPA(id) {
    if (this.state.ApproveTab) {
      this.setState({ ApproveTab: false, r_id: -1 });
    } else {
      this.setState({ ApproveTab: true, r_id: id });
    }
  }

  toggleDeletePopupCAPA(id) {
    if (this.state.deletePopupCAPA) {
      this.setState({ deletePopupCAPA: false, r_id: -1 });
    } else {
      this.setState({ deletePopupCAPA: true, r_id: id });
    }
  }

  toggleStep2Fun(type = "none") {
    if (this.state.toggleStep2) {
      this.setState({ toggleStep2: false });
    } else {
      this.setState({ toggleStep2: true });
    }

    if (type == "immediate") {
      this.state.step2Type = "Immediate Factor";
    } else if (type == "root cause") {
      this.state.step2Type = "Root Cause Analysis";
    } else if (type == "contributing") {
      this.state.step2Type = "Contributing Factor";
    } else {
      this.state.step2Type = "none";
    }
  }

  _InProgressFactorAddList = async (factorId) => {
    const { nmDetail, soiId, CFList, IFList, RCAFList } = this.state;
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");
    this.setState({ isFactorLoading: true });
    const project_id = nmDetail.project_id;
    const service_id = nmDetail.service_id;
    const near_miss_id = soiId;

    // Helper function to get the factor list based on factorId
    const getFactorList = (factorList) => {
      return factorList?.factors_list?.filter(el => el?.checked)?.map(el => el?.factor_id) || [];
    };

    // Determine factor list based on factorId
    const factorListMap = {
      1: CFList,
      2: IFList,
      3: RCAFList,
    };
    const selectedFactorList = getFactorList(factorListMap[factorId]);
    // const custom_cf = this.state.customList.map(item => item.customfactor);
    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      module_unique_id: near_miss_id,
      factor_id: factorId,
      sub_factor_id: selectedFactorList,
      module_name: "near_miss",
      custom_cf: [],
    };
    // await this.setModalVisible(false);
    
    fetch(URL.clientURL + "/rootcause/addfactors", {
      method: "post",
      body: qs.stringify(data, { arrayFormat: "repeat" }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          this.resetFactorsState(this.toggleStep2Fun);
          this.nmDetail();

          this.nmDetails();
        } else {
        }
      })
      .catch((error) => {
        console.log("sayan err");
      })
      .finally(() => {
        this.setState({ isFactorLoading: false });
      });
  };

  _InProgressContributingList = async () => {
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const near_miss_id = this.state.soiId;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      module_unique_id: near_miss_id,
      module_name: "near_miss",
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/rootcause/contributingfactorlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          await this.setState({ CFList: responseJson.data });
          // await this.setState((prevState) => {
          //   let CFList = { ...prevState.CFList };
          //   CFList.factors_list = CFList.factors_list.map((item) => ({
          //     ...item,
          //     isChecked: false,
          //   }));
          //   return { CFList };
          // });
        }
      });
  };

  resetFactorsState = (callback = {}) => {
    this.setState(
      {
        cflist: [],
        Iflist: [],
        rcalist: [],
      },
      callback
    );
  };
  _InProgressImmediateList = async () => {
    console.log("sayan fired");
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const near_miss_id = this.state.soiId;
    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      module_unique_id: near_miss_id,
      module_name: "near_miss",
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/rootcause/immediatefactorlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({ IFList: responseJson.data });
          // this.setState((prevState) => {
          //   let IFList = { ...prevState.IFList };
          //   IFList.factors_list = IFList.factors_list.map((item) => ({
          //     ...item,
          //     isChecked: false,
          //   }));
          //   return { IFList };
          // });
        }
      });
  };

  _InProgressRcaList = async () => {
    // console.log("sayan fired")
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const near_miss_id = this.state.soiId;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      module_unique_id: near_miss_id,
      module_name: "near_miss",
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/rootcause/rcafactorlist", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.setState({ RCAFList: responseJson.data });
          // this.setState((prevState) => {
          //   let RCAFList = { ...prevState.RCAFList };
          //   RCAFList.factors_list = RCAFList.factors_list.map((item) => ({
          //     ...item,
          //     isChecked: false,
          //   }));
          //   return { RCAFList };
          // });
        }
      });
  };

  validateQuestions = (list = []) => {
    const invalid = [];

    const temp = list.map((el) => {
      if (el?.question === "" || el?.response === "") {
        invalid.push(el);
        return {
          ...el,
          isQuestionErrorEnabled: el?.question === "",
          isDescriptionEnabled: el?.response === "",
        };
      } else {
        return {
          ...el,
          isQuestionErrorEnabled: false,
          isDescriptionEnabled: false,
        };
      }
    });
    if (invalid.length > 0) {
      this.setState({ questionList: temp });
    }
    return invalid.length > 0 ? true : false;
  };
  // add incidence analysis question api
  analysisQuestionAdd = async () => {
    const isFormInvalid = this.validateQuestions(this.state.questionList);
    if (isFormInvalid && this.state.questionList.length > 0) {
      return false;
    }
    this.setState({
      isQuestionLoading: true,
    });
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const near_miss_id = this.state.soiId;

    const question = this.state.questionList.map((item) => item.question);
    const response = this.state.questionList.map((item) => item.response);

    let databody = new FormData();
    databody.append("auth_key", token);
    databody.append("user_id", userId);
    databody.append("service_id", service_id);
    databody.append("project_id", project_id);
    databody.append("near_miss_id", near_miss_id);
    question.map((item) => item !== "" && databody.append("question", item));
    response.map(
      (item) => item !== undefined && databody.append("response", item)
    );

    fetch(URL.clientURL + "/listnm/nmaddquestion", {
      method: "post",
      body: databody,
      // headers: {
      //   'Content-Type': 'multipart/form-data',
      // },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          this.hideModal();
          this.nmDetail();
          this.nmDetails();

          AlertMessage({
            message: this.props.t('questionsAddedSuccessfully'),
            type: "success",
          });
          this.setState({
            questionList: [],
          });
        } else {
          AlertMessage({
            message: this.props.t('errorInAddingTryAgain'),
            type: "error",
          });
        }
      })
      .catch((error) => {
        console.log("error");
        AlertMessage({
          message: this.props.t('errorInAddingTryAgain'),
          type: "error",
        });
      })
      .finally((done) =>
        this.setState({
          isQuestionLoading: false,
        })
      );
  };
  analysisQuestionDelete = async (questionId) => {
    console.log("sayan fired");
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const near_miss_id = this.state.soiId;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      near_miss_id: near_miss_id,
      question_id: questionId,
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/listnm/nmdeletequestion", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          console.log("sayan 200");
          AlertMessage({
            message: this.props.t('questionsDeletedSuccessfully'),
            type: "success",
          });
          this.nmDetail();
          this.nmDetails();
          this.setState({ deleteQuestionId: null });
        } else {
          AlertMessage({
            message: this.props.t('errorInDeletingTryAgain'),
            type: "error",
          });
        }
      })
      .catch((error) => {
        console.log("error");
        AlertMessage({
          message: this.props.t('errorInDeletingTryAgain'),
          type: "error",
        });
      });
  };

  submitTargetDate() {
    var targetDate = this.state.selectedTargetDate;
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");
    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const near_miss_id = this.state.soiId;
    let data = {
      auth_key: token,
      user_id: userId,
      nearmiss_id: near_miss_id,
      target_date: moment(targetDate).format("YYYY-MM-DD"),
      service_id: service_id,
      project_id: project_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/listnm/nmtargetdateupdate", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          AlertMessage({
            message: this.props.t('targetDateUpdatedSuccessfully'),
            type: "success",
          });
          this.setState({
            afterTargetDatePopup: this.state.reportStatus === "pending",
            teamLeadID: null,
            confirmPopup: false,
          });
        } else {
          AlertMessage({ message: this.props.t('unableToSetTargetDate'), type: "error" });
        }
      });
  }

  confirmSubmitTargetDate() {
    this.setState({ confirmPopup: true });
  }

  cancleSubmitTargetTime() {
    this.setState({ selectedTargetDate: null });
    this.setState({ selectedDate: null });
    // console.log("heheh")
  }

  _handleChange = async (item = null, type = "observation") => {
    if (item != null && type == "target_date") {
      this.setState({ selectedTargetDate: item });
    }

    if (item != null && type == "step3Date") {
      this.setState({ selectedDate: item });
    }
  };

  _AddTeamLeader = async (user_id) => {
    this.setState({
      dataLoading: true,
    });
    console.log(user_id);
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const near_miss_id = this.state.soiId;
    const team_member_id = user_id;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      nearmiss_id: near_miss_id,
      team_member_id: team_member_id,
    };
    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/listnm/createteamleader", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        //window.location.reload(false);
        this.setState({ teamLeadID: user_id, dataLoading: false });
        if (Math.max.apply(null, this.state.teamsl) != 1) {
        }
        if (responseJson.status == 200) {
          await this.setState((prevState) => {
            let { nm_reported_detail_people_involved_list } = prevState;
            nm_reported_detail_people_involved_list =
              nm_reported_detail_people_involved_list.filter(
                (team) => team.user_id !== user_id
              );
            return { nm_reported_detail_people_involved_list };
          });

          this.nmDetail();
        }
      });
  };

  removeInviteUser = async (selectedUserId) => {
    console.log(selectedUserId);
    let token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    // await axios
    //   .post(
    //     URL.clientURL+"listnm/removeteammembers",
    //     qs.stringify({
    //       auth_key: token,
    //       user_id: userId,
    //       project_id: this.state.nmDetail.project_id,
    //       service_id: this.state.nmDetail.service_id,
    //       nearmiss_id: this.state.soiId,
    //       team_member_id: selectedUserId,
    //     }),
    //     {
    //       headers: {
    //         "Content-Type": "application/x-www-form-urlencoded",
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     console.log("sayan responce")
    //     if (response.data.status == 200) {
    //       console.log("sayan 200")
    //       AlertMessage({
    //         message: "User removed successfully",
    //         type: "success",
    //       });
    //       this.nmDetail();
    //     } else {
    //       console.log("sayan error")
    //       AlertMessage({
    //         message: "User not removed. Please try again.",
    //         type: "error",
    //       });
    //     }
    //   })
    //   .catch((error) => {

    //     console.log("error")

    //   });

    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const near_miss_id = this.state.soiId;

    let data = {
      auth_key: token,
      user_id: userId,
      service_id: service_id,
      project_id: project_id,
      nearmiss_id: near_miss_id,
      team_member_id: selectedUserId,
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(URL.clientURL + "/listnm/removeteammembers", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          // this.nmDetail();
          await this.setState((prevState) => {
            let { nm_reported_detail_people_involved_list } = prevState;
            nm_reported_detail_people_involved_list =
              nm_reported_detail_people_involved_list.filter(
                (team) => team.user_id !== selectedUserId
              );
            return { nm_reported_detail_people_involved_list };
          });
          window.location.reload(false);
        }
      })
      .catch((error) => {
        console.log("sayan err");
      });
  };

  _fetchProjectListFlag = async () => {
    const { reportType } = this.state;
    let moduleId = 2;
    // if (reportType == "soi") {
    //   moduleId = 1;
    // } else if (reportType == "nm") {
    //   moduleId = 2;
    // } else if (reportType == "ira") {
    //   moduleId = 3;
    // }

    let requestData = {
      service_id: this.state.nmDetail.service_id,
      module_name: "near_miss",
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.projectListForm,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { showContractor, showZone, showLocation, projectList } =
                prevState;

              projectList = response.data.data;

              // check contractor flag
              const isContractor = projectList.map(
                (item) => item.contractor_flag
              );
              showContractor = isContractor == 0 ? false : true;
              console.log("isContractor == " + isContractor);

              const isZone = projectList.map((item) => item.zone_location_flag);
              showZone = isZone == 0 ? false : true;
              console.log("isZone == " + isZone);

              const isLocation = projectList.map(
                (item) => item.zone_location_flag
              );
              showLocation = isLocation == 0 ? false : true;
              console.log("isLocation == " + isLocation);

              return {
                showContractor,
                showZone,
                showLocation,
                projectList,
              };
            });
          }
        })
        .catch((error) => {
          this.setState({
            dataLoading: false,
          });
        });
    });
  };

  CommentList = async (limitComment = 99999) => {
    this.setState({ isCommentApiLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.getCommentsList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.nmId,
          report_type: "nm",
          limit: limitComment,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            commentlist: response.data.data,
            total_number: response.data.total_number,
            comment: 0,
          });
        }
      })
      .catch((error) => {})
      .finally(() => this.setState({ isCommentApiLoading: false }));
  };

  subCommentList = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    this.setState({ isCommentApiLoading: true });
    await axios
      .post(
        Url.smDashboard.dashboarddetailcommentsubcommentlist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.nmId,
          comment_id: this.state.commentId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            subcommentlist: response.data.data,
            comment: 1,
            isCommentApiLoading: false,
          });
        }
      })
      .catch((error) => {})
      .finally(() => this.setState({ isCommentApiLoading: false }));
  };

  _formValidation = async () => {
    const tempObj = this.state;

    await this.setState((prevState) => {
      prevState.commentError = tempObj.commentAdd ? false : true;

      return prevState;
    });
  };

  CommentAdd = async () => {
    await this._formValidation();

    let formErrorStatus;
    for (var propertyName in this.state) {
      if (propertyName.includes("Error")) {
        if (this.state[propertyName]) {
          console.log(propertyName);
          console.log(this.state[propertyName]);
          formErrorStatus = true;
          console.log("erroe");
          return;
        } else {
          formErrorStatus = false;
        }
        console.log(formErrorStatus);
      }
    }

    if (!formErrorStatus) {
      this.setState({ isCommentApiLoading: true });
      let userId = localStorage.getItem("userId");
      let token = localStorage.getItem("token");

      await axios
        .post(
          Url.smDashboard.addMainComment,
          qs.stringify({
            auth_key: token,
            user_id: userId,
            id: this.state.nmId,
            module: "nm",
            comment: this.state.commentAdd,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.CommentList();
            this.setState({ commentAdd: "", isCommentApiLoading: false });
            AlertMessage({
              message: this.props.t('commentAddedSuccessfully'),
              type: "success",
            });
          } else {
            AlertMessage({
              message: this.props.t('commentAddFailedTryAgain'),
              type: "error",
            });
          }
        })
        .catch((error) => {});
    }
  };

  ReplyCommentAdd = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    this.setState({ isCommentApiLoading: true });
    await axios
      .post(
        Url.smDashboard.replyToComment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          reply_status: this.state.comment,
          comment_reply_id: 0,
          main_comment_id: this.state.commentId,
          comment: this.state.replycommentAdd,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.subCommentList();
          this.setState({ replycommentAdd: "", isCommentApiLoading: false });
          AlertMessage({
            message: this.props.t('replyAddedSuccessfully'),
            type: "success",
          });
        } else {
          AlertMessage({
            message: this.props.t('replyAddFailedTryAgain'),
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  LikeCommentAdd = async (Cid, ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.addLikeToComment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          comment_id: Cid,
          comment_type: ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: this.props.t('commentLikedSuccessfully'),
            type: "success",
          });
        } else {
          AlertMessage({
            message: this.props.t('commentLikeFailedTryAgain'),
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  LikeCommentRemove = async (Cid, Ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboardcontentcommentremoveunlike,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          comment_id: Cid,
          comment_type: Ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: this.props.t('commentLikeFailedTryAgain'),
            type: "success",
          });
        } else {
          AlertMessage({
            message: this.props.t('likeRemoveFailedTryAgain'),
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  CommentRemove = async (Cid, ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    var nmDetail = this.state.nmDetail;

    await axios
      .post(
        Url.soiDashboard.soideletecomment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: nmDetail.service_id,
          project_id: nmDetail.project_id,
          soi_id: this.state.soiId,
          comment_id: Cid,
          comment_type: ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: this.props.t('commentDeletedSuccessfully'),
            type: "success",
          });
        } else {
          AlertMessage({
            message: this.props.t('commentDeletedFailedTryAgain'),
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  deleteReport = async (nmId) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.nmModule.deleteNMReport,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          near_miss_id: nmId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          AlertMessage({
            message: this.props.t('nmDeletedSuccessfully'),
            type: "success",
          });
          setTimeout(() => {
            this.setState({ redirectToReferrer: true });
          }, 3000);
        } else {
          AlertMessage({
            message: this.props.t('nmDeleteFailedTryAgain'),
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  UserWhoLiked = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboarddetaillikelist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.nmId,
          report_type: "nm",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            userLike: response.data.data,
            likeNumber: response.data.like_number,
          });
        }
      })
      .catch((error) => {});
  };

  //dayscount
  Dasycount = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.nmModule.nmDaysCount,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          nearmiss_id: this.state.nmId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            completioncount: response.data.data.capa_completion_days,
            capacount: response.data.data.report_completion_days,
            opencount: response.data.data.recommendation_open_count,
            closecount: response.data.data.recommendation_close_count,
            gpcount: response.data.data.gp_count?.length
              ? response.data.data.gp_count
              : [{ gp_count: 0 }],
            tbtcount: response.data.data.tbt_count?.length
              ? response.data.data.tbt_count
              : [{ tbt_count: 0 }],
            trainingInternalCount: response.data.data.training_internal_count
              ?.length
              ? response.data.data.training_internal_count
              : [{ training_internal_count: 0 }],
            trainingExternalCount: response.data.data.training_external_count
              ?.length
              ? response.data.data.training_external_count
              : [{ training_external_count: 0 }],
            trainingEmployeeCount: response.data.data.training_employee_count
              ?.length
              ? response.data.data.training_employee_count
              : [{ training_employee_count: 0 }],
            trainingContractorCount: response.data.data
              .training_contractor_count?.length
              ? response.data.data.training_contractor_count
              : [{ training_contractor_count: 0 }],
          });
        }
      })
      .catch((error) => {});
  };

  _addUsersToList = async (newUsers) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const reportId = this.state.soiId;
    // console.log(newUsers);
    var selectedUserIds = [];
    newUsers.map((item, key) => selectedUserIds.push(item.team_member_id));

    var data = {
      auth_key: token,
      user_id: userId,
      service_id: this.state.nmDetail.service_id,
      project_id: this.state.nmDetail.project_id,
      nearmiss_id: this.state.soiId,
      // selected_user_id: selectedUserIds,
      team_members: selectedUserIds,
      status: 0,
    };
    // console.log(""+token+"  " + userId + " "+ this.state.nmDetail.project_id + " "+ this.state.soiId + " ")

    // var formBody = [];
    // for (var property in data) {
    //   var encodedKey = encodeURIComponent(property);
    //   var encodedValue = encodeURIComponent(data[property]);
    //   formBody.push(encodedKey + '=' + encodedValue);
    //   if (property == "selected_user_id") {
    //     data[property].map((item, key) =>
    //       formBody.push(encodedKey + "=" + item)
    //     );
    //   } else {
    //     formBody.push(encodedKey + "=" + encodedValue);
    //   }
    // }
    // formBody = formBody.join("&");

    // await axios
    //   .post("https://dev-api.clideoffice.com/listnm/addteammembers", formBody, {
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded",
    //     },
    //   })

    fetch(URL.clientURL + "/listnm/addteammembers", {
      method: "post",
      body: qs.stringify(data, { arrayFormat: "repeat" }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
      .then((response) => {
        if (response.data.status == 200) {
          AlertMessage({ message: this.props.t('userAddedSuccessfully'), type: "success" });
          console.log("done cheee ");
          this.nmDetail();
        } else {
          console.log(" not done cheee ");
          AlertMessage({
            message: this.props.t('userNotAddedTryAgain'),
            type: "error",
          });
        }
      })
      .catch((error) => {
        console.log("not not done cheee ");
        window.location.reload(false);
      });
  };

  _toggleDataLoading = async (flag) => {
    await this.setState({ dataLoading: flag });
  };

  nmDetails = () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let requestData = {
        nearmiss_id: this.state.nmId,
      };
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.nmModule.nmDetail,
        undefined,
        requestData
      );

      responseObj
        .then(async (response) => {
          try {
            if (response.data.status == 200) {
              await this.setState(
                (prevState) => {
                  const activityId = prevState.activity_id || response.data?.data?.[0].activity;
                  let {
                    dataLoading,
                    nmDetail,
                    nmFormSetting,
                    incidentPersonName,
                  } = prevState;
                  let { data, form_setting } = response.data;
                  dataLoading = false;
                  nmDetail = data;                  
                  nmFormSetting = form_setting?.setting;
                  incidentPersonName =
                    response.data.data.incident_person_details;

                  return {
                    ...prevState, // Preserve other state properties
                    activity_id: activityId,
                    dataLoading,
                    nmDetail,
                    nmFormSetting,
                    incidentPersonName,
                  };
                },
                () => {
                  this.fetchActivityDetails();
                  this._AddParameter();
                  this._InProgressContributingList();
                  this._InProgressImmediateList();
                  this._InProgressRcaList();
                },
                
              );
              this._fetchProjectListFlag();
            } else {
              this._toggleDataLoading(false);
            }
          } catch (error) {
            this._toggleDataLoading(false);
          }
        })
        .catch((error) => {
          this._toggleDataLoading(false);
        });
    });
  };

 

  cancelItem_onClick = () => {
    this.props.history.goBack();
    //window.history.back();
  };

  togglecommentId = async (comment_id) => {
    await this.setState({ commentId: comment_id });
    await this.subCommentList();
  };

  backToMainComment = async () => {
    await this.CommentList();
    await this.setState({ comment: 0, accordionOpen: true });
  };

  showModal = () => {
    this.setState({ deletePopup: true });
  };

  hideModal = () => {
    this.setState({ deletePopup: false });
    this.setState({ confirmPopup: false });
    this.setState({ afterTargetDatePopup: false });
    this.setState({ toggleAddQuestion: false });
    this.setState({ toggleStep3: false });
    this.setState({ toggleStep2: false });
    this.setState({ deletePopupCAPA: false });
    this.setState({ ApproveTab: false });
    this.setState({ toggleStep3edit: false });
  };

  _handleLightBox = async (list) => {
    console.log(list.map((item) => item.format));
    await this.setState({
      lightboxactive: true,
      fsToggler1: !this.state.fsToggler1,
      evidencelightbox: list.map((item) => item.evidence),
      evidencelightboxType: list.map((item) => "image"),
    });
  };

  modalHideSearch = () => {
    this.setState({
      showPopupSearch: false,
    });
  };
  modalShowSearch = (rec) => {
    this.setState({
      showPopupSearch: true,
      obs_rec: rec,
    });
  };

  comapareTargetDate = (date = "") => {
    if (date) {
      const targetDate = moment(date, "DD/MM/YYYY");
      const today = moment();
      if (targetDate.isSame(today, "day")) {
        return true;
      }
      return false;
    }
  };

  renderTimeLine = (reportData, state) => {
    if (state.dataLoading) {
      return [];
    }
    let details = [
      {
        title: <span className="font-14">{this.props.t('reportedDetails')}</span>,
        cardTitle: (
          <div className="d-flex  " style={{ width: "15rem" }}>
            <img
              src={reportData.reported_by_pic}
              className="clide-v2-profile-img"
              title={reportData.reported_by}
              alt="avatar"
            />
            <div className="d-flex flex-column text-left ml-3 w-100">
              <span className="common-grey-1 font-14">{this.props.t('reportedOn')}</span>
              <span className="common-black-1 font-14 font-weight-500 text-overflow-ellipsis">
                {`${reportData.reported_date} `} | {reportData?.reported_time}{" "}
              </span>
            </div>
          </div>
        ),
      },
      {
        title: <span className="font-14">{this.props.t('incidentDate')}</span>,
        cardTitle: (
          <div className="d-flex  " style={{ width: "15rem" }}>
            <img
              src={reportData.reported_by_pic}
              className="clide-v2-profile-img"
              title={reportData.reported_by}
              alt="avatar"
            />
            <div className="d-flex flex-column text-left ml-3 w-100">
              <span className="common-grey-1 font-14">{this.props.t('dateTime')}</span>
              <span className="common-black-1 font-14 font-weight-500 text-overflow-ellipsis">
                {`${reportData.incident_date} `} | {reportData?.incident_time}{" "}
              </span>
            </div>
          </div>
        ),
      },
    ];
    if (reportData.incident_person_details?.length) {
      details = [
        ...details,
        ...reportData.incident_person_details?.map((image, index) => {
          return {
            title: <span className="font-14">{this.props.t('injuredPersonDetails')}</span>,
            cardTitle: (
              <div className="d-flex   " style={{ width: "11rem" }}>
                <img
                  className="clide-v2-profile-img "
                  src={require("../../../../assets/sls-icons/V2. New Development/user.svg")}
                  alt=""
                  title={image.incident_person_name}
                />

                <div className="p-1 px-3  w-100 mob-pe">
                  {/* <div className="common-grey-1 font-14 ">{this.props.t('incidentPerson')}</div> */}
                  <div className="common-black-1 font-14 font-weight-500 text-overflow-ellipsis">
                    {" "}
                    {image.incident_person_name} |{" "}
                    {image.incident_person_id ?? index + 1}
                  </div>
                </div>
              </div>
            ),
          };
        }),
      ];
    }
    if (reportData.nm_reported_detail_people_involved_list?.length) {
      details = [
        ...details,
        ...reportData.nm_reported_detail_people_involved_list?.map(
          (image, index) => {
            return {
              title: <span className="font-14">{this.props.t('investigationTeam')}</span>,
              cardTitle: (
                <div className="d-flex   " style={{ width: "11rem" }}>
                  <img
                    className="clide-v2-profile-img "
                    src={image.user_pic}
                    alt=""
                    title={image.user_name}
                  />

                  <div className="d-flex flex-column text-left ml-3 w-100">
                    <div className="common-grey-1 font-14 ">
                      {this.props.t('team')}{" "}{image.status == 1 ? this.props.t('lead') : this.props.t('member')}
                    </div>
                    <div className="common-black-1 font-14 font-weight-500 text-overflow-ellipsis">
                      {" "}
                      {image.user_name}
                    </div>
                  </div>
                </div>
              ),
            };
          }
        ),
      ];
    }

    if (state.commentlist?.length) {
      const { comment, comment_time, user_name, user_pic } =
        state.commentlist[0] ?? {};
      details = [
        ...details,
        {
          title: <span className="font-14">{this.props.t('latestComment')}</span>,
          cardTitle: (
            <div
              className="d-flex  cursor-p "
              style={{ width: "11rem" }}
              onClick={() => {
                this.myRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
            >
              <img
                src={user_pic}
                className="clide-v2-profile-img"
                title={user_name}
                alt="avatar"
              />
              <div className="d-flex flex-column text-left ml-3 w-100">
                <div className="common-grey-1 font-14">{this.props.t('latestComment')}</div>
                <div className="common-black-1 font-14 font-weight-500 " title={comment}>
                  {" "}
                  {comment.substring(0, 20) + "..."}
                </div>
                <span className="font-12 common-grey-1">{comment_time}</span>
              </div>
            </div>
          ),
        },
      ];
    }
    return details;
  };
  __generateCarouselData = (items = []) => {
    const content = [];
    items.forEach((data) => {
      const format = data?.evidence_format ?? data.format;
      if (format !== undefined) {
        content.push({
          url: data.link ?? data.evidence_image,
          contentType:
            format != "text"
              ? format == "image" || format == "img"
                ? "image"
                : "video"
              : "video",
        });
      }
    });
    return content;
  };

  actionFreezeAll = () => {
    this.setState({
      isFreezeLoadingEnabled: true,
    });
    const baseUrl = URL.clientURL + "/listnm/finalrecommendationfreezeall";
    const user_id = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const nearmiss_id = this.state.soiId;
    const selected_recommendations =
      this.state.nmDetail.nm_reported_detail_final_recommendation_list.map(
        (el) => el.recommendation_id
      );
    const data = {
      auth_key: token,
      user_id,
      service_id,
      project_id,
      nearmiss_id,
      selected_recommendations,
    };
    fetch(baseUrl, {
      method: "post",
      body: qs.stringify(data, { arrayFormat: "repeat" }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          AlertMessage({
            type: "success",
            message: res?.msg ?? this.props.t('recordCompleted'),
          });

          this.props.history.push(`/nm-detail/${nearmiss_id}/kanban/completed`);
          window.location.reload();
        } else {
          AlertMessage({
            type: "error",
            message: res?.msg ?? this.props.t('somethingWentWrong'),
          });
        }
      })
      .catch((err) => console.log("error", err))
      .finally(() =>
        this.setState({
          isFreezeEnabled: false,
          isFreezeLoadingEnabled: false,
        })
      );
  };

  fetchReportingPerson = (id) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    axios
      .post(
        Url.clientURL + "/user/reportedpersonprofile",
        qs.stringify({
          auth_key: token,
          user_id: userId,
          reported_per_id: id,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        this.setState({
          assignedDetails: res.data.data,
        });
      })
      .catch((err) => console.log(err));
  };
  getDepartment = (text = "") => {
    const [label = "", value] = text.split(":") ?? [];
    if ((label.trim() === "Department" && value.trim() !== "null") || null) {
      return value;
    }
    return "NA";
  };

  manageFilterParams = (id, value) => {
    this.setState({
      filterParams: {
        ...this.state.filterParams,
        [id]: value,
      },
    });
  };

  getFilterData = () => {
    this.setState({
      isFilterLoading: true,
    });
    const baseUrl = URL.clientURL + "/listnm/recommendationfilter";
    const user_id = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const project_id = this.state.nmDetail.project_id;
    const service_id = this.state.nmDetail.service_id;
    const nearmiss_id = this.state.soiId;
    const isPropsed = this.state.currentTab === "prop" ? 0 : 1;
    const { extremeSelect, highSelect, mediumSelect, lowSelect } = this.state;
    const data = {
      auth_key: token,
      user_id,
      service_id,
      project_id,
      nearmiss_id,
      ...this.state.filterParams,
      startDate:
        this.state.filterParams.startDate !== ""
          ? moment(this.state.filterParams.startDate).format("YYYY-MM-DD")
          : "",
      endDate:
        this.state.filterParams.endDate !== ""
          ? moment(this.state.filterParams.endDate).format("YYYY-MM-DD")
          : "",
      list_type: isPropsed,
      priority: [
        extremeSelect ? extremeSelect : undefined,
        highSelect ? highSelect : undefined,
        mediumSelect ? mediumSelect : undefined,
        lowSelect ? lowSelect : undefined,
      ],
    };
    axios
      .post(baseUrl, qs.stringify(data, { arrayFormat: "repeat" }), {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            nmDetail: {
              ...this.state.nmDetail,
              [isPropsed === 0
                ? "nm_reported_detail_proposed_recommendation_list"
                : "nm_reported_detail_final_recommendation_list"]:
                res.data.data.recommendationlist,
            },
            isDataFilterEnable: false,
          });
        } else if (res.data.status === 500) {
          AlertMessage({
            type: "error",
            message: this.props.t('somethingIsWrong'),
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({
          isFilterLoading: false,
        });
      });
  };
  FilterData(param, value) {
    if (value == 4) {
      if (param == 0) {
        this.setState({ extremeSelect: 4, all: 0 });
      } else {
        this.setState({ extremeSelect: 0, all: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ highSelect: 3, all: 0 });
      } else {
        this.setState({ highSelect: 0, all: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ mediumSelect: 2, all: 0 });
      } else {
        this.setState({ mediumSelect: 0, all: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ lowSelect: 1, all: 0 });
      } else {
        this.setState({ lowSelect: 0, all: 0 });
      }
    }
    // if (value == 'overdue') {
    //   if (param == 0) {
    //     this.setState({ overdue: 1, all: 0 });
    //   } else {
    //     this.setState({ overdue: 0, all: 0 });
    //   }
    // }
    // if (value == 'all') {
    //   if (param == 0) {
    //     this.setState({
    //       all: 1,
    //       extremeSelect: 4,
    //       highSelect: 3,
    //       mediumSelect: 2,
    //       lowSelect: 1,
    //       severity: [1, 2, 3, 4],
    //     });
    //   } else {
    //     this.setState({
    //       all: 0,
    //       extremeSelect: 0,
    //       highSelect: 0,
    //       mediumSelect: 0,
    //       lowSelect: 0,
    //       severity: [],
    //     });
    //   }
    // }
  }

  makeReset = () => {
    this.setState(
      {
        isDataFilterEnable: false,
        extremeSelect: 0,
        extremeValue: 0,
        highSelect: 0,
        mediumSelect: 0,
        lowSelect: 0,
        filterParams: {
          category: "",
          recommendation_by: "",
          responsible_person_id: "",
          startDate: "",
          endDate: "",
          page_no: 1,
        },
      },
      this.nmDetail
    );
  };
  _getCommonParams = () => {
    return {
      auth_key: localStorage.getItem("token"),
      user_id: localStorage.getItem("userId"),
      service_id: this.state.nmDetail.service_id,
      project_id: this.state.nmDetail.project_id,
      nearmiss_id: this.state.soiId,
      module_name: "near_miss",
    };
  };
  _removeFactor = (factorId, sub_factor_id, callBack) => {
    let data = {
      ...this._getCommonParams(),
      factor_id: sub_factor_id,
      sub_factor_id: factorId,
      module_unique_id: this.state.soiId,
      module_name: "near_miss",
    };

    let formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    fetch(Url.clientURL + "/rootcause/deletefactors", {
      method: "post",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status == 200) {
          this.nmDetail();
          this.nmDetails();
          this._InProgressContributingList();
          this._InProgressImmediateList();
          this._InProgressRcaList();
          this.resetFactorsState(this.toggleStep2Fun);
        }
      })
      .finally(() => {
        callBack(sub_factor_id);
      });
  };
  manageFactors = (data) => {
    const { factorID, formType } = data ?? {};
    this.setState(
      {
        isFactorLoading: true,
      },
      this._removeFactor(factorID, formType, (id) => {
        this.setState({
          isFactorLoading: false,
          factorParams: {},
        });
        // if (id === 3) {
        //   this._InProgressRcaList();
        // } else if (id === 1) {
        //   this._InProgressContributingList();
        // } else {
        //   this._InProgressImmediateList();
        // }
      })
    );
  };
  manageCapaValidation = (isEditTrue = false) => {
    const {
      responsible_p,
      category_l,
      capaType,
      selectedDate,
      CorrectiveActionText,
      priority_l,
    } = this.state;
    let isErrorEnabled = false;
    if (
      !responsible_p ||
      !category_l ||
      !capaType ||
      !CorrectiveActionText ||
      !selectedDate ||
      (!isEditTrue && !priority_l)
    ) {
      isErrorEnabled = true;
    }
    this.setState({
      iAssignErrorEnabled: !responsible_p,
      isCategoryErrorEnabled: !category_l,
      isCorretiveActionErrorEnabled: !CorrectiveActionText,
      isCAPAErrorEnabled: !capaType,
      isDueDateErrorEnabled: !selectedDate,
      isPriortiyErrorEnabled: isEditTrue ? false : !priority_l,
    });
    return isErrorEnabled;
  };
  fetchUsersList = async (ids = []) => {
    let databody = new FormData();
    const userList = ids.filter((el) => {
      return !Number.isNaN(el);
    });
    if (!userList.length) {
      return false;
    }
    databody.append("auth_key", localStorage.getItem("token"));
    databody.append("user_id", localStorage.getItem("userId"));
    databody.append("user_id_list", userList);
    await axios
      .post(Url.usersList, databody, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            userLists: response.data?.listUserProfileDetailsData,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  CountDoughnut = (data) => {
    return data.length > 0 ? (
      <div style={{ justifyContent: "center" }}>
        <UaUcGraph
          title=""
          graphData={data}
          list={false}
          height={75}
          cutout={"55%"}
          radius={"100%"}
        />
      </div>
    ) : null;
  };
  debounceFunc = _.debounce(function (callback) {
    callback();
  }, 300);

  render() {
    const {t} = this.props;
    console.log("nm detailsssss", this.state.nmDetail.activity_analysis);
    var sourcesList = [];
    var sourcesType = [];
    var inputMessageFlag = 0;
    const { nmDetail, soiId, nmCache, userData, filterParams } = this.state;
    const teamLead =
      nmDetail?.nm_reported_detail_people_involved_list?.filter(
        (el) => el.status === 1
      )[0] ?? [];
    const isApproveEnabled =
      userData?.designation_flag === 1 ||
      userData?.designation_flag === 3 ||
      userData?.designation_flag === 5 ||
      userData?.user_id === teamLead?.user_id;
    const isReportedByEnabled = userData?.user_id === nmDetail?.reported_by_id;
    const capaMinDate = !_.isUndefined(nmDetail.target_date)
      ? getNextDays(_momentDate(nmDetail.target_date))
      : new Date();
    const isTeamMemeberLoggedIn = Boolean(
      searchInArrary(nmDetail?.nm_reported_detail_people_involved_list, {
        key: "user_id",
        value: userData?.user_id,
      })
    );

    const list =
      this.state.currentTab === "prop"
        ? nmCache?.nm_reported_detail_proposed_recommendation_list
        : nmCache?.nm_reported_detail_final_recommendation_list ?? [];

    const recommendedby = _.unionBy(
      list?.map((el) => {
        return {
          recommendedBy: el?.recommended_by,
          recommended_id: el?.recommended_by_id,
        };
      }),
      "recommended_id"
    );
    // [...new Map(arr.map(item => [item[key], item])).values()]
    const isTargetDateEnabled = Boolean(
      nmDetail.nm_reported_detail_people_involved_list?.filter(
        (el) => el.status === 1
      )?.length
    );

    if (this.state.imageType && this.state.imageType == "evidence") {
      sourcesList = nmDetail.nm_reported_detail_evidence_images?.map(
        (item) => item.evidence_image
      );
      sourcesType = nmDetail.nm_reported_detail_evidence_images?.map(
        (item) => item.evidence_format
      );
    }
    const isInProgress = ["progress"].includes(this.state.reportStatus);
    const isCrownEnabled =
      ["progress", "pending"].includes(this.state.reportStatus) &&
      isApproveEnabled;
    const isDeleteEnabled =
      ["progress", "pending"].includes(this.state.reportStatus) &&
      isApproveEnabled;
    if (this.state.redirectToReferrer) {
      return <Redirect to={`/nm/dashboard`} />;
    }

    let uaUcChartData = [];
    if (nmDetail?.activity_analysis != undefined) {
      uaUcChartData = [
        {
          y: nmDetail.activity_analysis?.ua_number,
          name: "Unsafe Act",
          color: "#C7AC83",
        },
        {
          y: nmDetail.activity_analysis?.uc_number,
          name: "Unsafe Condition",
          color: "#554040",
        },
      ];
    }

    const uaUcChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: nmDetail.activity_analysis?.ua_number,
              name: "Unsafe Act",
              color: "#C7AC83",
            },
            {
              y: nmDetail.activity_analysis?.uc_number,
              name: "Unsafe Condition",
              color: "#554040",
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    let nmuaUcChartData = [];

    if (nmDetail?.activity_analysis != undefined) {
      nmuaUcChartData = [
        {
          y: nmDetail.activity_analysis?.nm_ua_number,
          name: "Unsafe Act",
          color: nmDetail.activity_analysis?.nm_ua_color,
        },
        {
          y: nmDetail.activity_analysis?.nm_uc_number,
          name: "Unsafe Condition",
          color: nmDetail.activity_analysis?.nm_uc_color,
        },
      ];
    }

    const nmuaUcChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: nmDetail.activity_analysis?.nm_ua_number,
              name: "Unsafe Act",
              color: nmDetail.activity_analysis?.nm_ua_color,
            },
            {
              y: nmDetail.activity_analysis?.nm_uc_number,
              name: "Unsafe Condition",
              color: nmDetail.activity_analysis?.nm_uc_color,
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };
    let analysisChartData = [];
    if (nmDetail?.activity_analysis != undefined) {
      analysisChartData = [
        {
          y: nmDetail?.activity_analysis?.fa_number ?? 0,
          name: "FA",
          color: "#FF4242",
        },
        {
          y: nmDetail?.activity_analysis?.tra_number ?? 0,
          name: "TRA",
          color: "#FAAC15",
        },
        {
          y: nmDetail?.activity_analysis?.mtc_number ?? 0,
          name: "MTC",
          color: "#086992",
        },
        {
          y: nmDetail?.activity_analysis?.lti_number ?? 0,
          name: "LTI",
          color: "#18DA07",
        },
        {
          y: nmDetail?.activity_analysis?.first_aid_number ?? 0,
          name: "First Aid",
          color: "#1589EE",
        },
        {
          y: nmDetail?.activity_analysis?.occupational_illness_number ?? 0,
          name: "OI",
          color: "#C511FF",
        },
        {
          y: nmDetail?.activity_analysis?.notable_number || 0,
          name: "Notable",
          color: "rgb(161, 255, 206)",
        },
      ];
    }

    const analysisChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: nmDetail.activity_analysis?.fa_number || 0,
              name: "FA",
              color: "#FF4242",
            },
            {
              y: nmDetail.activity_analysis?.tra_number || 0,
              name: "RTA",
              color: "#FAAC15",
            },
            {
              y: nmDetail.activity_analysis?.mtc_number || 0,
              name: "MTC",
              color: "#086992",
            },
            {
              y: nmDetail.activity_analysis?.lti_number || 0,
              name: "LTI",
              color: "#18DA07",
            },
            {
              y: nmDetail.activity_analysis?.first_aid_number || 0,
              name: "First Aid",
              color: "#1589EE",
            },
            {
              y: nmDetail.activity_analysis?.occupational_illness_number || 0,
              name: "OI",
              color: "#C511FF",
            },
            {
              y: nmDetail.activity_analysis?.notable_number || 0,
              name: "Notable",
              color: "#rgb(161, 255, 206)",
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    //DaysCount
    const newDays = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2rem" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.completioncount.map(
            (data) => data.capa_completion_days_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.completioncount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.completioncount.map(
              (data) => data.capa_completion_days_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //Investigation
    const investigationChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2rem" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.capacount.map(
            (data) => data.report_completion_days_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.capacount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.capacount.map(
              (data) => data.report_completion_days_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //Open
    const openChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2rem" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.opencount.map((data) => data.recommendation_open_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.opencount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.opencount.map((data) => data.recommendation_open_count),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //Close
    const closeChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2rem" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.closecount.map((data) => data.recommendation_close_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.closecount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.closecount.map(
              (data) => data.recommendation_close_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //GpCount
    const gpChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2rem" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.gpcount.map((data) => data.gp_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.gpcount.map((data) => data.color),
      series: [
        {
          data: [this.state.gpcount.map((data) => data.gp_count)],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //tbtCount
    const tbtChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2rem" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.tbtcount.map((data) => data.tbt_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.tbtcount.map((data) => data.color),
      series: [
        {
          data: [this.state.tbtcount.map((data) => data.tbt_count)],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //InternalCOunt
    const internalCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2rem" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingInternalCount.map(
            (data) => data.training_internal_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingInternalCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingInternalCount.length > 0
              ? this.state.trainingInternalCount.map(
                  (data) => data.training_internal_count
                )
              : 0,
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //externalcount
    const externalCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2rem" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingExternalCount.map(
            (data) => data.training_external_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingExternalCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingExternalCount.map(
              (data) => data.training_external_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //employeeCount
    const employeeCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2rem" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingEmployeeCount.map(
            (data) => data.training_employee_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingEmployeeCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingEmployeeCount.map(
              (data) => data.training_employee_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //contractorCount
    const contractorCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2rem" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingContractorCount.map(
            (data) => data.training_contractor_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingContractorCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingContractorCount.map(
              (data) => data.training_contractor_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    const hTriangleChart = {
      chart: {
        type: "pyramid",
      },
      colors: ["#206EB6", "#45B5F4", "#DBFF00", "#FF9900", "#FF0000"],
      credits: {
        enabled: false,
      },
      title: {
        text: "H T Present",
        x: -50,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b> ({point.y:,.0f})",
            softConnector: true,
          },
          center: ["42%", "50%"],
          width: "60%",
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        pointFormat: "<b>{point.y}</b>",
        shared: true,
        backgroundColor: "#FFFFFF",
        borderColor: "#EBEBEB",
        borderRadius: 10,
      },
      series: [
        {
          data: [
            ["UA/UC", nmDetail.activity_analysis?.ua_number+nmDetail?.activity_analysis?.uc_number],
            ["NM", nmDetail?.activity_analysis?.nm_ua_number+nmDetail?.activity_analysis?.nm_uc_number],
            ["Notable",  nmDetail?.activity_analysis?.notable_number],
            ["FIrst Aid",  nmDetail?.activity_analysis?.first_aid_number],
            ["OI",  nmDetail?.activity_analysis?.occupational_illness_number],
            ["LTI",  nmDetail?.activity_analysis?.lti_number],
            ["TRA",  nmDetail?.activity_analysis?.tra_number],
            ["MTC", nmDetail?.activity_analysis?.mtc_number],
            ["FA", nmDetail?.activity_analysis?.fa_number],
          ],
        },
      ],
    };

    if (this.state.originFlag == "socialMedia") {
      var originUrl = {
        pathname: `/`,
      };
    } else if (this.state.originFlag == "thgPO") {
      var originUrl = {
        pathname: `/ua-uc/open`,
      };
    } else if (this.state.originFlag == "thgPR") {
      var originUrl = {
        pathname: `/ua-uc/resolve`,
      };
    }
    
    const variablesObj = {
      isCrownEnabled,
      isDeleteEnabled,
      isApproveEnabled,
      isInProgress,
    };
    const chartsVariableObj = {
      analysisChart,
      analysisChartData,
      nmuaUcChartData,
      nmuaUcChart,
      uaUcChartData,
      uaUcChart,
      gpChart,
      tbtChart,
      openChart,
      closeChart,
      investigationChart,
      internalCount,
      externalCount,
      employeeCount,
      contractorCount,
      newDays,
      hTriangleChart,
    };

    return (
      <>
        <div>
          <div className="clide-v2-pe-5 clide-v2-ps-5 container-fluid mb-5 pe-5 ps-5">
            <DetailTimelineSection
              data={this.renderTimeLine(nmDetail, this.state)}
              originFlag={this.state.originFlag}
              state={this.state}
              functionContent={this}
              variablesObj={variablesObj}
            />
            <div className="row mt-3">
              <div className="col-12 col-md-9 col-lg-9">
                <DetailLeftSection
                  state={this.state}
                  functionContent={{
                    ...this,
                    setState: this.setState,
                    toggleAddQuestionTab: this.toggleAddQuestionTab,
                    toggleStep2Fun: this.toggleStep2Fun,
                    toggleStep3Tab: this.toggleStep3Tab,
                    toggleDeletePopupCAPA: this.toggleDeletePopupCAPA,
                    toggleApproveTabCAPA: this.toggleApproveTabCAPA,
                  }}
                  variablesObj={variablesObj}
                />
              </div>
              <div className="col-12 col-md-3 col-lg-3">
                <DetailRightSection
                  state={this.state}
                  functionContent={this}
                  variablesObj={variablesObj}
                  chartsVariableObj={chartsVariableObj}
                />
              </div>
            </div>
          </div>
          {!this.state.dataLoading ? (
            <FsLightbox
              toggler={this.state.fsToggler}
              types={sourcesType}
              sources={sourcesList}
            />
          ) : null}

          {!!this.state.lightboxactive && (
            <FsLightbox
              toggler={this.state.fsToggler1}
              types={this.state.evidencelightboxType}
              disableLocalStorage={true}
              sources={this.state.evidencelightbox}
            />
          )}
          <Modal
            centered
            show={this.state.toggleStep2}
            className="submit-modal"
          >
            <Modal.Header className="align-items-center">
              <div className="align-items-center d-flex justify-content-between">
                <div>
                  <span className="font-20 font-weight-500 common-black-1">
                    {" "}
                    {this.state.step2Type}{" "}
                  </span>
                </div>
              </div>
              <div className="modal-controls">
                <button onClick={this.hideModal}>{t('close')}</button>

                <button
                  type="button"
                  //  onClick={this.handleContrubutingSubmit}
                  disabled={this.state.isFactorLoading}
                  onClick={() =>
                    this.debounceFunc(() => {
                      this._InProgressFactorAddList(
                        this.state.step2Type === "Contributing Factor"
                          ? 1
                          : this.state.step2Type === "Immediate Factor"
                          ? 2
                          : this.state.RCAFList
                          ? 3
                          : ""
                      );
                    })
                  }
                  // onClick={(e) => {
                  //   if (e.detail == 1) {
                  //     this._InProgressFactorAddList(
                  //       this.state.step2Type === "Contributing Factor"
                  //         ? 1
                  //         : this.state.step2Type === "Immediate Factor"
                  //         ? 2
                  //         : this.state.RCAFList
                  //         ? 3
                  //         : ""
                  //     );
                  //   }
                  // }}
                >
                  {t('submit')}
                </button>
              </div>
            </Modal.Header>

            <div>
              {this.state.step2Type === "Contributing Factor" ? (
                <Modal.Body>
                  {/* <div className="d-flex space-between mx-5">
                    <p className="common-black-1 font-16 mb-0 font-weight-500">
                      Selected
                    </p>
                    <p className="common-black-1 font-16 mb-0 font-weight-500">
                      Unselect
                    </p>
                  </div>
                  <hr /> */}
                  <div className="d-flex space-between flex-row-reverse">
                    <form className="common-check-box-layout">
                      {
                        this.state.CFList &&
                          this.state.CFList.factors_list.map((item, index) => (
                            <div key={index}>
                              <input
                                type="checkbox"
                                onChange={this.handleCheckboxChange}
                                id={item.factor_id}
                                checked={item.checked}
                              />
                              <label htmlFor={item.factor_name}>
                                {item.factor_name}
                              </label>
                            </div>
                          ))
                        // :<h3>No data</h3>
                      }
                    </form>
                    {/* <div>
                      {nmDetail?.nm_reported_detail_contrubuting_factor_list?.map(
                        (item, index) => (
                          <div
                            key={index}
                            className="my-2 common-check-box-layout d-flex space-between"
                          >
                            <div>
                              <input type="checkbox" defaultChecked disabled />
                              <label htmlFor={item.factor}>{item.factor}</label>
                            </div>
                            <div>
                              <button
                                className="clide-v2-icon-btn mx-2"
                                title="Delete"
                                onClick={() => {
                                  this.setState({
                                    factorParams: {
                                      factorID: item.factor_id,
                                      formType: 1,
                                    },
                                  });
                                }}
                              >
                                <RoundDelete />
                              </button>
                            </div>
                          </div>
                        )
                      )}
                    </div> */}
                  </div>
                </Modal.Body>
              ) : null}

              {this.state.step2Type === "Immediate Factor" ? (
                <Modal.Body>
                  {/* <div className="d-flex space-between mx-5">
                    <p className="common-black-1 font-16 mb-0 font-weight-500">
                      Selected
                    </p>
                    <p className="common-black-1 font-16 mb-0 font-weight-500">
                      Unselect
                    </p>
                  </div>
                  <hr /> */}
                  <div className="d-flex space-between flex-row-reverse">
                    <form className="common-check-box-layout">
                      {this.state.IFList &&
                        this.state.IFList.factors_list.map((item, index) => (
                          <div key={index}>
                            <input
                              type="checkbox"
                              onChange={this.handleCheckboxChangeIF}
                              id={item.factor_id}
                              checked={item.checked}
                            />
                            <label htmlFor={item.factor_name}>
                              {item.factor_name}
                            </label>
                          </div>
                        ))}
                    </form>
                    {/* <div>
                      {nmDetail?.nm_reported_detail_immediate_factor_list?.map(
                        (item, index) => (
                          <div
                            key={index}
                            className="my-2 common-check-box-layout d-flex space-between"
                          >
                            <div>
                              <input type="checkbox" defaultChecked disabled />
                              <label htmlFor={item.factor}>{item.factor}</label>
                            </div>
                            <div>
                              <button
                                className="clide-v2-icon-btn mx-2"
                                title="Delete"
                                onClick={() => {
                                  this.setState({
                                    factorParams: {
                                      factorID: item.factor_id,
                                      formType: 2,
                                    },
                                  });
                                }}
                              ></button>
                            </div>
                          </div>
                        )
                      )}
                    </div> */}
                  </div>
                </Modal.Body>
              ) : null}

              {this.state.step2Type === "Root Cause Analysis" ? (
                <Modal.Body>
                  <div className="d-flex space-between flex-row-reverse">
                    <form className="common-check-box-layout">
                      {this.state.RCAFList &&
                        this.state.RCAFList.factors_list.map((item, index) => (
                          <div key={index} className="d-flex space-between">
                            <div>
                              <input
                                type="checkbox"
                                onChange={this.handleCheckboxChangeRAC}
                                id={item.factor_id}
                                checked={item.checked}
                              />
                              <label htmlFor={item.factor_name}>
                                {item.factor_name}
                              </label>
                            </div>
                          </div>
                        ))}
                    </form>
                  </div>
                </Modal.Body>
              ) : null}
            </div>
          </Modal>

          <Modal centered show={this.state.toggleStep3}>
            <Modal.Header className="align-items-center">
              <div className="align-items-center d-flex justify-content-between">
                <div>
                  <span className="font-20 font-weight-500 common-white-2 clide-v2-primary-color font-weight-bold">
                    {" "}
                    {this.state.editR_id ? t('editAUC') : t('add')}
                  </span>
                </div>
              </div>
              <div className="modal-controls">
                <button
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.toggleStep3Tab();
                      this.setState({
                        iAssignErrorEnabled: false,
                        isCategoryErrorEnabled: false,
                        isCorretiveActionErrorEnabled: false,
                        isCAPAErrorEnabled: false,
                        isDueDateErrorEnabled: false,
                        isPriortiyErrorEnabled: false,
                      });
                    })
                  }
                  // onClick={() => {
                  //   this.toggleStep3Tab();
                  //   this.setState({
                  //     iAssignErrorEnabled: false,
                  //     isCategoryErrorEnabled: false,
                  //     isCorretiveActionErrorEnabled: false,
                  //     isCAPAErrorEnabled: false,
                  //     isDueDateErrorEnabled: false,
                  //     isPriortiyErrorEnabled: false,
                  //   });
                  // }}
                >
                  {t('close')}
                </button>
                <button
                  disabled={this.state.isRecommendationEnabled}
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.state.editR_id
                        ? this._EditRecommendation()
                        : this._AddRecommendation();
                    })
                  }
                  // onClick={(e) => {
                  //   if (e.detail == 1) {
                  //     this.state.editR_id
                  //       ? this._EditRecommendation()
                  //       : this._AddRecommendation();
                  //   }
                  // }}
                  // onClick={this.state.editR_id ? this._EditRecommendation : this._AddRecommendation}
                >
                  {this.state.isRecommendationEnabled ? (
                    <RefreshCw className="rotate-360" />
                  ) : this.state.editR_id ? (
                    t('editAUC')
                  ) : (
                    t('add')
                  )}
                </button>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <div className="rd-box-head">
                  <div className="with-mic">
                    <h6>
                      {t('selectCategory')}
                      <span className="inputErrorMsg">&nbsp;*</span>
                    </h6>
                  </div>

                  <div className="common-dropdown-layout">
                    <select
                      className="form-control1"
                      id={this.state.category_l}
                      value={this.state.category_l}
                      placeholder={t('selectCategory')}
                      onChange={(e) =>
                        this.setState({ category_l: e.target.value })
                      }
                    >
                      <option value="" disabled selected>
                        {t('chooseOption')}
                      </option>
                      {this.state.Category_list?.map((item, index) => (
                        <option key={index} value={item.category_id}>
                          {item.category}
                        </option>
                      ))}
                    </select>
                    {this.state.isCategoryErrorEnabled ? (
                      <p className="text-danger font-16">
                         {t('pleaseChooseOption')}
                      </p>
                    ) : null}
                  </div>

                  <div className="with-mic my-3">
                    <h6>
                       {t('capa')}
                      <span className="inputErrorMsg">&nbsp;*</span>
                    </h6>

                    <div className="control1 common-check-box-layout d-flex">
                      <input
                        type="checkbox"
                        id="CA"
                        checked={this.state.capaType === "CA"}
                        onChange={() => this.setState({ capaType: "CA" })}
                        name="CA"
                        value="CA"
                      />
                      <label htmlFor="Priority1" className="mr-3">
                        {" "}
                        CA
                      </label>
                      <input
                        type="checkbox"
                        id="PA"
                        checked={this.state.capaType === "PA"}
                        onChange={() => this.setState({ capaType: "PA" })}
                        name="PA"
                        value="PA"
                      />
                      <label htmlFor="Priority2"> PA</label>
                    </div>
                    {this.state.isCAPAErrorEnabled ? (
                      <p className="text-danger font-16">
                         {t('pleaseSelectCapaType')}
                      </p>
                    ) : null}
                  </div>

                  <div className="with-mic my-3">
                    {this.state.capaType === "PA" ? (
                      <h6>
                        {t('preventiveAction')}
                        <span className="inputErrorMsg">&nbsp;*</span>
                      </h6>
                    ) : (
                      <h6>
                       {t('correctiveAction')}
                        <span className="inputErrorMsg">&nbsp;*</span>
                      </h6>
                    )}
                    {/* <h6>
                          Corrective Action12
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6> */}
                  </div>

                  <textarea
                    className="form-control"
                    onChange={(event) =>
                      this.setState({
                        CorrectiveActionText: event.target.value,
                      })
                    }
                    value={this.state.CorrectiveActionText}
                    placeholder={
                      this.state.capaType === "PA"
                        ? "Enter Preventive Action"
                        : "Enter Corrective Action"
                    }
                  ></textarea>
                  {this.state.isCorretiveActionErrorEnabled ? (
                    <p className="text-danger font-16">{t('pleaseEnterAction')}</p>
                  ) : null}
                  <div className="with-mic my-3">
                    <h6>
                      {t('assignedTo')}
                      <span className="inputErrorMsg">&nbsp;*</span>
                    </h6>
                  </div>

                  <div className="common-dropdown-layout">
                    <select
                      className="form-control1"
                      id={this.state.responsible_p}
                      value={this.state.responsible_p}
                      onChange={(e) =>
                        this.setState({ responsible_p: e.target.value })
                      }
                    >
                      <option value="" disabled selected>
                        {t('chooseOption')}
                      </option>
                      {this.state.responsible_person?.map((item, index) => (
                        <option key={index} value={item.person_id}>
                          {item.person}
                        </option>
                      ))}
                    </select>
                  </div>
                  {this.state.iAssignErrorEnabled ? (
                    <p className="text-danger font-16">{t('pleaseChooseOption')}</p>
                  ) : null}

                  <div className="with-mic my-3">
                    <h6>
                      {t('dueDate')}
                      <span className="inputErrorMsg">&nbsp;*</span>
                    </h6>
                  </div>

                  <div>
                    <DatePicker
                      selected={this.state.selectedDate}
                      placeholderText="Due Date"
                      inputStyle={{ textAlign: "center" }}
                      minDate={capaMinDate}
                      onChange={(jsDate) =>
                        this._handleChange(jsDate, "step3Date")
                      }
                    />
                    {this.state.isDueDateErrorEnabled ? (
                      <p className="text-danger font-16">{t('pleaseSelectDate')}</p>
                    ) : null}
                  </div>

                  <div className="with-mic my-3">
                    <h6>
                      {t('priority')}
                      <span className="inputErrorMsg">&nbsp;*</span>
                    </h6>
                  </div>

                  <div className="control1 common-check-box-layout d-flex">
                    <input
                      type="checkbox"
                      id="Priority"
                      checked={this.state.priority.extreme}
                      onChange={() => this.onPriorityChange(0)}
                      name="Priority1"
                      value="Extreme"
                    />
                    <label htmlFor="Priority1" className="mr-3">
                      {" "}
                      {t('critical')}
                    </label>
                    <input
                      type="checkbox"
                      id="Priority"
                      checked={this.state.priority.high}
                      onChange={() => this.onPriorityChange(1)}
                      name="Priority2"
                      value="High"
                    />
                    <label htmlFor="Priority2"> {t('urgent')}</label>
                    <br />
                    <input
                      type="checkbox"
                      className="ml-3"
                      id="Priority"
                      checked={this.state.priority.medium}
                      onChange={() => this.onPriorityChange(2)}
                      name="Priority3"
                      value="Medium"
                    />
                    <label htmlFor="Priority3" className="mr-3">
                      {" "}
                      {t('important')}
                    </label>
                    <input
                      type="checkbox"
                      id="Priority"
                      checked={this.state.priority.low}
                      onChange={() => this.onPriorityChange(3)}
                      name="Priority4"
                      value="Low"
                    />
                    <label htmlFor="Priority4"> {t('normal')}</label>
                  </div>
                  {this.state.isPriortiyErrorEnabled ? (
                    <p className="text-danger font-16">
                      {t('pleaseChoosePriority')}
                    </p>
                  ) : null}
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal centered show={this.state?.isDataFilterEnable ?? false}>
            <Modal.Header className="align-items-center">
              <div className="align-items-center d-flex justify-content-between">
                <div>
                  <span className="font-20 font-weight-500 common-white-2 clide-v2-primary-color font-weight-bold">
                    {" "}
                    {t('filter')}
                  </span>
                </div>
              </div>
              <div className="d-flex  align-items-center">
                <div className="modal-controls">
                  <button
                    onClick={() =>
                      this.debounceFunc(() => {
                        this.makeReset();
                      })
                    }
                  >
                    {t('reset')}
                  </button>
                  <button
                    onClick={() =>
                      this.debounceFunc(() => {
                        this.getFilterData();
                      })
                    }
                  >
                    {this.state.isFilterLoading ? (
                      <RefreshCw className="rotate-360" />
                    ) : (
                      t('apply')
                    )}
                  </button>
                </div>
                <X
                  style={{
                    height: "1.4vw",
                    width: "1.4vw",
                  }}
                  className="cursor-p mx-2 common-white-2"
                  onClick={() =>
                    this.setState({
                      isDataFilterEnable: false,
                    })
                  }
                />
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <div className="rd-box-head">
                  <div className="with-mic">
                    <h6>
                      {t('selectCategory')}
                      <span className="inputErrorMsg">&nbsp;*</span>
                    </h6>
                  </div>

                  <div className="common-dropdown-layout">
                    <select
                      className="form-control1"
                      id={this.state.filterParams.category}
                      value={this.state.filterParams.category}
                      onChange={(e) =>
                        this.manageFilterParams("category", e.target.value)
                      }
                    >
                      <option value={""} disabled>
                        {t('chooseOption')}
                      </option>
                      {this.state.Category_list?.map((item, index) => (
                        <option key={index} value={item.category_id}>
                          {item.category}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="with-mic my-3">
                    <h6>
                      {" "}
                      {t('actionProposedBy')}
                      <span className="inputErrorMsg">&nbsp;*</span>
                    </h6>

                    {/* {this.state.capaType === "PA" ? (<h6>
                          Preventive Action Proposed By
                          <span className="inputErrorMsg">&nbsp;*</span>
                        </h6>):(<h6>Corrective Action Proposed By
                                <span className="inputErrorMsg">&nbsp;*</span>
                                </h6>)} */}
                  </div>
                  <div className="common-dropdown-layout">
                    <select
                      className="form-control1"
                      id={this.state.filterParams.recommendation_by}
                      value={this.state.filterParams.recommendation_by}
                      onChange={(e) =>
                        this.manageFilterParams(
                          "recommendation_by",
                          e.target.value
                        )
                      }
                    >
                      <option value={""} disabled>
                        {t('chooseOption')}
                      </option>
                      {recommendedby?.map((item, index) => (
                        <option key={index} value={item?.recommended_id}>
                          {item?.recommendedBy}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="with-mic my-3">
                    <h6>
                      {t('assignedTo')}
                      <span className="inputErrorMsg">&nbsp;*</span>
                    </h6>
                  </div>

                  <div className="common-dropdown-layout">
                    <select
                      className="form-control1"
                      id={this.state.filterParams.responsible_person_id}
                      value={this.state.filterParams.responsible_person_id}
                      onChange={(e) =>
                        this.manageFilterParams(
                          "responsible_person_id",
                          e.target.value
                        )
                      }
                    >
                      <option value={""} disabled>
                        {t('chooseOption')}
                      </option>
                      {this.state.responsible_person?.map((item, index) => (
                        <option key={index} value={item.person_id}>
                          {item.person}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="with-mic my-3">
                    <h6>
                      {t('dueDate')}
                      <span className="inputErrorMsg">&nbsp;*</span>
                    </h6>
                  </div>

                  <div className="d-flex common-datepick-layout">
                    <div className="w-50">
                      <DatePicker
                        placeholderText="Start Date"
                        inputStyle={{ textAlign: "center" }}
                        className="mx-2"
                        minDate={capaMinDate}
                        id={this.state.filterParams.startDate}
                        selected={this.state.filterParams.startDate}
                        onChange={(jsDate) =>
                          this.manageFilterParams("startDate", jsDate)
                        }
                      />
                    </div>
                    <div className="w-50 mx-3">
                      <DatePicker
                        placeholderText="End Date"
                        inputStyle={{ textAlign: "center" }}
                        className="mx-2"
                        id={this.state.filterParams.endDate}
                        minDate={this.state.filterParams.startDate}
                        selected={this.state.filterParams.endDate}
                        onChange={(jsDate) =>
                          this.manageFilterParams("endDate", jsDate)
                        }
                      />
                    </div>
                  </div>

                  <div className="with-mic my-3">
                    <h6>
                      {t('priority')}
                      <span className="inputErrorMsg">&nbsp;*</span>
                    </h6>
                  </div>

                  <div className="control1 common-check-box-layout d-flex">
                    <input
                      type="checkbox"
                      id="Priority"
                      checked={this.state.extremeSelect}
                      onChange={() =>
                        this.FilterData(this.state.extremeSelect, 4)
                      }
                      name="Priority1"
                      value="Extreme"
                    />
                    <label htmlFor="Priority1" className="mr-3">
                      {t('critical')}{" "}
                    </label>
                    <input
                      type="checkbox"
                      id="Priority"
                      checked={this.state.highSelect}
                      onChange={() => this.FilterData(this.state.highSelect, 3)}
                      name="Priority2"
                      value="High"
                    />
                    <label htmlFor="Priority2"> {t('urgent')}</label>
                    <br />
                    <input
                      type="checkbox"
                      className="ml-3"
                      id="Priority"
                      checked={this.state.mediumSelect}
                      onChange={() =>
                        this.FilterData(this.state.mediumSelect, 2)
                      }
                      name="Priority3"
                      value="Medium"
                    />
                    <label htmlFor="Priority3" className="mr-3">
                      {" "}
                      {t('important')}
                    </label>
                    <input
                      type="checkbox"
                      id="Priority"
                      checked={this.state.lowSelect}
                      onChange={() => this.FilterData(this.state.lowSelect, 1)}
                      name="Priority4"
                      value="Low"
                    />
                    <label htmlFor="Priority4"> {t('normal')}</label>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            centered
            show={this.state.deletePopupCAPA}
            className="submit-modal"
          >
            <Modal.Header closeButton onClick={this.hideModal}></Modal.Header>

            <Modal.Body>
              <img
                className="submit-img"
                src={require("assets/images/delete-popup.svg")}
              />
              <p className="submit-text">
              {t('deleteThis')}
              </p>
              <div className="modal-footer-btn">
                <button
                  type="submit"
                  className="red-btn"
                  onClick={this.hideModal}
                >
                  {t('cancelAUC')}
                </button>
                <button
                  type="submit"
                  className="red-btn"
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.DeleteRemmondation(this.state.r_id);
                    })
                  }
                >
                  {t('yesAUC')}, {t('deleteAUC')}
                </button>
              </div>
            </Modal.Body>
          </Modal>

          <DeletePopup
            showDeletePopup={this.state.deleteQuestionId}
            onCancel={() => this.setState({ deleteQuestionId: null })}
            onConfirm={() =>
              this.debounceFunc(() => {
                this.analysisQuestionDelete(this.state.deleteQuestionId);
              })
            }
            // onConfirm={() =>
            //   this.analysisQuestionDelete(this.state.deleteQuestionId)
            // }
          />
          {this.state.show ? (
            <ResolvePopup
              show={this.state.show}
              modalClose={this.openResolve}
              reportId={this.state.soiId}
              reportingDetails
            />
          ) : null}

          {this.state.showUser ? (
            <InviteUser
              showUser={this.state.showUser}
              closeUser={this.openInviteUser}
              reportId={this.state.soiId}
              moduleType={"nm"}
              // invitedUsersList={this.state.nmDetail.nm_reported_detail_people_involved_list}
              showInvitedUser={false}
              onAddNewUsers={this._addUsersToList}
              project_id={this.state.nmDetail.project_id}
              service_id={this.state.nmDetail.service_id}
            />
          ) : null}
          <Modal
            centered
            show={this.state.toggleAddQuestion}
            className="submit-modal"
          >
            <Modal.Header
              className="align-items-center"
              style={{
                backgroundColor: "rgb(255, 99, 71)",
                color: "white",
              }}
            >
              <div className="align-items-center d-flex justify-content-between">
                <div>
                  <span className="font-20 font-weight-500 ">
                    {" "}
                    {t('addQuestions')}
                  </span>
                </div>
              </div>
              <div className="modal-controls">
                <button
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.hideModal();
                      this.setState({ questionList: [] });
                    })
                  }
                >
                  {t('close')}
                </button>
                <button
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.analysisQuestionAdd();
                    })
                  }
                  // onClick={(e) => {
                  //   if (e.detail == 1) {
                  //     this.analysisQuestionAdd();
                  //   }
                  // }}
                  disabled={
                    !this.state.questionList.length &&
                    this.state.isQuestionLoading
                  }
                >
                  {this.state.isQuestionLoading ? (
                    <RefreshCw className="rotate-360" />
                  ) : (
                    t('apply')
                  )}
                </button>
              </div>
            </Modal.Header>
            <Modal.Body>
              <p className="submit-text">
                 {t('createQuestions')}
                <button
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.addQuestion();
                    })
                  }
                  // onClick={(e) => {
                  //   if (e.detail == 1) {
                  //     this.addQuestion();
                  //   }
                  // }}
                  style={{
                    position: "absolute",
                    right: "2vh",
                    top: "4vh",
                    backgroundColor: "#fff",
                    color: "rgb(255, 99, 71)",
                    minWidth: "5vh",
                    border: "none",
                  }}
                  className="icon-btn"
                >
                  <Plus />
                </button>
              </p>
              <div>
                <Scrollbars style={{ height: "15rem" }}>
                  {this.state.questionList.map((question, index) => (
                    <div key={index} className="mr-4 my-3 slds-form-element">
                      <div className="d-flex space-between my-2">
                        <span className="font-18 font-weight-500 m-1">
                        {t('question')} {index + 1}
                        </span>
                        <Trash
                          onClick={() => {
                            this.removeQuestion(index);
                          }}
                          className="cursor-p"
                        />
                      </div>
                      <div
                        className={`slds-form-element ${
                          question?.isQuestionErrorEnabled
                            ? "slds-has-error"
                            : ""
                        }`}
                      >
                        <label
                          className="slds-form-element__label"
                          for="text-input-id-47"
                        >
                          <abbr className="slds-required" title="required">
                            *{" "}
                          </abbr>
                          {t('question')}
                        </label>
                        <div class="slds-form-element__control">
                          <input
                            type="text"
                            placeholder="Write your question "
                            className="slds-input "
                            onChange={(question) => {
                              this.onChangeHandle(index, question.target.value);
                            }}
                            style={{ color: "black" }}
                            required
                          />
                        </div>
                        {question?.isQuestionErrorEnabled ? (
                          <div
                            className="slds-form-element__help"
                            id="error-message-id-49"
                          >
                            {t('pleaseEnterQuestion')}
                          </div>
                        ) : null}
                      </div>
                      <div
                        className={`slds-form-element ${
                          question?.isDescriptionEnabled ? "slds-has-error" : ""
                        }`}
                      >
                        <label
                          className="slds-form-element__label"
                          for="text-input-id-47"
                        >
                          <abbr className="slds-required" title="required">
                            *{" "}
                          </abbr>
                          {t('answer')}
                        </label>
                        <div class="slds-form-element__control">
                          <input
                            type="text"
                            placeholder="Describe"
                            className="slds-input "
                            onChange={(response) => {
                              this.onChangeHandle2(
                                index,
                                response.target.value
                              );
                            }}
                            style={{ color: "black" }}
                            required
                          />
                        </div>
                        {question?.isDescriptionEnabled ? (
                          <div
                            className="slds-form-element__help"
                            id="error-message-id-49"
                          >
                            {t('pleaseEnterDescription')}.
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </Scrollbars>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.userLikedPopup}
            onHide={this.handleClose}
            className="likeduser"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t('userWhoLiked')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="totalLikedUser">
                <span>{t('all')}</span>
                <span className="count">{this.state.likeNumber}</span>
              </div>
              {this.state.userLike != "" ? (
                this.state.userLike.map((items, index) => (
                  <div className="user-list" key={index}>
                    <img src={items.user_profile} alt={items.user_name} />
                    <h6 className="user-name">{items.user_name}</h6>
                  </div>
                ))
              ) : (
                <div className="user-list">
                  <p className="data-not-present">{t('likesNotAvailable')} </p>
                </div>
              )}
            </Modal.Body>
          </Modal>
          <Modal show={this.state.teamLeadID !== null} className="likeduser">
            {/* <Modal.Header closeButton>
            <Modal.Title>User Who Liked</Modal.Title>
          </Modal.Header> */}
            <Modal.Body>
              <div className="rd-box shadow-none ">
                <div className="d-flex space-between">
                  <div className="rd-box-head1 text-center">
                    <h5 className="font-weight-500 common-black-1 clide-v2-primary-color font-weight-bold">
                      {t('select')} {t('dueDate')}
                    </h5>
                  </div>
                  {isInProgress && (
                    <div>
                      <span className="font-weight-light font-16">
                        {t('previous')} {t('dueDate')} : {nmDetail.target_date}
                      </span>
                    </div>
                  )}
                </div>

                <Col style={{ margin: "1rem", width: "80%" }}>
                  <DatePicker
                    selected={this.state.selectedTargetDate}
                    minDate={new Date()}
                    placeholderText="Due date"
                    inputStyle={{ textAlign: "center" }}
                    onChange={(jsDate) =>
                      this._handleChange(jsDate, "target_date")
                    }
                    popperProps={{
                      positionFixed: true, // use this to make the popper position: fixed
                    }}
                  />
                </Col>

                <Col className="text-center">
                  <button
                    disabled={this.state.selectedTargetDate === null}
                    type="submit"
                    className="btn btn-outline-danger font-weight-500"
                    onClick={() =>
                      this.debounceFunc(() => {
                        this.confirmSubmitTargetDate();
                      })
                    }
                    // onClick={() => {
                    //   this.confirmSubmitTargetDate();
                    // }}
                  >
                    {t('confirm')}
                  </button>
                </Col>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            centered
            show={this.state.isFreezeEnabled}
            className="submit-modal"
          >
            <Modal.Header
              onClick={() => {
                this.setState({
                  isFreezeEnabled: false,
                });
              }}
            ></Modal.Header>

            <Modal.Body className="px-0">
              <p className="submit-text">
                {t('freezeFinalCorrectiveAction')}
              </p>
              {this.state.isFreezeLoadingEnabled ? (
                <div className="mx-2">
                  <Spinner />
                </div>
              ) : (
                <div className="modal-footer-btn my-3">
                  <button
                    type="submit"
                    className="clide-v2-primary clide-v2-white-color btn"
                    onClick={() =>
                      this.debounceFunc(() => {
                        this.actionFreezeAll();
                      })
                    }
                  >
                    {t('freezeAll')}
                  </button>
                  <button
                    type="submit"
                    className="btn border"
                    onClick={() =>
                      this.debounceFunc(() => {
                        this.setState({
                          isFreezeEnabled: false,
                        });
                      })
                    }
                  >
                    {t('cancel')}
                  </button>
                </div>
              )}
            </Modal.Body>
          </Modal>
          <Modal centered show={this.state.ApproveTab} className="submit-modal">
            <Modal.Header closeButton onClick={this.hideModal}></Modal.Header>

            <Modal.Body className="text-center p-0">
              <img
                className="submit-img"
                src={require("assets/images/delete-popup.svg")}
              />
              <p className="submit-text">
                 {t('youWantToApproveThis')}
              </p>
              <div className="modal-footer-btn my-4">
                <button
                  type="submit"
                  className="btn border"
                  onClick={this.hideModal}
                >
                  {t('cancelAUC')}
                </button>
                <button
                  type="submit"
                  className="btn clide-v2-primary clide-v2-white-color"
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.ApproveRemmondation(this.state.r_id);
                    })
                  }
                >
                  {t('yesAUC')}, {t('approveAUC')}
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <SLDModal
            disableClose
            footer={[
              <SLDButton
                key="promptBtn1"
                label={this.state.isFactorLoading ? "Loading ..." : "Yes"}
                onClick={() =>
                  this.debounceFunc(() => {
                    this.manageFactors(this.state.factorParams);
                  })
                }
              />,
              <SLDButton
                key="promptBtn"
                label="No"
                onClick={() =>
                  this.debounceFunc(() => {
                    this.setState({
                      factorParams: {},
                    });
                  })
                }
                disabled={this.state.isFactorLoading}
              />,
            ]}
            isOpen={Object.keys(this.state.factorParams).length}
            contentClassName="text-center"
            prompt="error"
            size="small"
            title={
              <span className=" font-18 font-weight-500 text-center w-100">
                {t('deleteRecord')}
              </span>
            }
          >
            <div className="common-black-1 font-16 font-weight-500 slds-m-around_medium">
              {t('wantToDeleteThis')}
            </div>
          </SLDModal>
          <Modal
            centered
            show={this.state.afterTargetDatePopup}
            className="submit-modal"
          >
            <Modal.Header closeButton onClick={this.hideModal}></Modal.Header>

            <Modal.Body>
              <img
                className="submit-img"
                src={require("assets/images/confirm-popup.svg")}
              />
              <p className="submit-text">
                {t('moveInInprogressList')}
              </p>
              <div className="modal-footer-btn">
                <button
                  type="submit"
                  className="red-btn"
                  onClick={this.hideModal}
                >
                  {t('closeAUC')}
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            centered
            show={this.state.fcrdelID !== null}
            className="submit-modal"
          >
            <Modal.Header
              closeButton
              onClick={() =>
                this.setState({
                  fcrdelID: null,
                })
              }
            ></Modal.Header>

            <Modal.Body>
              <img
                className="submit-img"
                src={require("assets/images/confirm-popup.svg")}
              />
              <p className="submit-text">
              {t('storeInProposedCorrectiveAction')}
              </p>
              <div className="modal-footer-btn">
                <button
                  type="submit"
                  className="red-btn"
                  onClick={() =>
                    this.setState({
                      fcrdelID: null,
                    })
                  }
                >
                   {t('closeAUC')}
                </button>
                <button
                  type="submit"
                  className="red-btn"
                  onClick={() =>
                    this.debounceFunc(() => {
                      this._removefinalcr(this.state.fcrdelID);
                    })
                  }
                >
                  {t('yesAUC')}, {t('deleteAUC')}
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            centered
            show={this.state.confirmPopup}
            className="submit-modal"
          >
            <Modal.Header closeButton onClick={this.hideModal}></Modal.Header>

            <Modal.Body>
              <img
                className="submit-img"
                src={require("assets/images/confirm-popup.svg")}
                alt=""
              />
              <p className="submit-text">{t('clickConfirmToFreezeTargetDate')}</p>
              <div className="modal-footer-btn">
                <button
                  type="submit"
                  className="red-btn"
                  onClick={this.hideModal}
                >
                  {t('cancelAUC')}
                </button>
                <button
                  type="submit"
                  className="red-btn"
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.submitTargetDate();
                    })
                  }
                  // onClick={() => this.submitTargetDate()}
                >
                  {t('confirmAUC')}
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            className="reportPopup searchPopup"
            centered
            show={this.state.showPopupSearch}
            onHide={this.modalHideSearch}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div>
                <AudioPlayer
                  src={this.state.obs_rec}
                  showJumpControls={true}
                  layout="horizontal-reverse"
                  customVolumeControls={[]}
                  customAdditionalControls={[]}
                  onPlay={(e) => {
                    console.log(e);
                  }}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
}

export default compose(
  withRouter,
  withTranslation(["nm", "util"])
)(NMDetail);
