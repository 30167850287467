/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-mixed-operators */
import { Button, Col, Modal, Row } from "react-bootstrap";
import {
  Calendar,
  Mic,
  MicOff,
  Trash,
  XCircle,
} from "react-feather";
import {
  ErrorToast,
} from "app/shared/components/Popups/ToastMessage";
import {
  SEVERITY as SEVERITYOPTION,
  INCIDENT_TYPE,
  TYPE_ACT,
} from "app/shared/constants/GlobalArray";
import React, { Component } from "react";
import {
  convertDateToUTC,
} from "app/shared/utility/tzUtilFunction";
import AsyncSelect from "react-select/async";
import AudioPlayer from "react-h5-audio-player";
import CommonDropdown from "./CommonDropdown";
import DatePicker from "react-datepicker";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import LowSpace from "app/shared/components/Popups/LowSpace";
import MyDropzone from "app/shared/components/DocumentModal/Dropzone";
import ProjectList from "./ProjectList";
import { ReactMic } from "react-mic";
import ServiceList from "./ServiceList";
import TimePicker from "react-times";
import Url from "app/shared/constants/Url";
import axios from "axios";
import moment from "moment";
import { requiredMessage } from "app/shared/constants/Messages";
import { withRouter } from "react-router-dom";
import SubmitImage from "../../../../assets/images/submit.png";
import { Scrollbars } from "react-custom-scrollbars";
import { ResolvePopup } from "app/shared/components/Popups";
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
const qs = require("qs");

const DEPT_URL = Url.departmentList;
const CONT_URL = Url.contractorList;
const ZONE_URL = Url.zoneList;
const ACT_URL = Url.activitylist;
// const HZD_URL = Url.hazardList;
const BARRIER_URL = Url.barrierList;

const ErrorMessage = <div className="invalid-feedback">Enter valid name</div>;
const ErrorBlock = ({ message }) => (
  <div className="invalid-feedback">{message || ""}</div>
);

export const SEVERITY = [
  {
    id: 4,
    name: "High Critical",
  },
  {
    id: 3,
    name: "High",
  },
  {
    id: 2,
    name: "Medium",
  },
  {
    id: 1,
    name: "Low",
  },
];

class NmReport extends Component {
  constructor(props) {
    super(props);
    const {t} = this.props;
    this.state = {
      formHideField: this.props.hideFieldList,
      reportType: this.props.reportType,
      tempFiles: [],
      serviceList: [],
      projectList: [],
      departmentList: [],
      contractorList: [],
      searchFiledUsers: [],
      zoneList: [],
      searchData: [],
      locationList: [],
      closingRightUserList: [],
      typeActList: TYPE_ACT,
      typeIncidentList: INCIDENT_TYPE,
      activityList: [],
      sourceId: null,
      severityList: this.props.reportType === "nm" ? SEVERITY : SEVERITYOPTION,
      barrierList: [],
      hazardList: [],
      subHazardList: [],
      controlMeasureList: [],
      selectedControlMeasureIds: [],
      clearDropZone: false,
      observationText: "",
      evidenceFileList: [],
      evidenceUrlList: [],
      selectedServiceId: null,
      selectedProjectId: null,
      selectedDepartmentId: null,
      selectedContractorId: null,
      selectedZoneId: null,
      selectedLocationId: null,
      selectedClosingRightUserId: null,
      selectedTypeAct: null,
      selectedActivity: null,
      selectedSeverity: null,
      selectedBarrier: null,
      selectedHazardId: null,
      selectedSubHazardId: null,
      //  selectedControlMeasureId: null,
      selectedIncidentTypeId: 0,
      selectedTargetDate: new Date(),
      maxDatepickerDate: new Date(),
      controlMeasure: "",
      resolveCheckbox: 0,

      selectedIncidentDate: new Date(),
      selectedIncidentTime: "10:00",
      selectedTimeMeridiem: "AM",
      personName: "",
      personNameList: [],
      lat: 0,
      long: 0,
      userData: JSON.parse(localStorage.getItem("userData")),
      observationError: false,
      serviceError: false,
      projectError: false,

      zoneError: false,
      departmentError: false,
      contractorError: false,
      locationError: false,
      closingRightUserError: false,
      resolveError: false,

      typeActError: false,
      activityError: false,
      severityError: false,
      barrierError: false,
      hazardError: false,
      controlMeasureError: false,
      incidentTypeError: false,
      showRecorderModal: false,
      recordAudio: false,
      controlMeasureAudio: "",
      controlMeasureURL: "",
      observationAudio: "",
      observationURL: "",
      recordingFor: "observation",
      voilatorPersonList: [],
      voilatorPersonName: "",
      voilatorId: "",
      voilatorIdList: [],
      sucesssPopup: false,
      reportNumber: null,
      lowStorageMessage: "",
      lowStorageStatus: 200,
      showLowStoragePopup: false,
      showContractorDropdown: true,
      showZoneDropdown: true,
      showLocationDropdown: true,
      selectedFile: "",

      // file:[],
      incidentPersonNameError: false,
      showResolveModal: false,
    };
    this.timeout = 0;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.hideFieldList !== prevState.formHideField) {
      return nextProps.hideFieldList;
    }
    return null;
  }

  _closeSuccessPopup = async () => {
    await this.setState({ sucesssPopup: false });
    await this._clearForm();
    this.props.close();
    this.props.history.push("/");
  };

  _continueReporting = async () => {
    await this.setState({ sucesssPopup: false });
    await this._continueReportingClearForm();
    await this._fetchServiceList();

    await this.setState((prevState) => {
      let { severityList, typeActList, typeIncidentList } = prevState;
      severityList = this.props.reportType === "nm" ? SEVERITY : SEVERITYOPTION;
      typeActList = TYPE_ACT;
      typeIncidentList = INCIDENT_TYPE;

      return { severityList, typeActList, typeIncidentList };
    });
  };

  _getLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState((prevState) => {
          let { lat, long } = prevState;
          lat = position.coords.latitude;
          long = position.coords.longitude;
          return { lat, long };
        });
      });
    }
  };

  componentDidMount = async () => {
    await this._fetchServiceList();
    await this._getLocation();
    this.props.setClick(this._submitSOI);
    // await this.fetchHazrdList();
    // await this.fetchControlMeasureList();
  };

  _dynamicObservationPlaceHolder = () => {
    let text = this.props.t('writeUaucDescription');

    let { reportType } = this.state;

    if (reportType == "nm") {
      text = this.props.t('writeNearMissDescription');
    } else if (reportType == "ira") {
      text = this.props.t('writeIraDescription');
    } else if (reportType == "gp") {
      text = this.props.t('writeGoodPracticesDescription');
    }

    return text;
  };

  _fetchOptionList = async () => {
    const tempUserId = localStorage.getItem("userId");
    const tempToken = localStorage.getItem("token");

    let requestData = {
      auth_key: tempToken,
      user_id: tempUserId,
      service_id: this.state.selectedServiceId,
      project_id: this.state.selectedProjectId,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios
      .all([
        // Department list
        axios.post(DEPT_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Contractor List
        axios.post(CONT_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Zone List
        axios.post(ZONE_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Activity List
        axios.post(ACT_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Hazard List
        // axios.post(HZD_URL, qs.stringify(requestData), {
        //   headers: ContentType,
        // }),
        // Barrier List
        axios.post(BARRIER_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
      ])
      .then(
        axios.spread(
          async (department, contractor, zone, activity, barrier) => {
            await this.setState((prevState) => {
              let {
                contractorList,
                departmentList,
                zoneList,
                // hazardList,
                activityList,
                barrierList,
                dataLoading,
              } = prevState;

              departmentList =
                department.data.status === 200 ? department.data.data : [];
              contractorList =
                contractor.data.status === 200 ? contractor.data.data : [];
              zoneList = zone.data.status === 200 ? zone.data.data : [];
              // hazardList = hazard.data.status === 200 ? hazard.data.data : [];
              activityList =
                activity.data.status === 200 ? activity.data.data : [];
              barrierList =
                barrier.data.status === 200 ? barrier.data.data : [];
              dataLoading = false;
              return {
                contractorList,
                departmentList,
                zoneList,
                // hazardList,
                activityList,
                barrierList,
                dataLoading,
              };
            });
          }
        )
      )
      .catch((error) => {
        this._setDataLoading(false);
      });
      if(this.state.userData?.designation_flag == 6) this._fetchClosingRightUserListPsh();
  };

  fetchHazrdList = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let requestData = {
      auth_key: token,
      user_id: userId,
      activity_id: this.state.selectedActivity,
    };

    let URL = Url.hazardList;

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    await axios
      .post(URL, qs.stringify(requestData), ContentType)
      .then(async (response) => {
        if (response.data.status == 200) {
          await this.setState({
            hazardList: response.data.data,
          });
        }
      })
      .catch(async (error) => {
        await this.setState({
          dataLoading: false,
        });
      });
  };

  fetchControlMeasureList = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let requestData = {
      auth_key: token,
      user_id: userId,
      activity_id: this.state.selectedActivity,
    };

    let URL = Url.controlMeasureList;

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    await axios
      .post(URL, qs.stringify(requestData), ContentType)
      .then(async (response) => {
        if (response.data.status == 200) {
          await this.setState({
            controlMeasureList: response.data.data,
          });
        }
      })
      .catch(async (error) => {
        await this.setState({
          dataLoading: false,
        });
      });
  };

  _setDataLoading = async (status) => {
    await this.setState((prevState) => {
      let { dataLoading } = prevState;
      dataLoading = status;
      return { dataLoading };
    });
  };

  _fetchServiceList = async () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(Url.serviceList);

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { serviceList } = prevState;
              serviceList = response.data.data;
              return { serviceList };
            });
            if (this.state.userData.designation_flag === 4) {
              this.setState(
                {
                  selectedServiceId: response.data.data[0]["service_id"],
                },
                () =>
                  void this._fetchProjectList(
                    response.data.data[0]["service_id"]
                  )
              );
            }
            this._setDataLoading(false);
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _serviceChangeHandle = async (serviceId) => {
    await this.setState((prevState) => {
      let { selectedServiceId, serviceError } = prevState;
      selectedServiceId = serviceId;
      serviceError = serviceId ? false : true;
      return { selectedServiceId, serviceError };
    });
    await this._resetDropdwon();
    this._fetchProjectList(serviceId);
  };

  _resetDropdwon = async (projectReset = true) => {
    await this.setState((prevState) => {
      let {
        selectedHazardId,
        selectedSubHazardId,
        selectedProjectId,
        selectedContractorId,
        selectedDepartmentId,
        selectedActivity,
        selectedLocationId,
        selectedClosingRightUserId,
        selectedZoneId,
        selectedBarrier,
        hazardList,
        subHazardList,
        projectList,
        contractorList,
        departmentList,
        activityList,
        locationList,
        closingRightUserList,
        barrierList,
        zoneList,
        controlMeasureList,
        selectedControlMeasureIds,
      } = prevState;

      if (projectReset) {
        selectedProjectId = null;
        projectList = [];
      }

      selectedHazardId = null;
      selectedSubHazardId = null;
      selectedContractorId = null;
      selectedActivity = null;
      selectedLocationId = null;
      selectedClosingRightUserId = null;
      selectedBarrier = null;
      selectedDepartmentId = null;
      selectedZoneId = null;

      hazardList = [];
      subHazardList = [];
      departmentList = [];
      contractorList = [];
      locationList = [];
      closingRightUserList = [];
      barrierList = [];
      activityList = [];
      zoneList = [];
      controlMeasureList = [];
      selectedControlMeasureIds = [];

      return {
        selectedHazardId,
        selectedProjectId,
        selectedSubHazardId,
        selectedContractorId,
        selectedDepartmentId,
        selectedActivity,
        selectedLocationId,
        selectedZoneId,
        selectedBarrier,
        hazardList,
        subHazardList,
        projectList,
        contractorList,
        departmentList,
        activityList,
        locationList,
        closingRightUserList,
        barrierList,
        zoneList,
        controlMeasureList,
        selectedControlMeasureIds,
      };
    });
  };

  _fetchProjectList = async (serviceId) => {
    const { reportType } = this.state;

    let moduleName = "uauc";
    if (reportType == "soi") {
      moduleName = "uauc";
    } else if (reportType == "nm") {
      moduleName = "near_miss";
    } else if (reportType == "ira") {
      moduleName = "ira";
    }

    let requestData = {
      service_id: serviceId,
      // module_id: moduleId,
      module_name: moduleName,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.projectListForm,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { projectList } = prevState;
              projectList = response.data.data;
              return { projectList };
            });
            if (this.state.userData.designation_flag === 4) {
              const { project_id, contractor_flag, zone_location_flag } =
                response.data.data[0];
              this.setState(
                {
                  selectedProjectId: project_id,
                  // selectedContractorId: contractor_flag,
                  // selectedZoneId: zone_location_flag,
                  showContractorDropdown: Boolean(contractor_flag),
                  showLocationDropdown: Boolean(zone_location_flag),
                  showZoneDropdown: Boolean(zone_location_flag),
                  // selectedLocationId: zone_location_flag
                },
                async () => {
                  await this._fetchOptionList();
                }
              );
            }
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _projectChangeHandle = async (projectId) => {
    if (projectId) {
      await this._resetDropdwon(false);

      await this.setState((prevState) => {
        let {
          selectedProjectId,
          projectError,
          showContractorDropdown,
          showZoneDropdown,
          showLocationDropdown,
          projectList,
        } = prevState;

        // check contractor flag
        let index = projectList.findIndex(
          (item) => projectId == item.project_id
        );
        const isContractor = projectList[index].contractor_flag;
        showContractorDropdown = isContractor == 0 ? false : true;

        const isZone = projectList[index].zone_location_flag;
        showZoneDropdown = isZone == 0 ? false : true;

        const isLocation = projectList[index].zone_location_flag;
        showLocationDropdown = isLocation == 0 ? false : true;

        selectedProjectId = projectId;
        projectError = projectId ? false : true;

        return {
          selectedProjectId,
          projectError,
          showContractorDropdown,
          showZoneDropdown,
          showLocationDropdown,
        };
      });
      await this._fetchOptionList();
    } else {
      await this._resetDropdwon(true);
    }
  };

  _zoneChangeHandle = async (zoneId) => {
    await this.setState((prevState) => {
      let {
        selectedZoneId,
        departmentError,
        contractorError,
        // locationError,
        zoneError,
      } = prevState;
      selectedZoneId = zoneId;
      zoneError = zoneId ? false : true;

      const isFieldShownMapAndValid = {
        isValidDepartment: !!selectedZoneId,
        isValidContactor: !!selectedZoneId,
        // isValidLocation: !!selectedZoneId,
        isValidZone: !!selectedZoneId,

        isShownDepartment: !Object.values(prevState.formHideField).includes(
          "Department"
        ),
        isShownContactor: prevState.showContractorDropdown,
        // isShownLocation: prevState.showLocationDropdown,
        isShownZone: prevState.showZoneDropdown,
      };

      if (isFieldShownMapAndValid.isShownDepartment) {
        departmentError = !selectedZoneId;
      }

      if (isFieldShownMapAndValid.isShownContactor) {
        contractorError = !selectedZoneId;
      }

      // if (isFieldShownMapAndValid.isShownLocation) {
      //   locationError = !selectedZoneId;
      // }

      return {
        selectedZoneId,
        departmentError,
        contractorError,
        // locationError,
        zoneError,
      };
    });

    await this._fetchLocationList();
    // await this._fetchClosingRightUserList();
  };

  _fetchLocationList = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    let requestData = {
      auth_key: token,
      user_id: userId,
      service_id: this.state.selectedServiceId,
      project_id: this.state.selectedProjectId,
      zone_id: this.state.selectedZoneId,
      // zone_id: zoneId,
    };
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.locationList,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { locationList } = prevState;
              locationList = response.data.data;
              return { locationList };
            });
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _fetchSubHazardList = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    let requestData = {
      auth_key: token,
      user_id: userId,
      hazard_Id: this.state.selectedHazardId,
    };
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.subhazardList,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { subHazardList } = prevState;
              subHazardList = response.data.data;
              return { subHazardList };
            });
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _hazardChangeHandle = async (hazardId) => {
    await this.setState((prevState) => {
      let { selectedHazardId, hazardError } = prevState;
      selectedHazardId = hazardId;

      if (this.state.reportType === "soi") {
        hazardError = hazardId ? false : true;
      }

      return { selectedHazardId, hazardError };
    });
    await this._fetchSubHazardList();
  };

  _controlMeasureChangeHandle = async (controlMeasureId) => {
    let text = this.props.t('writeControlMeasure');

    await this.setState((prevState) => {
      let {
        selectedControlMeasureIds,
        controlMeasureError,
        controlMeasureList,
      } = prevState;
      selectedControlMeasureIds.push(
        controlMeasureList.find((item) => item.id == controlMeasureId)
      );

      controlMeasureList = controlMeasureList.filter(
        (item) => item.id != controlMeasureId
      );
      return {
        selectedControlMeasureIds,
        controlMeasureError,
        controlMeasureList,
        text,
      };
    });
  };

  _removeControlMeasure = async (removeId) => {
    let text = this.props.t('writeControlMeasureDescription');
    const updateCmlist = this.state.selectedControlMeasureIds.filter(
      (item) => item.id !== removeId
    );

    this.state.selectedControlMeasureIds.map((data) => {
      if (data.id === removeId) {
        this.setState({
          controlMeasureList: [...this.state.controlMeasureList, data],
        });
      }
      return text;
    });

    this.setState({
      selectedControlMeasureIds: updateCmlist,
    });
  };

  _departmentChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let {
        selectedDepartmentId,
        departmentError,
        contractorError,
        locationError,
        zoneError,
      } = prevState;
      selectedDepartmentId = id;
      departmentError = id ? false : true;
      const isFieldShownMapAndValid = {
        isValidDepartment: !!selectedDepartmentId,
        isValidContactor: !!selectedDepartmentId,
        isValidLocation: !!selectedDepartmentId,
        isValidZone: !!selectedDepartmentId,

        isShownDepartment: !Object.values(prevState.formHideField).includes(
          "Department"
        ),
        isShownContactor: prevState.showContractorDropdown,
        isShownLocation: prevState.showLocationDropdown,
        isShownZone: prevState.showZoneDropdown,
      };

      if (isFieldShownMapAndValid.isShownDepartment) {
        departmentError = !selectedDepartmentId;
      }

      if (isFieldShownMapAndValid.isShownContactor) {
        contractorError = !selectedDepartmentId;
      }

      if (
        isFieldShownMapAndValid.isShownLocation &&
        isFieldShownMapAndValid.isShownZone
      ) {
        locationError = !selectedDepartmentId;
        zoneError = !selectedDepartmentId;
      }

      return {
        selectedDepartmentId,
        departmentError,
        contractorError,
        locationError,
        zoneError,
      };
    });
    if (this.state.userData?.designation_flag != 6)
      await this._fetchClosingRightUserList();
  };

  _locationChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let {
        selectedLocationId,
        departmentError,
        contractorError,
        locationError,
      } = prevState;
      selectedLocationId = id;
      locationError = id ? false : true;

      const isFieldShownMapAndValid = {
        isValidDepartment: !!selectedLocationId,
        isValidContactor: !!selectedLocationId,
        isValidLocation: !!selectedLocationId,
        isValidZone: !!selectedLocationId,

        isShownDepartment: !Object.values(prevState.formHideField).includes(
          "Department"
        ),
        isShownContactor: prevState.showContractorDropdown,
        isShownLocation: prevState.showLocationDropdown,
        isShownZone: prevState.showZoneDropdown,
      };

      if (isFieldShownMapAndValid.isShownDepartment) {
        // departmentError = !prevState.selectedDepartmentId;
        departmentError = !selectedLocationId;
      }

      if (isFieldShownMapAndValid.isShownContactor) {
        //contractorError = !prevState.selectedContractorId;
        contractorError = !selectedLocationId;
      }

      return {
        selectedLocationId,
        locationError,
        departmentError,
        contractorError,
      };
    });
    if (this.state.userData?.designation_flag != 6)
      await this._fetchClosingRightUserList();
  };

  _closingRightUserChangeHandle = async (id) => {
    if (id === "0") {
      this._handleChange(true, "resolve_checkbox");
      return;
    }
    await this.setState((prevState) => {
      let {
        selectedClosingRightUserId,
        closingRightUserError,
        resolveError,
        resolveCheckbox,
        isCheckboxChecked,
      } = prevState;
      selectedClosingRightUserId = id;
      closingRightUserError = false;
      resolveCheckbox = 0;
      resolveError = false;
      isCheckboxChecked = false;
      return {
        selectedClosingRightUserId,
        closingRightUserError,
        resolveError,
        resolveCheckbox,
        isCheckboxChecked
      };
    });
    if (this.state.userData?.designation_flag != 6)
      await this._fetchClosingRightUserList();
  };

  _fetchClosingRightUserList = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    let requestData = {
      auth_key: token,
      user_id: userId,
      service_id: this.state.selectedServiceId,
      project_id: this.state.selectedProjectId,
      // zone_id: this.state.selectedZoneId,
      department_id: this.state.selectedDepartmentId,
      contractor_id: this.state.selectedContractorId,
      location_id: this.state.selectedLocationId,
    };
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.closingRightUserList,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { closingRightUserList } = prevState;
              closingRightUserList = response.data.data;
              return { closingRightUserList };
            });
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _fetchClosingRightUserListPsh = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    let requestData = {
      auth_key: token,
      user_id: userId,
      service_id: this.state.selectedServiceId,
      project_id: this.state.selectedProjectId,
    };
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.closingRightUserListPsh,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { closingRightUserList } = prevState;
              closingRightUserList = [...closingRightUserList, ...response.data.data];
              return { closingRightUserList };
            });
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _subHazardChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedSubHazardId } = prevState;
      selectedSubHazardId = id;
      //subHazardError = id ? false : true;
      return { selectedSubHazardId };
    });
  };

  _barrierChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedBarrier, barrierError } = prevState;
      selectedBarrier = id;

      if (this.state.reportType === "soi") {
        barrierError = id ? false : true;
      }

      return { selectedBarrier, barrierError };
    });
  };

  _activityChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedActivity, activityError } = prevState;
      selectedActivity = id;
      activityError = id ? false : true;
      return { selectedActivity, activityError };
    });

    await this.fetchHazrdList(id);
    await this.fetchControlMeasureList(id);
  };

  _contractorChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let {
        selectedContractorId,
        departmentError,
        contractorError,
        locationError,
        zoneError,
      } = prevState;
      selectedContractorId = id;
      contractorError = id ? false : true;

      const isFieldShownMapAndValid = {
        isValidDepartment: !!selectedContractorId,
        isValidContactor: !!selectedContractorId,
        isValidLocation: !!selectedContractorId,
        isValidZone: !!selectedContractorId,

        isShownDepartment: !Object.values(prevState.formHideField).includes(
          "Department"
        ),
        isShownContactor: prevState.showContractorDropdown,
        isShownLocation: prevState.showLocationDropdown,
        isShownZone: prevState.showZoneDropdown,
      };

      if (isFieldShownMapAndValid.isShownDepartment) {
        departmentError = !selectedContractorId;
      }

      if (isFieldShownMapAndValid.isShownContactor) {
        contractorError = !selectedContractorId;
      }

      if (
        isFieldShownMapAndValid.isShownLocation &&
        isFieldShownMapAndValid.isShownZone
      ) {
        locationError = !selectedContractorId;
        zoneError = !selectedContractorId;
      }

      return {
        selectedContractorId,
        departmentError,
        contractorError,
        locationError,
        zoneError,
      };
    });
    if (this.state.userData?.designation_flag != 6)
      await this._fetchClosingRightUserList();
  };

  _severityChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedSeverity, severityError } = prevState;
      selectedSeverity = id;
      severityError = id ? false : true;
      return { selectedSeverity, severityError };
    });
  };

  _typeactChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedTypeAct, typeActError } = prevState;
      selectedTypeAct = id;
      typeActError = id ? false : true;
      return { selectedTypeAct, typeActError };
    });
  };

  _typeIncidentChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedIncidentTypeId, incidentTypeError } = prevState;
      selectedIncidentTypeId = id;
      incidentTypeError = id ? false : true;
      return { selectedIncidentTypeId, incidentTypeError };
    });
  };

  _evidenceFileHandle = async (event) => {
    var evidence_file = Array.from(event.target.files);
    event.target.value = "";

    const lowStorage = this.checkLowStorage();

    lowStorage
      .then(async (response) => {
        const { status, msg } = response.data;

        if (status == 200) {
          var tempUrl = [];
          var filePromise = new Promise((resolve, reject) => {
            evidence_file.forEach((item, index) => {
              let temVar;

              let reader = new FileReader();
              reader.readAsDataURL(item);
              reader.onloadend = () => {
                tempUrl.push(reader.result);
                if (index === evidence_file.length - 1) {
                  return resolve(1);
                }
              };
            });
          });

          filePromise.then(async () => {
            await this.setState((prevState) => {
              let { evidenceFileList, evidenceUrlList } = prevState;
              const tempArr = evidence_file.map((item) => item);
              evidenceFileList = [...evidenceFileList, ...tempArr];
              evidenceUrlList = [...evidenceUrlList, ...tempUrl];
              return { evidenceFileList, evidenceUrlList };
            });
          });
        }
        await this.setState({
          lowStorageStatus: status,
          lowStorageMessage: msg,
          showLowStoragePopup: status == 200 ? false : true,
        });
      })
      .catch((error) => {});
  };

  _handleChange = async (item = null, type = "observation") => {
    await this.setState((prevState) => {
      switch (type) {
        case "observation":
          let { observationText, observationError, observationURL } = prevState;
          observationText = item;
          observationError = !observationURL && !item ? true : false;
          return { observationText, observationError };
        case "control_measure":
          let { controlMeasure } = prevState;
          controlMeasure = item;
          return { controlMeasure };
        case "target_date":
          let { selectedTargetDate } = prevState;
          selectedTargetDate = item;
          return { selectedTargetDate };
        case "incident_date":
          let { selectedIncidentDate } = prevState;
          selectedIncidentDate = item;
          return { selectedIncidentDate };
        case "person_name":
          let { personName, personNameError } = prevState;
          personName = item;
          personNameError = item ? false : true;
          return { personName, personNameError };
        case "resolve_checkbox":
          let {
            resolveCheckbox,
            selectedClosingRightUserId,
            resolveError,
            closingRightUserError,
            // department, contractor & location
            departmentError,
            contractorError,
            locationError,
            zoneError,
          } = prevState;
          resolveCheckbox = item ? 1 : 0;
          selectedClosingRightUserId = item ? 0 : selectedClosingRightUserId;
          resolveError = false;
          closingRightUserError = false;
          // FOR UA/UC only Validations
          if (item) {
            departmentError = false;
            contractorError = false;
            locationError = false;
            zoneError = false;
          } else {
            const isFieldShownMapAndValid = {
              isValidDepartment: !!prevState.selectedDepartmentId,
              isValidContactor: !!prevState.selectedContractorId,
              isValidLocation: !!prevState.selectedLocationId,
              isValidZone: !!prevState.selectedZoneId,

              isShownDepartment: !Object.values(
                prevState.formHideField
              ).includes("Department"),
              isShownContactor: prevState.showContractorDropdown,
              isShownLocation: prevState.showLocationDropdown,
              isShownZone: prevState.showZoneDropdown,
            };

            if (isFieldShownMapAndValid.isShownDepartment) {
              departmentError = !prevState.selectedDepartmentId;
            }

            if (isFieldShownMapAndValid.isShownContactor) {
              contractorError = !prevState.selectedContractorId;
            }

            if (
              isFieldShownMapAndValid.isShownLocation &&
              isFieldShownMapAndValid.isShownZone
            ) {
              locationError = !prevState.selectedLocationId;
              zoneError = !prevState.selectedZoneId;
            }
          }
          return {
            resolveCheckbox,
            selectedClosingRightUserId,
            resolveError,
            closingRightUserError,

            // department, contractor & location
            departmentError,
            contractorError,
            locationError,
            zoneError,
          };
        default:
          return prevState;
      }
    });
  };

  _removePreviewFile = async (index = 0) => {
    await this.setState((prevState) => {
      let { evidenceFileList, evidenceUrlList } = prevState;
      evidenceFileList.splice(index, 1);
      evidenceUrlList.splice(index, 1);
      return { evidenceFileList, evidenceUrlList };
    });
  };

  checkLowStorage = () => {
    return import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.reportSetting.checkStorage
      );

      return responseObj;
    });
  };

  _handleLowSpace = async (statusFlag) => {
    await this.setState({ showLowStoragePopup: false });
  };

  _formValidation = async () => {
    const tempObj = this.state;

    await this.setState((prevState) => {
      prevState.observationError =
        tempObj.observationText || tempObj.observationURL ? false : true;

      prevState.serviceError = tempObj.selectedServiceId ? false : true;

      prevState.projectError = tempObj.selectedProjectId ? false : true;

      if (
        !Object.values(this.state.formHideField).includes("Type of Act") &&
        this.state.reportType !== "ira"
      ) {
        prevState.typeActError = tempObj.selectedTypeAct ? false : true;
      }

      if (this.state.reportType !== "ira") {
        prevState.severityError = tempObj.selectedSeverity ? false : true;
      }

      prevState.activityError = tempObj.selectedActivity ? false : true;

      // if report type is soi, nm & gp
      // if (this.state.reportType === "soi") {
      if (this.state.resolveCheckbox === 0) {
        // DEPARTMENT/LOCATION/CONTRACTOR/ZONE validation START
        const isFieldShownMapAndValid = {
          isValidDepartment: !!tempObj.selectedDepartmentId,
          isValidContactor: !!tempObj.selectedContractorId,
          isValidLocation: !!tempObj.selectedLocationId,
          isValidZone: !!tempObj.selectedZoneId,

          isShownDepartment: !Object.values(this.state.formHideField).includes(
            "Department"
          ),
          isShownContactor: this.state.showContractorDropdown,
          isShownLocation: this.state.showLocationDropdown,
          isShownZone: this.state.showZoneDropdown,
        };

        if (
          isFieldShownMapAndValid.isShownDepartment &&
          isFieldShownMapAndValid.isShownContactor &&
          isFieldShownMapAndValid.isShownLocation &&
          isFieldShownMapAndValid.isShownZone
        ) {
          // dept, contractor, location
          if (isFieldShownMapAndValid.isValidDepartment) {
            prevState.departmentError =
              !isFieldShownMapAndValid.isValidDepartment;
            prevState.contractorError = false;
            prevState.locationError = false;
            prevState.zoneError = false;
          } else if (isFieldShownMapAndValid.isValidContactor) {
            prevState.contractorError =
              !isFieldShownMapAndValid.isValidContactor;
            prevState.departmentError = false;
            prevState.locationError = false;
            prevState.zoneError = false;
          } else if (
            isFieldShownMapAndValid.isValidZone &&
            isFieldShownMapAndValid.isValidLocation
          ) {
            prevState.departmentError = false;
            prevState.contractorError = false;
            prevState.locationError = !isFieldShownMapAndValid.isValidLocation;
            prevState.zoneError = !isFieldShownMapAndValid.isValidZone;
          } else {
            prevState.departmentError = true;
            prevState.contractorError = true;
            prevState.locationError = true;
            prevState.zoneError = true;
          }
        } else if (
          isFieldShownMapAndValid.isShownDepartment &&
          isFieldShownMapAndValid.isShownContactor
        ) {
          // dept, contractor
          if (isFieldShownMapAndValid.isValidDepartment) {
            prevState.departmentError =
              !isFieldShownMapAndValid.isValidDepartment;
            prevState.contractorError = false;
          } else if (isFieldShownMapAndValid.isValidContactor) {
            prevState.contractorError =
              !isFieldShownMapAndValid.isValidContactor;
            prevState.departmentError = false;
          } else {
            prevState.contractorError = true;
            prevState.departmentError = true;
          }
        } else if (
          isFieldShownMapAndValid.isShownDepartment &&
          isFieldShownMapAndValid.isShownLocation &&
          isFieldShownMapAndValid.isShownZone
        ) {
          // dept, location
          if (isFieldShownMapAndValid.isValidDepartment) {
            prevState.departmentError =
              !isFieldShownMapAndValid.isValidDepartment;
            prevState.locationError = false;
            prevState.zoneError = false;
          } else if (
            isFieldShownMapAndValid.isValidZone &&
            isFieldShownMapAndValid.isValidLocation
          ) {
            prevState.departmentError = false;
            prevState.locationError = !isFieldShownMapAndValid.isValidLocation;
            prevState.zoneError = !isFieldShownMapAndValid.isValidZone;
          } else {
            prevState.departmentError = true;
            prevState.locationError = true;
            prevState.zoneError = true;
          }
        } else if (
          isFieldShownMapAndValid.isShownContactor &&
          isFieldShownMapAndValid.isShownLocation &&
          isFieldShownMapAndValid.isShownZone
        ) {
          // contractor, location
          if (isFieldShownMapAndValid.isValidContactor) {
            prevState.contractorError =
              !isFieldShownMapAndValid.isValidContactor;
            prevState.departmentError = false;
            prevState.locationError = false;
            prevState.zoneError = false;
          } else if (
            isFieldShownMapAndValid.isValidZone &&
            isFieldShownMapAndValid.isValidLocation
          ) {
            prevState.departmentError = false;
            prevState.contractorError = false;
            prevState.locationError = !isFieldShownMapAndValid.isValidLocation;
            prevState.zoneError = !isFieldShownMapAndValid.isValidZone;
          } else {
            prevState.contractorError = true;
            prevState.locationError = true;
            prevState.zoneError = true;
          }
        } else if (isFieldShownMapAndValid.isShownDepartment) {
          // dept
          prevState.departmentError =
            !isFieldShownMapAndValid.isValidDepartment;
        } else if (isFieldShownMapAndValid.isShownContactor) {
          // contractor
          prevState.departmentError = !isFieldShownMapAndValid.isValidContactor;
        } else if (
          isFieldShownMapAndValid.isShownLocation &&
          isFieldShownMapAndValid.isShownZone
        ) {
          // location
          prevState.locationError = !isFieldShownMapAndValid.isValidLocation;
          prevState.zoneError = !isFieldShownMapAndValid.isValidZone;
        }
      } else {
        prevState.departmentError = false;
        prevState.contractorError = false;
        prevState.locationError = false;
        prevState.zoneError = false;
      }
      // DEPARTMENT/LOCATION/CONTRACTOR/ZONE validation END

      if (
        !Object.values(this.state.formHideField).includes("Barrier") &&
        this.state.reportType === "soi"
      ) {
        prevState.barrierError = tempObj.selectedBarrier ? false : true;
      }
      if (this.state.resolveCheckbox == 0 && this.state.reportType === "soi") {
        prevState.closingRightUserError = tempObj.selectedClosingRightUserId
          ? false
          : true;
        console.log(prevState.closingRightUserError);
      } else {
        prevState.closingRightUserError = false;
      }
      if (
        !tempObj.selectedClosingRightUserId &&
        this.state.reportType === "soi"
      ) {
        prevState.resolveError = tempObj.resolveCheckbox ? false : true;
      } else {
        prevState.resolveError = false;
      }
      if (
        !Object.values(this.state.formHideField).includes("Hazard") &&
        this.state.reportType != "ira"
      ) {
        prevState.hazardError = tempObj.selectedHazardId ? false : true;
      }
      // }

      return prevState;
    });
  };

  _uploadFile = async (file) => {
    if (file != null) {
      await this.setState((prevState) => {
        let { selectedFile, tempFiles } = prevState;
        selectedFile = file;
        tempFiles = [...tempFiles, file];
        return { selectedFile, tempFiles };
      });
    }
  };

  _submitSOI = async () => {
    await this._formValidation();

    // Checking form have any Error
    let formErrorStatus;
    for (var propertyName in this.state) {
      if (propertyName.includes("Error")) {
        if (this.state[propertyName]) {
          console.log(propertyName);
          formErrorStatus = true;
          return;
        } else {
          formErrorStatus = false;
        }
      }
    }

    // If form not have error then submit form
    if (!formErrorStatus) {
      // this.props.parentStateSetter(({
      //   apiLoading:true
      // }));
      if (this.state.reportType == "ira") {
        if (this.state.voilatorId !== "" && this.state.personName === "") {
          ErrorToast(this.props.t('pleaseEnterTheInjuredEmployeeName'));
          return false;
        } else if (
          this.state.voilatorId === "" &&
          this.state.personName !== ""
        ) {
          ErrorToast(this.props.t('pleaseEnterTheInjuredPersonID'));

          return false;
        }
      }

      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");

      await this.setState({ dataLoading: true });

      this._setDataLoading(true);

      let soiData = new FormData();
      let targetDate;
      let incidentDate;

      soiData.append("auth_key", token);
      soiData.append("user_id", userId);
      soiData.append(
        "service_id",
        this.state.selectedServiceId ? this.state.selectedServiceId : 0
      );
      soiData.append(
        "project_id",
        this.state.selectedProjectId ? this.state.selectedProjectId : 0
      );
      soiData.append(
        "type_of_act",
        this.state.selectedTypeAct ? this.state.selectedTypeAct : 0
      );

      if (this.state.reportType !== "ira") {
        soiData.append(
          "severity",
          this.state.selectedSeverity ? this.state.selectedSeverity : 0
        );
      }

      soiData.append(
        "activity_id",
        this.state.selectedActivity ? this.state.selectedActivity : 0
      );
      soiData.append(
        "hazard_id",
        this.state.selectedHazardId ? this.state.selectedHazardId : 0
      );

      this.state.selectedControlMeasureIds.forEach((data) => {
        soiData.append("control_measure_id", data.id);
      });

      soiData.append(
        "zone_id",
        this.state.selectedZoneId ? this.state.selectedZoneId : 0
      );
      soiData.append(
        "location_id",
        this.state.selectedLocationId ? this.state.selectedLocationId : 0
      );
      soiData.append(
        "department_id",
        this.state.selectedDepartmentId ? this.state.selectedDepartmentId : 0
      );
      soiData.append(
        "contractor_id",
        this.state.selectedContractorId ? this.state.selectedContractorId : 0
      );
      if (this.state.reportType === "soi") {
        soiData.append(
          "closing_right_user_id",
          this.state.selectedClosingRightUserId
            ? this.state.selectedClosingRightUserId
            : 0
        );
        if (this.state.sourceId !== null) {
          soiData.append("source", this.state.sourceId);
        }
      }
      soiData.append(
        "observation",
        this.state.observationText ? this.state.observationText : ""
      );
      if (this.state.searchFiledUsers.length) {
        this.state.searchFiledUsers.forEach((el) => {
          soiData.append("incident_person_id", el.employeeId);
          soiData.append("searched_incident_person_id", el.incident_person_id);
          soiData.append("incident_person_name", el?.incident_person_name);
        });
      }
      if (this.state.voilatorIdList.length) {
        this.state.voilatorIdList.forEach((el) => {
          soiData.append("incident_person_id", el);
        });
      }
      if (this.state.reportType === "soi") {
        const { voilatorPersonList } = this.state;
        voilatorPersonList.forEach((el) => {
          soiData.append("incident_person_name", el);
        });
        if (!Object.values(this.state.formHideField).includes("Target Date")) {
          targetDate = convertDateToUTC(
            this.state.selectedTargetDate,
            "YYYY-MM-DD"
          );
        } else {
          targetDate = "";
        }

        soiData.append("target_date", targetDate);
        soiData.append(
          "control_measure",
          this.state.controlMeasure ? this.state.controlMeasure : ""
        );
        soiData.append("self_closing", this.state.resolveCheckbox ? 1 : 0);
        soiData.append(
          "sub_hazard_id",
          this.state.selectedSubHazardId ? this.state.selectedSubHazardId : 0
        );
        soiData.append(
          "barrier_id",
          this.state.selectedBarrier ? this.state.selectedBarrier : 0
        );
      }

      soiData.append("lat", this.state.lat);
      soiData.append("lang", this.state.long);

      if (this.state.reportType === "soi") {
        soiData.append("observation_file", this.state.observationAudio);
      }

      if (this.state.reportType === "nm" || this.state.reportType === "ira") {
        soiData.append("observation_record", this.state.observationAudio);
      }

      // UAUC report only have Control measure
      if (this.state.reportType === "soi") {
        soiData.append("control_measure_file", this.state.controlMeasureAudio);
      }

      if (this.state.reportType === "nm") {
        if (
          !Object.values(this.state.formHideField).includes("Incident Date")
        ) {
          incidentDate = convertDateToUTC(
            this.state.selectedIncidentDate,
            "YYYY-MM-DD"
          );
        } else {
          incidentDate = "";
        }

        let tempPersonList = [...this.state.personNameList];
        if (tempPersonList.length) {
          tempPersonList.map((personItem) => {
            soiData.append("incident_person_name", personItem);
          });
        } else {
          soiData.append("incident_person_name", "");
        }

        soiData.append("incident_time", this.state.selectedIncidentTime);
        soiData.append("incident_date", incidentDate);
      }

      if (this.state.reportType === "ira") {
        if (
          !Object.values(this.state.formHideField).includes("Incident Date")
        ) {
          incidentDate = convertDateToUTC(
            this.state.selectedIncidentDate,
            "YYYY-MM-DD"
          );
        } else {
          incidentDate = "";
        }
        soiData.append("incident_time", this.state.selectedIncidentTime);
        soiData.append("incident_date", incidentDate);

        let tempPersonList = [...this.state.personNameList];

        if (tempPersonList.length) {
          tempPersonList.map((personItem) => {
            soiData.append("incident_person_name", personItem);
          });
        } else {
          soiData.append("incident_person_name", "");
        }

        soiData.append(
          "incident_type",
          this.state.selectedIncidentTypeId
            ? this.state.selectedIncidentTypeId
            : 0
        );
      }

      // Evidence File
      if (this.state.tempFiles.length) {
        this.state.tempFiles.forEach((el) => {
          soiData.append("file", el || "");
        });
      }

      soiData.append("file_status", this.state.selectedFile ? 1 : 0);

      var ContentType = {
        "Content-Type": "multipart/form-data",
      };

      let URL = Url.createReport.soiReport;
      switch (this.state.reportType) {
        case "nm":
          URL = Url.createReport.nmReport;
          break;
        case "ira":
          URL = Url.createReport.iraReport;
          break;
        default:
          URL = Url.createReport.soiReport;
          break;
      }

      // Display the key/value pairs
      for (var pair of soiData.entries()) {
        console.log("soiData here", pair[0] + ", " + pair[1]);
      }
      await axios
        .post(URL, soiData, ContentType)
        .then(async (response) => {
          this.setState({
            clearDropZone: true,
          });
          this._setDataLoading(false);
          if (response.data.status === 200) {
            await this._continueReportingClearForm();
            await this.setState({
              reportNumber: response.data.report_number,
              sucesssPopup: true,
              selectedFile: null,
            });
            if(this.state.isCheckboxChecked){
              await this.setState({
                showResolveModal : true,
                reportId: response.data.report_id,
              });
            }
          } else {
            if (response.data.status !== 200) {
              ErrorToast(response.data?.msg);
            }
            await this._continueReportingClearForm();
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        })
        // .finally(() =>{
        //   this.props.parentStateSetter(({
        //     apiLoading:false
        //   }));
        // })
    }
  };

  _showRecorder = async (flag = false, type = "observation") => {
    if (flag) {
      const lowStorage = this.checkLowStorage();
      lowStorage
        .then(async (response) => {
          const { status, msg } = response.data;

          if (status == 200) {
            await this.setState((prevState) => {
              let { showRecorderModal, recordingFor } = prevState;
              showRecorderModal = flag;
              recordingFor = type;
              return { showRecorderModal, recordingFor };
            });
          }
          await this.setState({
            lowStorageStatus: status,
            lowStorageMessage: msg,
            showLowStoragePopup: status == 200 ? false : true,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await this.setState((prevState) => {
        let { showRecorderModal, recordingFor } = prevState;
        showRecorderModal = flag;
        recordingFor = type;
        return { showRecorderModal, recordingFor };
      });
    }
  };

  startRecording = async () => {
    await this.setState({ recordAudio: true });
  };

  stopRecording = async () => {
    await this.setState({ recordAudio: false });
  };

  onStop = async (recordedBlob) => {
    let promise = new Promise((resolve, error) => {
      let reader = new FileReader();
      reader.readAsDataURL(recordedBlob.blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });

    promise.then(async (response) => {
      await this.setState((prevState) => {
        let {
          controlMeasureAudio,
          controlMeasureURL,
          observationAudio,
          observationURL,
        } = prevState;
        if (this.state.recordingFor === "control_measure") {
          controlMeasureAudio = recordedBlob.blob;
          controlMeasureURL = response;
        }
        if (this.state.recordingFor === "observation") {
          observationAudio = recordedBlob.blob;
          observationURL = response;

          console.log(observationURL, observationAudio);
        }
        return {
          controlMeasureAudio,
          controlMeasureURL,
          observationAudio,
          observationURL,
        };
      });
    });
  };

  _setDataLoading = async (status) => {
    await this.setState((prevState) => {
      let { dataLoading } = prevState;
      dataLoading = status;
      return { dataLoading };
    });
  };

  _removeAudioFile = async (type) => {
    await this.setState((prevState) => {
      if (type === "observation") {
        prevState.observationAudio = "";
        prevState.observationURL = "";
        return prevState;
      }

      if (type === "control_measure") {
        prevState.controlMeasureAudio = "";
        prevState.controlMeasureURL = "";
        return prevState;
      }
    });
  };

  _onTimeChange = async (options) => {
    const { hour, minute, meridiem } = options;

    const time = `${hour}:${minute}:00 ${meridiem}`;
    const formattedTime = moment(time, ["h:mm A"]).format("HH:mm");

    await this.setState({
      selectedIncidentTime: formattedTime,
      selectedTimeMeridiem: meridiem,
    });
  };

  _reportType = () => {
    if (this.state.reportType == "nm") {
      return "NM";
    } else if (this.state.reportType == "ira") {
      return "IRA";
    } else {
      return "UA/UC";
    }
  };

  _clearForm = async () => {
    await this.setState((prevState) => {
      prevState.typeActList = TYPE_ACT;
      prevState.typeIncidentList = INCIDENT_TYPE;
      prevState.severityList =
        this.props.reportType === "nm" ? SEVERITY : SEVERITYOPTION;
      prevState.projectList = [];
      prevState.departmentList = [];
      prevState.contractorList = [];
      prevState.zoneList = [];
      prevState.locationList = [];
      prevState.closingRightUserList = [];
      prevState.hazardList = [];
      prevState.activityList = [];
      prevState.barrierList = [];
      prevState.subHazardList = [];
      prevState.controlMeasureList = [];
      prevState.clearDropZone = false;
      prevState.observationText = "";
      prevState.evidenceFileList = [];
      prevState.evidenceUrlList = [];
      prevState.selectedServiceId =
        this.state.userData.designation_flag === 4
          ? prevState.selectedServiceId
          : null;
      prevState.selectedProjectId =
        this.state.userData.designation_flag === 4
          ? prevState.selectedProjectId
          : null;
      prevState.selectedDepartmentId = null;
      prevState.selectedContractorId = null;
      prevState.selectedZoneId = null;
      prevState.selectedLocationId = null;
      prevState.selectedClosingRightUserId = null;
      prevState.selectedTypeAct = null;
      prevState.selectedActivity = null;
      prevState.selectedSeverity = null;
      prevState.selectedBarrier = null;
      prevState.selectedHazardId = null;
      prevState.selectedSubHazardId = null;

      prevState.selectedControlMeasureIds = [];
      prevState.selectedIncidentTypeId = 0;

      prevState.selectedTargetDate = new Date();
      prevState.controlMeasure = "";
      prevState.resolveCheckbox = false;
      prevState.soiFormHidingField = {};
      prevState.nmFormHidingField = {};
      prevState.iraFormHidingField = {};

      prevState.serviceError = false;
      prevState.projectError = false;

      prevState.zoneError = false;
      prevState.departmentError = false;
      prevState.contractorError = false;
      prevState.locationError = false;
      prevState.closingRightUserError = false;
      prevState.resolveError = false;

      prevState.typeActError = false;
      prevState.activityError = false;
      prevState.severityError = false;
      prevState.barrierError = false;
      prevState.hazardError = false;
      prevState.observationError = false;
      prevState.controlMeasureError = false;

      prevState.showRecorderModal = false;
      prevState.recordAudio = false;
      prevState.controlMeasureAudio = "";
      prevState.controlMeasureURL = "";
      prevState.observationAudio = "";
      prevState.observationURL = "";
      prevState.recordingFor = "observation";

      prevState.selectedIncidentTime = "10:00";
      prevState.selectedIncidentDate = new Date();
      prevState.selectedTimeMeridiem = "AM";
      prevState.personName = "";

      return prevState;
    });
  };

  _continueReportingClearForm = async () => {
    await this.setState((prevState) => {
      prevState.typeActList = TYPE_ACT;
      prevState.typeIncidentList = INCIDENT_TYPE;
      prevState.severityList =
        this.props.reportType === "nm" ? SEVERITY : SEVERITYOPTION;

      prevState.observationText = "";
      prevState.evidenceFileList = [];
      prevState.evidenceUrlList = [];
      prevState.selectedServiceId = null;
      prevState.selectedProjectId = null;
      prevState.selectedDepartmentId = null;
      prevState.selectedContractorId = null;
      prevState.selectedZoneId = null;
      prevState.selectedLocationId = null;
      prevState.selectedClosingRightUserId = null;
      prevState.selectedTypeAct = null;
      prevState.selectedActivity = null;
      prevState.selectedSeverity = null;
      prevState.selectedBarrier = null;
      prevState.selectedHazardId = null;
      prevState.selectedSubHazardId = null;
      prevState.selectedControlMeasureIds = [];
      prevState.selectedIncidentTypeId = 0;
      prevState.sourceId = null;
      prevState.selectedTargetDate = new Date();
      prevState.controlMeasure = "";
      prevState.resolveCheckbox = false;
      prevState.soiFormHidingField = {};
      prevState.nmFormHidingField = {};
      prevState.iraFormHidingField = {};
      prevState.voilatorId = null;
      prevState.voilatorPersonName = null;
      prevState.voilatorPersonList = [];
      prevState.voilatorIdList = [];
      prevState.tempFiles = [];
      prevState.serviceError = false;
      prevState.projectError = false;

      prevState.zoneError = false;
      prevState.departmentError = false;
      prevState.contractorError = false;
      prevState.locationError = false;
      prevState.closingRightUserError = false;
      prevState.resolveError = false;

      prevState.typeActError = false;
      prevState.activityError = false;
      prevState.severityError = false;
      prevState.barrierError = false;
      prevState.hazardError = false;
      prevState.observationError = false;

      prevState.showRecorderModal = false;
      prevState.recordAudio = false;
      prevState.controlMeasureAudio = "";
      prevState.controlMeasureURL = "";
      prevState.observationAudio = "";
      prevState.observationURL = "";
      prevState.recordingFor = "observation";

      prevState.selectedIncidentTime = "10:00";
      prevState.selectedIncidentDate = new Date();
      prevState.selectedTimeMeridiem = "AM";
      prevState.personName = "";

      // clearing dropdown values
      prevState.serviceList = [];
      prevState.projectList = [];
      prevState.departmentList = [];
      prevState.contractorList = [];
      prevState.zoneList = [];
      prevState.ocationList = [];
      prevState.closingRightUserList = [];
      prevState.barrierList = [];
      prevState.hazardList = [];
      prevState.subHazardList = [];
      prevState.activityList = [];
      prevState.personNameList = [];
      prevState.typeActList = [];
      prevState.typeIncidentList = [];
      prevState.severityList = [];
      prevState.locationList = [];
      prevState.controlMeasureList = [];

      prevState.resolveCheckbox = 0;
      prevState.selectedFile = null;

      return prevState;
    });
  };

  _addPersonName = async () => {
    if (this.state.personName) {
      await this.setState((prevState) => {
        let { personName, personNameList, incidentPersonNameError } = prevState;
        personNameList = [...personNameList, personName];
        personName = "";
        incidentPersonNameError = false;
        return { personName, personNameList, incidentPersonNameError };
      });
    } else {
      ErrorToast(this.props.t('enterValidInjuredEmployeeName'));
    }
  };

  _removePersonName = async (position) => {
    await this.setState((prevState) => {
      let { personNameList } = prevState;
      personNameList.splice(position, 1);
      return { personNameList };
    });
  };

  _getDynamicAboutLabel = () => {
    switch (this.state.reportType) {
      case "nm":
        return this.props.t('nmDetails');
      case "soi":
        return this.props.t('uaucDetails');
      case "ira":
        return this.props.t('incidentDetails');
      case "gp":
        return this.props.t('goodPracticesDetails');
      default:
        return this.props.t('uaucDetails');
    }
  };

  _getDynamicPersonNameLabel = () => {
    switch (this.state.reportType) {
      case "nm":
        return this.props.t('employeeName');
      case "ira":
        return this.props.t('injuredEmployeeName');
      default:
        return this.props.t('employeeName');
    }
  };
  _manageVoilatorState = (val, type) => {
    const { voilatorIdList, voilatorPersonList } = this.state;
    const temp = Array.from(type === 2 ? voilatorIdList : voilatorPersonList);
    temp.push(val);
    this.setState({
      [type === 2 ? "voilatorIdList" : "voilatorPersonList"]: temp,
      [type === 2 ? "voilatorId" : "voilatorPersonName"]: "",
    });
  };

  removeVoilator = (voilatorId, type = 1) => {
    const { voilatorIdList, voilatorPersonList } = this.state;
    const temp = [
      ...Array.from(type === 2 ? voilatorIdList : voilatorPersonList),
    ];
    temp.splice(voilatorId, 1);
    this.setState({
      [type === 2 ? "voilatorIdList" : "voilatorPersonList"]: temp,
    });
  };
  searchEmployee = async (inputString, callback) => {
    const contentType = { "Content-Type": "application/x-www-form-urlencoded" };
    await axios
      .post(
        Url.userSearch,
        qs.stringify({
          service_id: this.state.selectedServiceId,
          auth_key: localStorage.getItem("token"),
          project_id: this.state.selectedProjectId,
          user_id: localStorage.getItem("userId"),
          search_user_name: inputString,
        }),
        contentType
      )
      .then(async (response) => {
        if (response.data.status === 200) {
          callback(response.data);
          await this.setState({
            searchData: { ...this.state.searchData, ...response.data },
          });
        }
      })
      .catch((error) => {});
  };
  render() {
    const {t} = this.props;
    const isOberserver = this.state.userData.designation_flag === 4;
    console.log("current Uploads changes", this.state.selectedFile);
    console.log("state",this.state)
    return (
      <>
        {/* Data Loading  */}
        <div
          className="loader"
          style={{ display: this.state.dataLoading ? "block" : "none" }}
        >
          <LoadingImage />
        </div>
        <div className="rd-content">
          <div className="rd-left-content">
            <div className="rd-box">
              <Row>
                <Col>
                  <div className="form-group">
                    {/* <label className="form-label">Image/Video</label> */}
                    <div className="padding-l-r">
                      <MyDropzone
                        type="5"
                        disabledPlaceHolder
                        uploadFile={this._uploadFile}
                        clearDropZone={this.state.clearDropZone}
                      />
                    </div>
                  </div>

                  <div
                    className={`slds-form-element padding-l-r ${
                      this.state.observationError ? "slds-has-error" : ""
                    }`}
                  >
                    <div className="rd-box-head">
                      <div className="with-mic">
                        <label className="slds-form-element__label">
                          {this.state.reportType != "soi"
                            ? t('incidentDescription')
                            : t('observation')}
                          <abbr className="slds-required" title="required">
                            *{" "}
                          </abbr>
                        </label>
                      </div>
                      {this.state.observationURL ? (
                        <div className="audioPreviewer mt-2">
                          <AudioPlayer
                            src={this.state.observationURL}
                            showJumpControls={true}
                            layout="horizontal-reverse"
                            customVolumeControls={[]}
                            customAdditionalControls={[]}
                            style={{
                              width: "600px",
                            }}
                          />
                          <Button
                            variant="outline-primary"
                            onClick={() => this._removeAudioFile("observation")}
                          >
                            X
                          </Button>
                        </div>
                      ) : null}
                    </div>
                    <div className="slds-form-element__control">
                      <textarea
                        className="slds-textarea"
                        onChange={(event) =>
                          this._handleChange(event.target.value, "observation")
                        }
                        value={this.state.observationText}
                        placeholder={this._dynamicObservationPlaceHolder()}
                      ></textarea>
                    </div>

                    {!!this.state.observationError && (
                      <div class="slds-form-element__help" id="error-01">
                        {requiredMessage}
                      </div>
                    )}
                  </div>
                  <div>
                    {!isOberserver ? (
                      <Row>
                        <Col>
                          <ServiceList
                            optionList={this.state.serviceList}
                            onChangeHandle={this._serviceChangeHandle}
                            value={this.state.selectedServiceId}
                            hasError={this.state.serviceError}
                          />
                          {/* {!!this.state.serviceError && (
                          <p className="inputErrorMsg">{requiredMessage}</p>
                        )} */}
                        </Col>
                        <Col>
                          <ProjectList
                            optionList={this.state.projectList}
                            onChangeHandle={this._projectChangeHandle}
                            value={this.state.selectedProjectId}
                            hasError={this.state.projectError}
                          />
                          {/* {!!this.state.projectError && (
                          <p className="inputErrorMsg">{requiredMessage}</p>
                        )} */}
                        </Col>
                      </Row>
                    ) : null}
                  </div>
                  <div>
                    {/* <div className="rd-box-head">
                      <h3>Department/Contractor/Location (Select any one or all)</h3>
                    </div> */}
                    <Row>
                      {!!!Object.values(this.state.formHideField).includes(
                        "Department"
                      ) && (
                        <Col sm={6}>
                          <CommonDropdown
                            title={t('department')}
                            optionList={this.state.departmentList}
                            onChangeHandle={this._departmentChangeHandle}
                            isRequired={true}
                            value={this.state.selectedDepartmentId}
                            hasError={this.state.departmentError}
                          />
                          {/* {!!this.state.departmentError && (
                              <p className="inputErrorMsg">{requiredMessage}</p>
                            )} */}
                        </Col>
                      )}
                      {!!(
                        this.state.showContractorDropdown &&
                        !Object.values(this.state.formHideField).includes(
                          "Contractor"
                        )
                      ) && (
                        <Col sm={6}>
                          <CommonDropdown
                            title={t('contractor')}
                            optionList={this.state.contractorList}
                            onChangeHandle={this._contractorChangeHandle}
                            isRequired={true}
                            value={this.state.selectedContractorId}
                            hasError={this.state.contractorError}
                          />
                          {/* {!!this.state.contractorError && (
                              <p className="inputErrorMsg">{requiredMessage}</p>
                            )} */}
                        </Col>
                      )}

                      {!!(
                        this.state.showZoneDropdown &&
                        !Object.values(this.state.formHideField).includes(
                          "Zone"
                        ) &&
                        !Object.values(this.state.formHideField).includes(
                          "zone"
                        )
                      ) && (
                        <Col sm={6}>
                          <CommonDropdown
                            title={t('zone')}
                            optionList={this.state.zoneList}
                            onChangeHandle={this._zoneChangeHandle}
                            isRequired={true}
                            value={this.state.selectedZoneId}
                            hasError={this.state.zoneError}
                          />
                          {/* {!!this.state.zoneError && (
                              <p className="inputErrorMsg">{requiredMessage}</p>
                            )} */}
                        </Col>
                      )}
                      {!!(
                        this.state.showLocationDropdown &&
                        !Object.values(this.state.formHideField).includes(
                          "Location"
                        ) &&
                        !Object.values(this.state.formHideField).includes(
                          "location"
                        )
                      ) && (
                        <Col sm={6}>
                          <CommonDropdown
                            title={t('location')}
                            optionList={this.state.locationList}
                            onChangeHandle={this._locationChangeHandle}
                            isRequired={true}
                            value={this.state.selectedLocationId}
                            hasError={this.state.locationError}
                          />
                          {/* {!!this.state.locationError && (
                              <p className="inputErrorMsg">{requiredMessage}</p>
                            )} */}
                        </Col>
                      )}
                    </Row>
                  </div>
                </Col>
                <Col>
                  <div>
                    {/* <div className="rd-box-head">
                      <h3>{this._getDynamicAboutLabel()}</h3>
                    </div> */}
                    <Row>
                      {!!(
                        this.state.reportType != "ira" &&
                        !Object.values(this.state.formHideField).includes(
                          "Type of Act"
                        )
                      ) && (
                        <Col col={6}>
                          <CommonDropdown
                            title={
                              this.state.reportType == "soi"
                                ? t('uaucType')
                                : t('typeOfAct')
                            }
                            optionList={this.state.typeActList}
                            onChangeHandle={this._typeactChangeHandle}
                            value={this.state.selectedTypeAct}
                            hasError={this.state.typeActError}
                          />
                          {/* {!!this.state.typeActError && (
                              <p className="inputErrorMsg">{requiredMessage}</p>
                            )} */}
                        </Col>
                      )}
                      <Col sm={6}>
                        <CommonDropdown
                          title={t('activity')}
                          optionList={this.state.activityList}
                          onChangeHandle={this._activityChangeHandle}
                          value={this.state.selectedActivity}
                          hasError={this.state.activityError}
                        />
                        {/* {!!this.state.activityError && (
                          <p className="inputErrorMsg">{requiredMessage}</p>
                        )} */}
                      </Col>

                      {this.state.reportType != "ira" ? (
                        <Col sm={6}>
                          <CommonDropdown
                            title={t('riskLevel')}
                            optionList={this.state.severityList}
                            onChangeHandle={this._severityChangeHandle}
                            value={this.state.selectedSeverity}
                            hasError={this.state.severityError}
                          />
                          {/* {!!this.state.severityError && (
                            <p className="inputErrorMsg">{requiredMessage}</p>
                          )} */}
                        </Col>
                      ) : null}

                      {!!(
                        this.state.reportType == "soi" &&
                        !Object.values(this.state.formHideField).includes(
                          "Barrier"
                        )
                      ) && (
                        <Col sm={6}>
                          <CommonDropdown
                            title={t('barrierImmediateCause')}
                            customPlaceHolder="Immediate Cause"
                            optionList={this.state.barrierList}
                            onChangeHandle={this._barrierChangeHandle}
                            value={this.state.selectedBarrier}
                            hasError={this.state.barrierError}
                          />
                          {/* {!!this.state.barrierError && (
                              <p className="inputErrorMsg">{requiredMessage}</p>
                            )} */}
                        </Col>
                      )}

                      {!!(
                        this.state.reportType != "ira" &&
                        !Object.values(this.state.formHideField).includes(
                          "Hazard"
                        )
                      ) && (
                        <Col sm={6}>
                          <CommonDropdown
                            title={t('hazard')}
                            optionList={this.state.hazardList}
                            onChangeHandle={this._hazardChangeHandle}
                            isRequired
                            value={this.state.selectedHazardId}
                            hasError={this.state.hazardError}
                          />
                          {/* {!!this.state.hazardError && (
                              <p className="inputErrorMsg">{requiredMessage}</p>
                            )} */}
                        </Col>
                      )}

                      {!!(
                        this.state.reportType == "soi" &&
                        !Object.values(this.state.formHideField).includes(
                          "Sub Hazard"
                        )
                      ) && (
                        <Col sm={6}>
                          <CommonDropdown
                            title={t('subHazard')}
                            optionList={this.state.subHazardList}
                            onChangeHandle={this._subHazardChangeHandle}
                            isRequired={false}
                            value={this.state.selectedSubHazardId}
                          />
                        </Col>
                      )}
                      {this.state.reportType !== "soi" && (
                        <>
                          {!!(this.state.reportType === "ira") && (
                            <Col sm={6}>
                              <CommonDropdown
                                title={t('incidentType')}
                                optionList={this.state.typeIncidentList}
                                onChangeHandle={this._typeIncidentChangeHandle}
                                isRequired={false}
                                value={this.state.selectedIncidentTypeId}
                              />
                            </Col>
                          )}
                          {!!!Object.values(this.state.formHideField).includes(
                            "Incident Date"
                          ) && (
                            <Col sm={6}>
                              <div>
                                <label className="slds-form-element__label">
                                  {t('incidentDate')}
                                </label>
                                <div className="calender-div">
                                  <DatePicker
                                    selected={this.state.selectedIncidentDate}
                                    maxDate={this.state.maxDatepickerDate}
                                    onChange={(jsDate) =>
                                      this._handleChange(
                                        jsDate,
                                        "incident_date"
                                      )
                                    }
                                  />
                                  <Calendar className="calender-icon" />
                                </div>
                              </div>
                            </Col>
                          )}

                          {!!!Object.values(this.state.formHideField).includes(
                            "Incident Time"
                          ) && (
                            <Col sm={6}>
                              <div>
                                <label className="slds-form-element__label">
                                  {t('incidentTime')}
                                </label>
                                <div className="calender-div slds-form-element__control">
                                  <TimePicker
                                    timeMode="12"
                                    onTimeChange={(timeOptions) =>
                                      this._onTimeChange(timeOptions)
                                    }
                                    time={this.state.selectedIncidentTime}
                                    closeOnOutsideClick={false}
                                    meridiem={this.state.selectedTimeMeridiem}
                                  />
                                </div>
                              </div>
                            </Col>
                          )}

                          {!!!Object.values(this.state.formHideField).includes(
                            this.state.reportType === "nm"
                              ? t('person')
                              : t('nameOfInjuredperson')
                          ) && (
                            <Col sm={6} className="my-2">
                              <div className="slds-form-element">
                                <label className="slds-form-element__label">
                                  {this._getDynamicPersonNameLabel()}
                                </label>
                                <div className="d-flex slds-form-element__control">
                                  <input
                                    className={`slds-input col-lg-10 ${
                                      this.state.incidentPersonNameError
                                        ? "is-invalid"
                                        : "border"
                                    }`}
                                    value={this.state.personName}
                                    onChange={(event) =>
                                      this._handleChange(
                                        event.target.value,
                                        "person_name"
                                      )
                                    }
                                    placeholder={`${t('addMultiple')} ${
                                      this.state.reportType == "nm"
                                        ? t('employeeName')
                                        : t('injuredEmployeeName')
                                    } `}
                                  />

                                  <ErrorBlock
                                    message={t('enterAtLeastOneIncidentPerosonName')}
                                  />

                                  {/* <img src={require("assets/sls-icons/V2. New Development/ADD2.svg")} className=" ml-2 cursor-pointer svg-icons" onClick={this._addPersonName} /> */}
                                </div>
                                {this.state.personNameList.map(
                                  (personItem, personIndex) => (
                                    <div
                                      className="d-flex justify-content-between mt-2"
                                      key={`person-${personIndex}`}
                                    >
                                      <span>{personItem}</span>
                                      {/* <XCircle
                                          className="cursor-pointer"
                                          onClick={() =>
                                            this._removePersonName(personIndex)
                                          }
                                        /> */}
                                    </div>
                                  )
                                )}
                              </div>
                            </Col>
                          )}
                        </>
                      )}
                      {this.state.reportType == "soi" ? (
                        <Col sm={6} className="my-2">
                          {!!!Object.values(this.state.formHideField).includes(
                            "Person"
                          ) ? (
                            <div className="slds-form-element">
                              <label className="slds-form-element__label">
                                {t('violatorName')}
                              </label>
                              <div className="d-flex slds-form-element__control">
                                <input
                                  className={`slds-input col-lg-12 custom-input`}
                                  value={this.state.voilatorPersonName}
                                  onChange={(event) =>
                                    this.setState({
                                      voilatorPersonName: event.target.value,
                                    })
                                  }
                                  placeholder={t('enterViolatorName')}
                                />

                                <ErrorBlock
                                  message={t('enterAtLeastOneIncidentPerosonName')}
                                />

                                {/* <img src={require("assets/sls-icons/V2. New Development/ADD2.svg")} className=" ml-2 cursor-pointer svg-icons" onClick={() => this.state.voilatorPersonName !== "" && this._manageVoilatorState(this.state.voilatorPersonName, 1)} /> */}
                              </div>
                              {this.state.voilatorPersonList.map(
                                (personItem, personIndex) => (
                                  <div
                                    className="d-flex justify-content-between mt-2"
                                    key={`person-${personIndex}`}
                                  >
                                    <span>{personItem}</span>
                                  </div>
                                )
                              )}
                            </div>
                          ) : null}
                        </Col>
                      ) : null}

                      {!!!Object.values(this.state.formHideField).includes(
                        "Person Id"
                      ) ? (
                        <Col sm={6} className="my-2">
                          <div className="slds-form-element">
                            <label className="slds-form-element__label">
                              {this.state.reportType == "soi" ? t('violator') : ""}{" "}
                              {this.state.reportType == "nm" ? t('employee') : ""}{" "}
                              {this.state.reportType == "ira"
                                ? t('injuredEmployee')
                                : ""}{" "}
                              {t('id')}
                            </label>
                            <div className="d-flex slds-form-element__control mr-3">
                              <input
                                className={`slds-input col-lg-11 custom-input`}
                                value={this.state.voilatorId}
                                onChange={(event) =>
                                  this.setState({
                                    voilatorId: event.target.value,
                                  })
                                }
                                placeholder={`${t('enter')} ${
                                  this.state.reportType == "soi"
                                    ? t('violator')
                                    : ""
                                } ${
                                  this.state.reportType == "nm" ? t('employee') : ""
                                } ${t('id')}`}
                              />

                              <ErrorBlock
                                message={t('enterAtLeastOneIncidentEmployeeName')}
                              />

                              <img
                                src={require("assets/sls-icons/V2. New Development/ADD2.svg")}
                                className=" ml-2 cursor-pointer svg-icons"
                                onClick={() => {
                                  if (
                                    this.state.voilatorPersonName !== "" ||
                                    (this.state.personName !== "" &&
                                      this.state.voilatorId !== "")
                                  ) {
                                    if (this.state.personName !== "") {
                                      this._addPersonName();
                                    } else {
                                      this._manageVoilatorState(
                                        this.state.voilatorPersonName,
                                        1
                                      );
                                    }
                                    this._manageVoilatorState(
                                      this.state.voilatorId,
                                      2
                                    );
                                  }
                                }}
                              />
                            </div>
                            {this.state.voilatorIdList.map(
                              (personItem, personIndex) => (
                                <div
                                  className="d-flex justify-content-between mt-2"
                                  key={`person-${personIndex}`}
                                >
                                  <span>{personItem}</span>
                                  <XCircle
                                    className="cursor-pointer"
                                    onClick={() => {
                                      this.removeVoilator(personIndex);

                                      this.removeVoilator(personIndex, 2);
                                      if (this.state.personNameList?.length) {
                                        this._removePersonName(personIndex);
                                      }
                                    }}
                                  />
                                </div>
                              )
                            )}
                          </div>
                        </Col>
                      ) : null}
                      {this.state.selectedServiceId !== null &&
                      this.state.selectedProjectId !== null &&
                      !!!Object.values(this.state.formHideField).includes(
                        "Employee Search"
                      ) ? (
                        <Col sm={12} className="font-weight-light my-2">
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            onChange={(data) => {
                              const items = data?.map((el) => {
                                return {
                                  ...el,
                                  incident_person_id: el?.value,
                                  incident_person_name: el?.label,
                                };
                              });
                              this.setState({
                                searchFiledUsers: items,
                              });
                            }}
                            placeholder={t('searchEmployee')}
                            loadOptions={(input, callback) => {
                              if (this.timeout) clearTimeout(this.timeout);
                              this.timeout = setTimeout(() => {
                                this.searchEmployee(input, (data) => {
                                  const items = data?.data.map((element) => {
                                    return {
                                      ...element,
                                      label: element?.name,
                                      value: element?.id,
                                    };
                                  });
                                  callback(items);
                                });
                              }, 1000);
                            }}
                          />
                        </Col>
                      ) : null}

                      {!!(
                        this.state.reportType == "soi" &&
                        !Object.values(this.state.formHideField).includes(
                          "Target Date"
                        )
                      ) && (
                        <Col sm={6}>
                          <div>
                            <label className="slds-form-element__label">
                              {t('dueDate')}
                            </label>
                            <div className="calender-div">
                              <DatePicker
                                selected={this.state.selectedTargetDate}
                                minDate={this.state.maxDatepickerDate}
                                popperPlacement="top"
                                onChange={(jsDate) =>
                                  this._handleChange(jsDate, "target_date")
                                }
                              />
                              <Calendar className="calender-icon" />
                            </div>
                          </div>
                        </Col>
                      )}

                      {!!(this.state.reportType == "soi") && (
                        <Col sm={6}>
                          <CommonDropdown
                            title="Resolve Rights"
                            optionList={this.state.userData?.designation_flag != 6 ? [
                              ...this.state.closingRightUserList,
                              { id: 0, ["closing_right"]: "Self Resolve" },
                            ]: this.state.closingRightUserList}
                            onChangeHandle={this._closingRightUserChangeHandle}
                            isRequired={true}
                            value={this.state.selectedClosingRightUserId}
                            hasError={this.state.closingRightUserError}
                          />
                          {/* {this.state.closingRightUserError && (
                            <p className="inputErrorMsg">
                              This is mandatory field
                            </p>
                          )} */}
                        </Col>
                      )}
                      {!!(this.state.reportType == "soi") &&
                      !!!Object.values(this.state.formHideField).includes(
                        "Source"
                      ) ? (
                        <Col sm={6}>
                          <CommonDropdown
                            title="Source"
                            optionList={this.props.sourceList}
                            onChangeHandle={(id) =>
                              this.setState({ sourceId: id })
                            }
                            isRequired={false}
                            value={this.state.sourceId}
                          />
                        </Col>
                      ) : null}

                    <Col sm={6} className="d-flex align-items-center">
                      {this.state.selectedClosingRightUserId === 0 && (
                            <div className="slds-form-element__control">
                              <div className="slds-checkbox">
                                <input
                                  name="options"
                                  id="checkbox-unique-id-164"
                                  type="checkbox"
                                  checked={this.state.isCheckboxChecked}
                                  onChange={() => this.setState({ isCheckboxChecked: !this.state.isCheckboxChecked })}                    
                                />
                                <label
                                  className="slds-checkbox__label"
                                  for="checkbox-unique-id-164"
                                >
                                  <span className="slds-checkbox_faux"></span>{" "}
                                  <span className="slds-form-element__label">
                                  {t('immediateResolve')}
                                  </span>
                                </label>
                              </div>
                            </div>
                        )}
                      </Col>
                    </Row>
                    {!!(this.state.reportType == "soi") && (
                      <>
                        <Row>
                          {!!(
                            this.state.reportType == "soi" &&
                            !Object.values(this.state.formHideField).includes(
                              "Control Measure"
                            )
                          ) && (
                            <Col sm={6}>
                              <CommonDropdown
                                title={t('controlMeasure')}
                                optionList={this.state.controlMeasureList}
                                onChangeHandle={
                                  this._controlMeasureChangeHandle
                                }
                                isRequired={false}
                              />
                            </Col>
                          )}

                          {/* <Col sm={6}>
                            <label className="check-box">
                              Resolve this incident by yourself.
                              <input
                                type="checkbox"
                                checked={this.state.resolveCheckbox}
                                onChange={(event) =>
                                  this._handleChange(
                                    event.target.checked,
                                    "resolve_checkbox"
                                  )
                                }
                              />
                              <span className="checkmark1"></span>
                            </label>
                            {!!this.state.closingRightUserError && (
                              <p className="inputErrorMsg">
                                Select either Closing Right or Resolve by
                                yourself
                              </p>
                            )}
                          </Col> */}
                          <Col sm={6}>
                            <div className="slds-form-element">
                              <div className="rd-box-head">
                                {/* <div className="with-mic">
                              <h3>Control Measure </h3>
                            </div> */}
                                {!!this.state.controlMeasureURL && (
                                  <>
                                    <audio controls>
                                      <source
                                        src={this.state.controlMeasureURL}
                                      ></source>
                                    </audio>
                                    <Button
                                      variant="outline-primary"
                                      onClick={() =>
                                        this._removeAudioFile("control_measure")
                                      }
                                    >
                                      X
                                    </Button>
                                  </>
                                )}
                              </div>
                              <div className="slds-form-element">
                                {/* <label className="slds-form-element__label" for="textarea-id-01">Textarea Label</label> */}
                                <textarea
                                  className="slds-textarea"
                                  placeholder={t('writeControlMeasureDescription')}
                                  onChange={(event) =>
                                    this._handleChange(
                                      event.target.value,
                                      "control_measure"
                                    )
                                  }
                                  value={this.state.controlMeasure}
                                ></textarea>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    {this.state.selectedControlMeasureIds.length ? (
                      <Row>
                        <Col>
                          <Scrollbars style={{ height: "80px" }}>
                            <>
                              {this.state.selectedControlMeasureIds.map(
                                (data, index) => (
                                  <div
                                    key={index}
                                    className="d-flex justify-content-between px-4"
                                  >
                                    <p>{`${index + 1} - ${
                                      data.controlMeasure
                                    }`}</p>
                                    <div>
                                      <Trash
                                        onClick={() =>
                                          this._removeControlMeasure(data.id)
                                        }
                                        className="cursor-pointer common-red-2"
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          </Scrollbars>
                        </Col>
                      </Row>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {/* <div className="publish-btn">
          <Button
            variant="secondary"
            onClick={this.props.close}
            className="gray-btn"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={this._submitSOI}
            className="red-btn"
          >
            REPORT
          </Button>
        </div> */}

        <Modal
          show={this.state.showRecorderModal}
          dialogClassName="modal-90w"
          aria-labelledby="Voice Recording"
          onHide={() => this._showRecorder(false)}
          className="recordingModal"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('record')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <Button onClick={this.startRecording} className="red-btn">
                  <Mic />
                </Button>
                <Button onClick={this.stopRecording} className="gray-btn">
                  <MicOff />
                </Button>
              </div>
              <div className="obervationRecording"></div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showRecorderModal}
          dialogClassName="modal-90w"
          aria-labelledby="Voice Recording"
          onHide={() => this._showRecorder(false)}
          className="recordingModal"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="obervationRecording">
              <ReactMic
                record={this.state.recordAudio}
                className="sound-wave"
                onStop={this.onStop}
                strokeColor="#000000"
                mimeType="audio/mp3"
                // strokeColor="#000000"
                backgroundColor="#FF4081"
              />
            </div>

            <div className="modal-footer-btn">
              <Button onClick={this.startRecording} className="red-btn">
                <Mic /> {t('startRecording')}
              </Button>
              <Button onClick={this.stopRecording} className="gray-btn">
                <MicOff /> {t('stopRecording')}
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          centered
          show={this.state.sucesssPopup}
          className="recordingModal"
          onHide={this._closeSuccessPopup}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body className="text-center">
            <img className="submit-img" src={SubmitImage} alt={t('submitImage')} />
            <p className="report-submit-text"> {t('thankYouForReporting')}</p>
            <p className="report-submit-text"> {t('youHaveReported')}</p>
            <p className="report-submit-text">
              {this.state.reportNumber || 0} {this._reportType()} {t('tillToday')}
            </p>
            <div className="modal-footer-btn">
              <button
                type="submit"
                className="red-btn"
                onClick={this._closeSuccessPopup}
              >
                {t('gotoDashboardAUC')}
              </button>
              <button
                type="submit"
                className="red-btn"
                onClick={this._continueReporting}
              >
                {t('continueReportingAUC')}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {this.state.showResolveModal ? (
            <ResolvePopup
              show={this.state.showResolveModal}
              modalClose={() => this.setState({ showResolveModal: !this.state.showResolveModal })}
              reportId={this.state.reportId}
            />
          ) : null}
        {this.state.showLowStoragePopup ? (
          <LowSpace
            message={this.state.lowStorageMessage}
            handleLowSpace={this._handleLowSpace}
          />
        ) : null}
      </>
    );
  }
}

export default compose(
  withRouter,
  withTranslation(["report","util"])
)(NmReport);
